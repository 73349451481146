import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useFoldersTranslation} from "../folder/FoldersContext";
import {WithSaveCircle} from "../app/WithSaveCircle";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {TOGGLE_REMOVE_FILES, useResultActionDispatch, useResultActionState,} from "./ResultActionContext";
import {useParams} from "react-router-dom";
import {useGetFolder} from "../folder/useGetFolder";
import {Box, Button, Grow, Modal, Paper, Toolbar, Typography,} from "@mui/material";
import {useRemoveFromFolder} from "../folder/useRemoveFromFolder";

const PREFIX = "RemoveFilesModal";

const classes = {
    modal: `${PREFIX}-modal`,
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
    marginLeft: `${PREFIX}-marginLeft`,
    marginBottom: `${PREFIX}-marginBottom`,
    toolbar: `${PREFIX}-toolbar`,
    break: `${PREFIX}-break`,
};

const StyledModal = styled(Modal)(({theme}) => ({
    [`&.${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.content}`]: {
        outline: "none",
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(4),
        height: "100%",
    },

    [`& .${classes.marginLeft}`]: {
        marginLeft: theme.spacing(2),
    },

    [`& .${classes.marginBottom}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.toolbar}`]: {
        padding: 0,
        flexWrap: "wrap",
        marginBottom: theme.spacing(1),
    },

    [`& .${classes.break}`]: {
        flexBasis: "100%",
        height: 0,
    },
}));

export const RemoveFilesModal = ({selectedDocuments, onSuccess}) => {
    const {showRemoveFiles} = useResultActionState();
    const dispatch = useResultActionDispatch();
    const params = useParams();
    const t = useFoldersTranslation();
    const folder = useGetFolder(params.folderId);
    const snackbarDispatch = useSnackbarDispatch();
    const [documents, setDocuments] = useState(null);

    const [postDocumentsResponse, postDocuments] = useRemoveFromFolder();

    useEffect(() => {
        setDocuments(selectedDocuments);
    }, [selectedDocuments]);

    const onHide = () => {
        dispatch({type: TOGGLE_REMOVE_FILES});
    };


    const removeFilesHandler = () => {
        if (documents === null || documents.length === 0) {
            return;
        }

        // NOTE:
        // Folders are referenced by params.folderId
        // Projects are referenced by params.projectId
        const folderId = params.folderId || params.projectId;

        // Using Promise.allSettled() to continue even if saving of one document fails.
        Promise.allSettled(postDocuments(documents, folderId)).then(results => {
            const folderName = folder.data.title;
            const savedDocuments = [] || results.filter(r => r.status === 'fulfilled');
            snackbarDispatch({
                type: ADD_MESSAGE,
                message: {
                    title: t("removeFromFolderSuccessTitle", "Fjernet"),
                    body: t(
                        "removeFromFolderSuccessMessage",
                        "{{count}} dokumenter ble fjernet fra mappen {{folderName}}",
                        {
                            count: documents?.length,
                            folderName: folderName,
                        }
                    ),
                    type: "success",
                },
            });
            onHide();
            onSuccess(savedDocuments);
        });
    };

    return (
        <StyledModal
            className={classes.modal}
            open={showRemoveFiles}
            closeAfterTransition
        >
            <div className={classes.content}>
                <WithSaveCircle saving={postDocumentsResponse.pendingDocuments > 0}>
                    <Grow in={showRemoveFiles}>
                        <Paper className={classes.paper}>
                            <Toolbar className={classes.toolbar}>
                                <Typography component={"h2"} variant={"h6"}>
                                    {t("removeDocumentsHeader", "Fjerne dokumenter")}
                                </Typography>
                                <div className={classes.break}/>
                                <Typography component={"div"}>
                                    {t(
                                        "removeDocumentsConfirm",
                                        "Fjerne {{count}} {{text}} fra mappen?",
                                        {
                                            count: selectedDocuments.length,
                                            text:
                                                selectedDocuments.length > 1
                                                    ? "dokumenter"
                                                    : "dokument",
                                        }
                                    )}
                                </Typography>
                            </Toolbar>
                            <Toolbar>
                                <Box flexGrow={1}/>
                                <Button onClick={onHide}>{t("cancel", "Avbryt")}</Button>
                                <Button
                                    variant={"contained"}
                                    color={"secondary"}
                                    className={classes.marginLeft}
                                    disabled={false}
                                    onClick={removeFilesHandler}
                                >
                                    {t("removeDocumentsButton", "Ok")}
                                </Button>
                            </Toolbar>
                        </Paper>
                    </Grow>
                </WithSaveCircle>
            </div>
        </StyledModal>
    );
};
