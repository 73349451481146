import React from "react";
import {useFormikContext} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import AssignmentIcon from "@mui/icons-material/Assignment";

export const FormikSubmitButton = ({disabled, children, ...props}) => {
    const {submitForm, isSubmitting, isValid} = useFormikContext();
    return (
        <LoadingButton
            sx={{marginBottom: 2, marginTop: 2, marginLeft: 1}}
            variant={"contained"}
            color={"primary"}
            onClick={submitForm}
            loadingPosition="start"
            startIcon={<AssignmentIcon/>}
            loading={isSubmitting}
            disabled={isSubmitting || !isValid || disabled}
            {...props}
        >
            {children}
        </LoadingButton>
    );
};
