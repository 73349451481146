import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {SELECT_FIELDS, STEP_FORWARD, useBatchEditDispatch, useBatchEditState,} from "./batchEditContext";
import {useDocumentTranslation} from "./documentContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import {ListItemButton} from "@mui/material";

const PREFIX = "BatchEditSelectFields";

const classes = {
    root: `${PREFIX}-root`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    listCard: `${PREFIX}-listCard`,
    continueButton: `${PREFIX}-continueButton`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.root}`]: {
        margin: "auto",
    },

    [`& .${classes.content}`]: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "75%",
    },

    [`& .${classes.button}`]: {
        margin: theme.spacing(0.5, 0),
    },

    [`& .${classes.listCard}`]: {
        width: 250,
        minHeight: 500,
        height: "90%",
        overflow: "auto",
    },

    [`& .${classes.continueButton}`]: {
        marginLeft: theme.spacing(1),
    },
}));

const intersection = (a, b) => a.filter((value) => -1 !== b.indexOf(value));

export const BatchEditSelectFields = ({onCancel}) => {
        const {availableFields, selectedFields} = useBatchEditState();
        const dispatch = useBatchEditDispatch();
        const [checked, setChecked] = useState([]);
        const t = useDocumentTranslation();

        const fieldsNotSelected = availableFields.filter(
            (availableField) => !selectedFields.includes(availableField)
        );

        const availableChecked = intersection(checked, fieldsNotSelected);

        const onContinue = () => {
            dispatch({type: SELECT_FIELDS, fields: availableChecked});
            dispatch({type: STEP_FORWARD});
            setChecked([]);
        };

        const handleCheck = (value) => {
            if (checked.includes(value)) {
                setChecked(checked.filter((c) => c !== value));
            } else {
                setChecked([...checked, value]);
            }
        };

        const customList = (items) => (
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItemButton
                            key={value}
                            role="listitem"
                            onClick={() => handleCheck(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{"aria-labelledby": labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={t(value)}/>
                        </ListItemButton>
                    );
                })}
                <ListItem/>
            </List>
        );

        return (
            <Root>
                <Box sx={{display: "flex", marginBottom: 2, marginTop: 2}}>
                    <Box m={"auto"}>
                        <Card className={classes.listCard}>
                            <CardHeader
                                subheader={"Tilgjengelige felter"}
                                title={fieldsNotSelected.length}
                            />
                            <Divider/>
                            {customList(fieldsNotSelected)}
                        </Card>
                    </Box>
                </Box>
                <Toolbar>
                    <Box flexGrow={1}/>
                    <Button onClick={onCancel}>{t("cancel", "Avbryt")}</Button>
                    <Button
                        color={"secondary"}
                        variant={"contained"}
                        className={classes.continueButton}
                        onClick={onContinue}
                        disabled={0 === checked.length}
                    >
                        {t("continue", "Fortsett")}
                    </Button>
                </Toolbar>
            </Root>
        );
    }
;
