import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {SearchResultsOrderByMenu} from "../search/SearchResultsOrderByMenu";
import {
    SET_ROWS,
    SET_SEARCHING,
    SET_SORT,
    SET_START,
    useFoldersDispatch,
    useFoldersState,
    useFoldersTranslation,
} from "./FoldersContext";
import {FolderActions} from "./FolderActions";
import {FolderActionProvider} from "./FolderActionContext";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import {ThemeProvider} from "@mui/styles";
import {TextFieldSearchForFolder} from "./TextFieldSearchForFolder";
import {FolderSearchSettings} from "../search/FolderSearchSettings";
import TablePagination from "@mui/material/TablePagination";
import {createTheme} from "@mui/material";
import * as locales from "@mui/material/locale";
import i18next from "i18next";


const PREFIX = "ToolbarFolderSearch";

const classes = {
    divider: `${PREFIX}-divider`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.divider}`]: {
        marginBottom: theme.spacing(0),
    },
}));

export const ToolbarFolderSearch = ({children}) => {

    const folderSearchSettings = FolderSearchSettings('dams.folderListSearchResults.config');
    const foldersDispatch = useFoldersDispatch();
    const {sort, start, rows, results} = useFoldersState();
    const t = useFoldersTranslation();

    const page = start / rows;

    const [locale] = useState(i18next.language === "nbNO" ? "svSE" : "enUS");

    const handleChangePage = (_event, newPage) => {
        foldersDispatch({
            type: SET_START,
            start: newPage * rows,
        });
        foldersDispatch({
            type: SET_SEARCHING
        });
    };

    const handleChangeRowsPerPage = (event) => {
        folderSearchSettings.setNumberOfRows(event.target.value);
        foldersDispatch({
            type: SET_ROWS,
            rows: event.target.value,
        });
        foldersDispatch({
            type: SET_SEARCHING
        });
    };

    return (
        <Root sx={{
            paddingInline: {
                xs: 0,

            },
        }}>
            <Toolbar sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}>
                {children}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '4rem',
                        marginLeft: 'auto',
                    }}
                >
                    <TextFieldSearchForFolder t={t} foldersDispatch={foldersDispatch}/>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '4rem',
                }}>
                    <FolderActionProvider>
                        <FolderActions/>
                    </FolderActionProvider>
                    <SearchResultsOrderByMenu
                        sort={sort}
                        t={t}
                        selectHandler={(value) => {
                            foldersDispatch({
                                type: SET_SORT,
                                sort: value,
                            });
                            foldersDispatch({
                                type: SET_SEARCHING
                            });
                        }}
                    />
                </Box>
                <ThemeProvider
                    theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
                >
                    <TablePagination
                        sx={{

                            display: 'flex',
                            alignItems: 'center',
                            minHeight: '4rem',

                            "& .MuiTablePagination-toolbar": {
                                paddingLeft: {
                                    xs: 0,
                                    md: .5,
                                },
                                paddingRight: 0,
                            },
                            "& .MuiTablePagination-input": {
                                marginLeft: 0,
                                marginRight: 0.5,
                            },
                            "& div.MuiTablePagination-actions": {
                                marginLeft: .5,
                            }
                        }}
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={results.count}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPage={
                            folderSearchSettings.getNumberOfRows()
                        }
                        labelRowsPerPage={t("rowsPerPage", "Vis:")}
                        labelDisplayedRows={({from, to, count}) =>
                            t("labelDisplayedRows", "{{from}}-{{to}} av {{count}}", {
                                from: from,
                                to: to,
                                count: count,
                            })
                        }
                    />
                </ThemeProvider>
            </Toolbar>
            <Divider className={classes.divider} sx={{
                marginInline: {
                    xs: 0,
                },
                paddingInline: {
                    xs: 0,
                },
            }}/>
        </Root>
    );
};
