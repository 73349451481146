import {Box, Button, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useTheme} from "@mui/styles";
import {
    CLEAR_ARCHEOLOGY_PROJECT_READ,
    useProjectDispatch,
    useProjectState,
    useProjectTranslation,
} from "../../projectContext";
import {useNavigate} from "react-router-dom";

export const ArcheologyProjectExists = () => {
    const t = useProjectTranslation();
    const theme = useTheme();
    const projectDispatch = useProjectDispatch();
    const {projectData, damsProject} = useProjectState();
    const navigate = useNavigate();
    const project = projectData.archeologyRemote;
    const {name, projectStart, projectEnd} = project;

    const handleGotoProject = () => {
        navigate(`/project/${damsProject.id}`);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: theme.spacing(2),
                }}
            >
                <Typography variant={"h5"}>
                    {t('headingProjectAlreadyCreated', 'Det er allerede opprettet et DAMS-prosjekt knyttet til')}
                </Typography>
                <Typography
                    variant={"h5"}
                    color={"secondary"}
                    sx={{marginLeft: theme.spacing(1)}}
                >
                    {projectData.name}
                </Typography>
            </Box>
            <Box
                sx={{
                    border: "solid 1px #ddd",
                    borderRadius: "4px",
                    padding: theme.spacing(2),
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box>
                    <Typography variant={"h6"}>
                        {name}({projectStart} - {projectEnd})
                    </Typography>
                    <Typography variant={"subtitle1"} color={"secondary"}>
                        {project.projectType}
                    </Typography>
                </Box>
                <CheckCircleIcon
                    color={"secondary"}
                    sx={{marginLeft: theme.spacing(4), width: 40, height: 40}}
                />
            </Box>

            {/* Action buttons */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: theme.spacing(4),
                }}
            >
                <Button
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={() => {
                        projectDispatch({
                            type: CLEAR_ARCHEOLOGY_PROJECT_READ,
                        });
                    }}
                >
                    {t('btnGoBack', 'Gå tilbake')}
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    sx={{marginLeft: theme.spacing(2)}}
                    onClick={handleGotoProject}
                >
                    {t('btnGotoProject', 'Gå til prosjektet')}
                </Button>
            </Box>
        </>
    );
};
