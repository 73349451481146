import React from "react";
import {UploadedFileGridItem} from "./UploadedFileGridItem";
import {useDocumentState} from "../documents/documentContext";
import {UploadedFileGridItemSmallScreen} from "../metadata/smallscreen/UploadedFileGridItemSmallScreen";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

export const UploadedFilesGrid = ({checked, checkFile, smallscreen}) => {
    const {files} = useDocumentState();

    return (
        <ImageList
            cols={smallscreen ? 2 : 8}
            rowHeight={"auto"}
            sx={{
                overflowY: "auto",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 2,
                paddingRight: 2,
                display: 'flex',
                flexWrap: 'wrap'
            }}
        >
            {files.map((file) => (
                <ImageListItem
                    key={"list_item_" + file.title}
                    file={file}
                    sx={{
                        aspect: 1,
                        objectFit: "cover",
                    }}
                >
                    {!smallscreen && (
                        <UploadedFileGridItem
                            file={file}
                            checked={checked[file.title]}
                            onCheck={checkFile(file.title)}
                        />
                    )}
                    {smallscreen && (
                        <UploadedFileGridItemSmallScreen
                            file={file}
                            checked={checked[file.title]}
                            onCheck={checkFile(file.title)}
                        />
                    )}
                </ImageListItem>
            ))}
        </ImageList>
    );
};
