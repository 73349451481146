import {AccordionFilter} from "../../search/AccordionFilter";
import {AccordionFilterTitle} from "../../search/filters/AccordionFilterTitle";
import List from "@mui/material/List";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFoldersState} from "../FoldersContext";
import {useOptions} from "../tags/useOptions";
import {setFq} from "../tags/setFq";
import {renderCheckboxes} from "./renderCheckboxes";

export const FilterCreatedBy = ({callback}) => {

    const {t} = useTranslation('dams.folder', {useSuspense: false});

    const {fq, results} = useFoldersState();

    const facetKey = "created_by_name";

    const [loading, setLoading] = useState(true);

    const [facets, setFacets] = useState({});

    const [searchValue, setSearchValue] = useState('');

    const [selected, setSelected] = useState([]);

    const count = Object.keys(facets).length;   // The total number of different facet-values available.

    const options = useOptions(loading, searchValue, selected, facets);

    /**
     * Callback used when the user types the search criteria.
     * Used to filter the list of available filter-values/-options.
     * @param event
     */
    const searchForFilters = (event) => {
        setSearchValue(event.target.value);
    };

    /**
     * Handles the click event of a checkbox and updates the selected filters.
     *
     * @param {string} value - The value of the checkbox that was clicked.
     * @return {void} This function does not return a value.
     */
    const handleCheckboxClick = (value) => {
        const filters = setFq(facetKey, value, selected);
        setSelected(filters);
        callback(filters);
    };

    /**
     * Hook used to extract the available facet-values from the current result set.
     */
    useEffect(() => {
        if (!results || !results.facets) {
            return;
        }

        if (Object.keys(results.facets).includes('createdByName')) {
            setFacets(results.facets['createdByName']);
            setLoading(false);
        }
    }, [results]);

    /**
     * Removes the checked filter values, when the user has clicked the "reset filters" button.
     */
    useEffect(() => {
        if (!fq || fq?.length === 0) {
            setSelected([]);
        }
    }, [fq]);


    return (!loading && Object.keys(facets).length > 0 &&
        <AccordionFilter
            title={
                <AccordionFilterTitle
                    title={`${t("createdBy", "Opprettet av")} (${count})`}
                    value={searchValue}
                    label={t('createdBySearch', 'Søk...')}
                    onChangeCallback={searchForFilters}
                    setSearchValueCallback={() => {
                        setSearchValue('');
                    }}
                />
            }
            count={count}
        >
            <List>{renderCheckboxes(options, facets, handleCheckboxClick, selected, facetKey)}</List>
        </AccordionFilter>
    );
};