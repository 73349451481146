import {damsFetch} from "../app/damsFetch";
import mime from "react-native-mime-types";
import {getMimeTypeFromFilename} from "../damsFileObjectDefinitions";
import {clientLog} from "../clientLog";

export const buildGetUrl = ({collectionId, dmsId, isImage = true}) => {
    const params = {
        collectionId: collectionId,
        multimediaId: dmsId,
    };

    const docType = isImage ? "image" : "file";

    return damsFetch(`/dms/build-get-url/${docType}`, {
        method: "POST",
        body: JSON.stringify(params),
    });
};

export const buildStreamUrl = ({collectionId, dmsId}) => {
    const params = {
        collectionId: collectionId,
        multimediaId: dmsId,
    };

    return damsFetch(`/dms/build-stream-url/`, {
        method: "POST",
        body: JSON.stringify(params),
    });
};

export const orderUploadUrl = (collectionId, file) => {
    let mimeType = file.type;
    if (!file.type || file.type === '') {
        mimeType = mime.lookup(file.name);
        if (!mimeType) {
            mimeType = getMimeTypeFromFilename(file.name)
        }
    }
    const params = {
        collectionId: collectionId,
        filename: file.name,
        mediaType: mimeType,
    };
    return damsFetch(`/dms/order-upload-url/`, {
        method: "POST",
        body: JSON.stringify(params),
    });
};

export const orderPlaybackLink = (collectionId, dmsId, mediaFormat) => {
    const params = {
        collectionId: collectionId,
        multimediaId: dmsId,
        mediaFormat: mediaFormat,
    };

    return damsFetch(`/dms/order-playback-link/`, {
        method: "POST",
        body: JSON.stringify(params),
    }).catch((e) => {
        clientLog('error', e);
    });
};

export const orderDownloadUrl = ({collectionId, dmsId, filename}) => {
    const url = `/dms/order-download-url/`;
    const params = {
        collectionId: collectionId,
        multimediaId: dmsId,
        filename: filename
    };
    return damsFetch(url, {
        method: "POST",
        body: JSON.stringify(params),
    });
};

export const extractMediaMeta = (collectionId, dmsId, regenerate) => {
    const params = {
        collectionId: collectionId,
        multimediaId: dmsId,
        regenerate: regenerate ? regenerate : false
    };

    return damsFetch(`/dms/extract-media-meta/`, {
        method: "POST",
        body: JSON.stringify(params),
    });
};

