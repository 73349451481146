import React from "react";
import {styled} from "@mui/material/styles";
import {useMetaMappingTranslation} from "./MetaMappingContext";
import {If} from "../app/If";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = "MappingAccordion";

const classes = {
    heading: `${PREFIX}-heading`,
    secondaryHeading: `${PREFIX}-secondaryHeading`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.heading}`]: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },

    [`& .${classes.secondaryHeading}`]: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export const MappingAccordion = ({
                                     activeType,
                                     selectedField,
                                     setSelectedFieldCallback,
                                     fields,
                                     values,
                                     show,
                                     children,
                                 }) => {
    const t = useMetaMappingTranslation();

    if (!show) {
        return null;
    }
    const changeHandler = (key) => () => {
        setSelectedFieldCallback(expanded(key) ? "" : key);
    };

    const expanded = (key) => selectedField === key;

    const getFieldValues = (key) => {
        return values[activeType + key];
    };

    return (
        <Root>
            {Object.keys(fields).map((k) => {
                const field = fields[k];
                const label = t(field.transKey, field.transDefaultValue);

                if (
                    Object.keys(field).includes("metadataMapper") &&
                    !field.metadataMapper
                ) {
                    // Skip fields excluded from the metadata mapper.
                    return <></>;
                } else {
                    return (
                        <Accordion
                            key={k}
                            id={k}
                            expanded={expanded(k)}
                            onChange={changeHandler(k)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography className={classes.heading}>{label}</Typography>
                                <If boolean={!expanded(k)}>
                                    <Typography className={classes.secondaryHeading}>
                                        {getFieldValues(k).join(",")}
                                    </Typography>
                                </If>
                            </AccordionSummary>
                            <AccordionDetails>
                                <If boolean={expanded(k)}>{children}</If>
                            </AccordionDetails>
                        </Accordion>
                    );
                }
            })}
        </Root>
    );
};
