import React from "react";
import {styled} from "@mui/material/styles";
import {useField, useFormikContext} from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";

const PREFIX = "FormikArrayTextField";

const classes = {
    fieldWithButton: `${PREFIX}-fieldWithButton`,
};

const Root = styled("div")(() => ({
    [`& .${classes.fieldWithButton}`]: {
        display: "flex",
    },
}));

export const FormikArrayTextField = ({formikKey, ...props}) => {
    const [field, meta] = useField(formikKey);
    const {setFieldValue} = useFormikContext();

    const changeHandler = (idx) => (event) => {
        setFieldValue(`${field.name}[${idx}]`, event.target.value).then();
    };

    const addHandler = () => {
        setFieldValue(`${field.name}[${field.value.length}]`, "").then();
    };

    const removeHandler = (idx) => () => {
        setFieldValue(
            field.name,
            field.value.filter((_value, index) => index !== idx)
        ).then();
    };

    const downHandler = (idx) => () => {
        const switchWith = field.value[idx + 1];
        const switchValue = field.value[idx];
        setFieldValue(`${field.name}[${idx}]`, switchWith).then();
        setFieldValue(`${field.name}[${idx + 1}]`, switchValue).then();
    };

    const upHandler = (idx) => () => {
        const switchWith = field.value[idx - 1];
        const switchValue = field.value[idx];
        setFieldValue(`${field.name}[${idx}]`, switchWith).then();
        setFieldValue(`${field.name}[${idx - 1}]`, switchValue).then();
    };

    const addButton = () => (
        <Toolbar>
            <IconButton
                onClick={addHandler}
                disabled={Boolean(field.value.filter((val) => "" === val).length)}
                color={"secondary"}
                size="large"
            >
                <AddCircleOutlineIcon/>
            </IconButton>
        </Toolbar>
    );

    if (undefined === meta.initialValue) {
        return null;
    }

    return (
        <Root>
            {field.value.map((value, idx) => (
                <div key={crypto.randomUUID()} className={classes.fieldWithButton}>
                    <TextField
                        name={`${field.name}[${idx}]`}
                        helperText={meta.touched ? meta.error : ""}
                        error={meta.touched && Boolean(meta.error)}
                        value={value}
                        onChange={changeHandler(idx)}
                        onBlur={field.onBlur}
                        {...props}
                    />
                    <Toolbar>
                        <IconButton
                            disabled={0 === idx}
                            color={"secondary"}
                            onClick={upHandler(idx)}
                            size="large"
                        >
                            <ArrowUpwardIcon/>
                        </IconButton>
                        <IconButton
                            color={"secondary"}
                            disabled={idx === field.value.length - 1}
                            onClick={downHandler(idx)}
                            size="large"
                        >
                            <ArrowDownwardIcon/>
                        </IconButton>
                        <IconButton
                            onClick={removeHandler(idx)}
                            color={"secondary"}
                            size="large"
                        >
                            <RemoveCircleOutlineIcon/>
                        </IconButton>
                    </Toolbar>
                </div>
            ))}
            {addButton()}
        </Root>
    );
};
