import React from "react";
import {damsSearch} from "../app/damsFetch";
import {
    concatWithExisting,
    excludeThoseAlreadyListedAsKulturnav,
    fieldValuesWithCollectionIds,
    inputWithCollectionIds,
    sourceToUniqueId,
} from "../metadata/inputMapper";
import {kulturnavApiCoreFetch} from "../app/kulturnavFetch";
import {useAddLicense} from "./useAddLicense";
import {useField, useFormikContext} from "formik";
import {UPDATE_FORM_DATA, useDocumentDispatch, useDocumentState} from "../documents/documentContext";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useAuthsState} from "../auths/authsContext";
import {KulturNavLicenseSearch} from "./KulturNavLicenseSearch";
import CopyrightIcon from "@mui/icons-material/Copyright";
import {mapNotMissing} from "../inpututils";
import {apiCoreObjectToDAMSInput, getBrowserLocale} from "../utility";
import {HIDE_ADD_LICENSE_FIELD, useCopyrightDispatch} from "../copyright/copyrightContext";
import {clientLog} from "../clientLog";

export const FormikSelectLicense = ({
                                        formikKey,
                                        label,
                                        disabled = false,
                                        size = null,
                                        helperText = null,
                                    }) => {
    const [field] = useField(formikKey);
    const {setFieldValue} = useFormikContext();
    const locale = getBrowserLocale();
    const postLicenses = useAddLicense();
    const {formData, collectionIds} = useDocumentState();
    const {museumCollections} = useAuthsState();
    const docDispatch = useDocumentDispatch();
    const copyrightDispatch = useCopyrightDispatch();

    const museums = museumCollections
        .filter((museumCollection) =>
            collectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    const multipleSelect = false;

    /**
     * Fetches licenses stored in KulturNav.
     * @param params
     * @returns {Promise<unknown | *[]>}
     */
    const fetchKulturNavValues = (params) => {
        const datasetUUID = `d5bff00e-44dc-409c-a6e4-cf601a7ac6b1`;
        const url = `entity.dataset:${datasetUUID},entityType:Concept,nativeCompoundName:${params}?properties=entity.dataset,entity.fullCaption&displayValues=true&lang=${locale}`;
        return kulturnavApiCoreFetch(url).then(json => {
            const mapped = json['entities'].map(d => {
                return apiCoreObjectToDAMSInput(d);
            });
            return Promise.resolve(mapped);
        }).catch(error => {
            clientLog('error', error, 'FormikSelectLicense');
            return Promise.resolve([]);
        });
    };

    /**
     * Fetches a list of licenses stored in the DAMS database.
     * @param params
     * @returns {Promise<unknown>}
     */
    const fetchDamsValues = (params) => {
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: `_title_:${params}`,
                fl: "title,id",
                sort: "title asc",
                expand: true,
                documentType: `("license")`,
                museums: museums.join(","),
            })
        );

        return damsSearch(searchParams)
            .then((json) => {
                return Promise.resolve(inputWithCollectionIds(json['models']));
            });
    };

    /**
     * Searchandler, used to fetch data from both KulturNav and the DAMS database,
     * and merge these values into a single list of options.
     * @param params
     * @param callback
     */
    const licenseSearchHandler = (params, callback) => {
        Promise.all([
            fetchKulturNavValues(params),
            fetchDamsValues(params)
        ]).then(([kulturnavResponse, damsResponse]) => {
            // Remove the item "Falt i det fri"
            const REMOVE_ID = '3c2002ff-79be-4a23-a7ea-656150f50143'
            damsResponse = damsResponse.filter(f => f.sourceId !== REMOVE_ID);

            let damsLookup = sourceToUniqueId(damsResponse);

            // Concatenate values from both sources.
            let kulturnavMapped = concatWithExisting(kulturnavResponse, damsLookup);
            kulturnavMapped = kulturnavMapped.filter(f => f.sourceId !== REMOVE_ID);

            callback([
                ...kulturnavMapped,
                ...excludeThoseAlreadyListedAsKulturnav(damsResponse, kulturnavMapped),
            ]);
        });
    };

    const onChange = (licenses) => {
        setFieldValue(field.name, licenses).then();

        const found = formData.licenses.find(l => {
            return l.reference.uniqueId === licenses[0].reference.uniqueId;
        });

        if (found) {
            return;
        }

        // Make sure the formData object is updated with the latest license-changes.
        formData.licenses = [...formData.licenses, ...licenses];
        docDispatch({
            type: UPDATE_FORM_DATA,
            formData: formData
        });

        // Make sure the "add license" field is hidden, to avoid duplicates of this field.
        copyrightDispatch({
            type: HIDE_ADD_LICENSE_FIELD
        });

    };

    const onAdd = (licenses) => {
        if (Array.isArray(field.value)) {
            onChange([...field.value, ...licenses]);
        } else if (!field.value) {
            onChange(licenses);
        } else {
            onChange([field.value, ...licenses]);
        }
    }

    const postMissing = (value) => {
        const licenses = collectionIds
            .filter((collectionId) => !value.collectionId.includes(collectionId))
            .map((collectionId) => ({
                ...value,
                collectionId: collectionId,
            }));
        return postLicenses({licenses: licenses});
    };

    const changeHandler = (values) => {
        if (!multipleSelect && !Array.isArray(values)) {
            // Convert selected value to an array. Array is used to suppport multiple select in other cases.
            values = [values];
        }

        const createExternalLicense = values.find((license) => !license.uniqueId);
        if (createExternalLicense) {
            const licenses = collectionIds.map((collectionId) => ({
                ...createExternalLicense,
                collectionId: collectionId,
            }));
            postLicenses({
                licenses: licenses,
            }).then(onAdd);
        } else {
            const existingLicenses = values
                .map((v) => mapNotMissing(collectionIds, v, "license"))
                .flat();

            Promise.all(values.map(postMissing)).then((newLicenses) =>
                onChange([...existingLicenses, ...newLicenses.flat()])
            );
        }
    };

    const getValue = () => {
        let val = [];
        if (field.value) {
            const values = fieldValuesWithCollectionIds(field.value);
            val = !multipleSelect ? values[0] : values;
        }
        return val;
    };

    return (
        <KulturNavLicenseSearch
            key={formikKey}
            id={"search-knav-license"}
            searchHandler={licenseSearchHandler}
            onChange={changeHandler}
            onClear={() => onChange([])}
            disabled={disabled}
            inputLabel={label}
            Icon={CopyrightIcon}
            multiple={multipleSelect}
            value={getValue()}
            size={size}
            helperText={helperText}
        />
    );
};
