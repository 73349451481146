import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {SearchResultDetailsProvider} from "../../../search-result-details/SearchResultDetailsContext";
import {DocumentTypeProvider} from "../../../document-type/documentTypeContext";
import {useParams} from "react-router-dom";
import {TextField, Zoom} from "@mui/material";
import {CLEAR_FQ, SET_QUERY, SET_SEARCHING, UPDATE_FQ, useSearchDispatch,} from "../../../search/SearchContext";
import {ArcheologyProjectViewHeader} from "./ArcheologyProjectViewHeader";
import {ProjectContentsSearchResults} from "../../ProjectContentsSearchResults";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useProjectTranslation} from "../../projectContext";

import '../../projectview.css'

const PREFIX = "ArcheologyProjectView";

const classes = {
    museumSelector: `${PREFIX}-museumSelector`,
    searchAndType: `${PREFIX}-searchAndType`,
};

const Root = styled("div")(({theme}) => ({

    [`& .${classes.museumSelector}`]: {
        display: "flex",
        alignItems: "center",
    },

    [`& .${classes.searchAndType}`]: {
        [theme.breakpoints.up("xs")]: {
            flexGrow: 1,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: '60%'
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: '50%'
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: '38rem'
        },
    },
}));

export const ArcheologyProjectView = ({startSearched = false}) => {

    const t = useProjectTranslation();

    const searchDispatch = useSearchDispatch();

    const [searched, setSearched] = useState(startSearched);
    const [inputValue, setInputValue] = useState("");
    const [showInstantly, setShowInstantly] = useState("");

    const {searchString, projectId} = useParams();


    // Set project content search criterias.
    const setCriterias = (inValue, query) => {
        if (inValue) {
            setInputValue(inValue);
        }
        setSearched(true);
        searchDispatch({
            type: UPDATE_FQ,
            fq: {
                key: "folder_ids",
                value: projectId,
            },
        });
        searchDispatch({
            type: SET_QUERY,
            query: query,
            folderContext: true,
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    };

    // Execute search for the project content.
    useDeepCompareEffect(() => {
        setShowInstantly("");
        if (!searchString || searchString === "*") {
            setCriterias("", "*");
        } else if (searchString) {
            setCriterias(searchString, searchString);
        }
    }, [searchString, setCriterias]);

    // Execute search when user pushes ENTER on the keyboard.
    const searchOnEnter = (event) => {
        if ("Enter" === event.key) {
            let query = inputValue;
            if (query === "") {
                query = "*";
            }
            searchDispatch({type: CLEAR_FQ});
            setCriterias(undefined, query);
        }
    };

    return (
        <Zoom in={true} style={{transitionDelay: '300ms'}}>
            <Root className={'landingPage'}>
                <DocumentTypeProvider>
                    <div className={'search'}>
                        <div className={classes.searchAndType}>
                            <TextField
                                fullWidth="true"
                                id="dams-search"
                                label={t('searchInProject', 'Søk i prosjektet')}
                                type="search"
                                variant="filled"
                                onKeyDown={searchOnEnter}
                                value={inputValue}
                                onChange={(event) => setInputValue(event.target.value)}
                            />
                        </div>
                    </div>
                    <SearchResultDetailsProvider>
                        {searched && (
                            <Box className={'searchDetailsContainer'}>
                                <ArcheologyProjectViewHeader/>
                                <ProjectContentsSearchResults
                                    showInstantly={showInstantly}
                                />
                            </Box>
                        )}
                    </SearchResultDetailsProvider>
                </DocumentTypeProvider>
            </Root>
        </Zoom>
    );
};
