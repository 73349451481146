import {TextField} from "@mui/material";
import React, {useState} from "react";
import PropTypes from "prop-types";

/**
 * Input field used on the primary search page.
 * @param classes
 * @param searchOnEnterCallback
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
const SearchInput = ({classes, searchOnEnterCallback, label}) => {
    const [inputValue, setInputValue] = useState('');

    const handleSearchInput = event => {
        setInputValue(event.target.value);
    };

    return <div className={classes.searchAndType}>
        <TextField
            id="dams-search"
            className={classes.searchInput}
            label={label}
            type="search"
            variant="filled"
            onKeyDown={(event) => {
                searchOnEnterCallback(event, inputValue)
            }}
            value={inputValue}
            onChange={handleSearchInput}
            fullWidth
        />
    </div>
};

SearchInput.propTypes = {
    classes: PropTypes.object.isRequired,
    searchOnEnterCallback: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired // Make sure to add the PropTypes for label
}

export {SearchInput};