import {DescriptionOutlined} from "@mui/icons-material";
import {DamsFileIcon} from "../../files/DamsFileIcon";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useStyles} from "./resultCardStyles";


export const ResultCardDocumentIcon = ({mimeType, model, icon = true}) => {
    const [filename, setFilename] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        if (!model || !model.content.mediae) {
            return;
        }
        const fName = model.content.mediae[0].reference.title;
        setFilename(fName);
    }, [model]);

    return (
        <Box>
            <div className={classes['fileThumbContainer']}>
                <DescriptionOutlined sx={{fontSize: 135, color: "#bbb"}}/>
            </div>
            <div className={classes['fileTypeContainer']}>
                {icon && <DamsFileIcon mimeType={mimeType} filename={filename} size={36}/>}
            </div>
        </Box>
    );
};
