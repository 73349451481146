import {ArcheologyProjectAdminUsers} from "../../ArcheologyProjectAdminUsers";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import {PROJECT_CLOSE_ADMIN_OVERLAY, PROJECT_UPDATED, useProjectViewDispatch,} from "../projectViewContext";
import {useSaveProject} from "../../useSaveProject";
import React, {useEffect, useState} from "react";
import {ADD_MESSAGE, useSnackbarDispatch,} from "../../../../snackbar/SnackbarContext";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {useSearchTranslation} from "../../../../search/SearchContext";
import {useProjectTranslation} from "../../../projectContext";

export const ArcheologyProjectAdminSmallScreen = ({model, show}) => {
    const t = useSearchTranslation();
    const tp = useProjectTranslation();

    const projectViewDispatch = useProjectViewDispatch();
    const snackbarDispatch = useSnackbarDispatch();

    const [archeology, success, error] = useSaveProject();

    const [users, setUsers] = useState([]);

    const showSnackbar = (err, succ) => {
        let msgObj = {
            title: t('titleProjectUsers', 'Prosjekt - Brukere'),
            message: t('messageProjectUsers', 'Valgte brukere har fått tilgang'),
            type: "success",
        };
        if (err) {
            msgObj = {
                title: t('titleProjectUsersError', 'Prosjekt - Brukere - Feil'),
                message: t('messageProjectUsersError', 'Det oppstod en feil ved lagring av brukere'),
                type: "error",
            };
        }

        if (err || succ) {
            snackbarDispatch({
                type: ADD_MESSAGE,
                message: {
                    title: msgObj.title,
                    body: msgObj.message,
                    type: msgObj.type,
                },
            });
        }
    };

    const handleSave = () => {
        const aclEmails = users.filter((u) => u.selected).map((u) => u.email);
        archeology(model.collectionId, model, aclEmails);
        projectViewDispatch({
            type: PROJECT_UPDATED,
            project: {
                ...model,
                content: {
                    ...model.content,
                    acls: aclEmails,
                },
            },
        });
    };

    const handleClose = () => {
        projectViewDispatch({type: PROJECT_CLOSE_ADMIN_OVERLAY});
    };

    const updatedUserList = (values) => setUsers(values);

    useEffect(() => {
        showSnackbar(error, success);
    }, [success, error]);

    return (
        <Dialog fullScreen={true} open={show}>
            <DialogTitle>{tp('headingProjectAdmin', 'Prosjektadmin')}</DialogTitle>
            <DialogContent>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "fixed",
                        top: 0,
                        right: 0,
                    }}
                >
                    <CloseIcon/>
                </IconButton>

                <ArcheologyProjectAdminUsers
                    projectId={model.id}
                    collectionId={model.collectionId}
                    updatedUsersCallback={updatedUserList}
                />
            </DialogContent>
            <DialogActions>
                {/* Buttons */}
                <Stack direction={"row"} justifyContent={"flex-end"}>
                    <Button color={"secondary"} onClick={handleClose}>
                        {tp('btnCancel', 'Avbryt')}
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={handleSave}
                        sx={{marginLeft: 2}}
                    >
                        {tp('btnSAve', 'Lagre')}
                    </Button>
                    );
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
