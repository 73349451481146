import {Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useFileUploadTranslation} from "./fileUploadContext";

export const DialogWarnZipFiles = ({open}) => {
    const t = useFileUploadTranslation();
    const [openState, setOpenState] = useState(false);

    const closeHandler = () => {
        setOpenState(false);
    };

    useEffect(() => {
        setOpenState(open);
    }, [open]);

    return <Dialog open={openState}>
        <DialogTitle>
            {t('zipFiles', 'ZIP-filer')}
        </DialogTitle>
        <DialogContent>
            {t('zipFilesInfo', 'Vi ser at det er lastet opp en eller flere ZIP-filer/-arkiver. ' +
                'Dette er en filtype, som av DAMS kan tolkes som flere objekttyper. Vi ber deg derfor gå igjennom de ' +
                'opplastede filene (merket med oransje), og påse at riktig objekttype er satt, evt. endre denne til ' +
                'korrekt objekttype.')}
        </DialogContent>
        <DialogActions>
            <Box flexGrow={1}/>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={closeHandler}>
                {t('btnOk', 'Ok')}
            </Button>
        </DialogActions>
    </Dialog>;
};