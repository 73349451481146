import {Dialog, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import {CLEAR_ALL, TOGGLE_OPEN_CONFIRM_DIALOG, useFileUploadDispatch, useFileUploadState} from "./fileUploadContext";
import {useNavigate} from "react-router-dom";
import {damsFetch} from "../app/damsFetch";
import {PromisePool} from "@supercharge/promise-pool";
import {clientLog} from "../clientLog";

/**
 * Dialog displayed, that allows the user to end the upload wizard at step 2.
 */
export const DialogConfirmEndWizard = ({numSteps}) => {

    const navigate = useNavigate();
    const fileUploadDispatch = useFileUploadDispatch();
    const {activeStep, openConfirmDialog, project, saved} = useFileUploadState();

    const hasWizardComeToTheEnd = () => (numSteps === (activeStep + 1));

    const deleteDAMSObjects = async () => {
        if (saved.length === 0) {
            return;
        }

        await PromisePool
            .withConcurrency(2)
            .for(saved)
            .process(async (damsObject) => {
                const modelId = damsObject.id;
                damsFetch(`/documents/${modelId}?erase=true`, {
                    method: "DELETE",
                })
                    .then((json) => {
                        return json;
                    })
                    .catch((error) => {
                        clientLog('error', error, 'DialogConfirmEndWizard');
                    });
            });
    };

    /**
     * Method run if the user decides to abort the upload wizard.
     * Deletes the objects, and quits the wizard.
     */
    const deleteAndQuit = () => {
        deleteDAMSObjects().then(quit);
    };

    /**
     * Method run when the wizard is complete.
     */
    const quit = () => {
        fileUploadDispatch({type: CLEAR_ALL});
        setTimeout(() => {
            if (!project) {
                navigate('/');
            } else {
                navigate(`/project/${project.id}`);
            }
        }, 100);
    };

    /**
     * Clicking the "Cancel" button, closes the dialog.
     */
    const handleCancelButtonClick = () => {
        fileUploadDispatch({
            type: TOGGLE_OPEN_CONFIRM_DIALOG
        });
    };

    /**
     * Clicking the "OK" button redirect the user to the DAMS frontpage, or the project's front page,
     * depending on the current context.
     */
    const handleOkButtonClick = () => {
        if (hasWizardComeToTheEnd()) {
            quit();
        } else {
            deleteAndQuit();
        }
    };

    const getDialogTitle = () => {
        if (hasWizardComeToTheEnd()) {
            return 'Filopplasting fullført';
        } else {
            return 'Avslutte filopplasting';
        }
    };

    /**
     * Returns the dialog text, based on the current step/context.
     * @returns {JSX.Element}
     */
    const getDialogText = () => {
        if (activeStep === 0) {
            return <Typography>
                Du har ikke lastet opp noen filer, ønsker du å avslutte?
            </Typography>;
        }
        if (activeStep === 1 && !project) {
            return <>
                <Typography>
                    Ønsker du å avslutte nå, mister du muligheten til å legge filene i mapper.
                </Typography>
                <Typography>
                    MERK: Dette kan gjøres på et senere tidspunkt.
                </Typography>
            </>;
        }
        if (hasWizardComeToTheEnd()) {
            return <>
                <Typography>Filene er lastet opp, og wizard avsluttes.</Typography>
                {!project && <Typography>Du tas tilbake til DAMS forside.</Typography>}
                {project && <Typography>Du tas tilbake til prosjektets side.</Typography>}
            </>;
        }
    };

    return <Dialog open={openConfirmDialog} disableEscapeKeyDown={true}>
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <DialogContent>{getDialogText()}</DialogContent>
        <DialogActions>
            {!hasWizardComeToTheEnd() && <Button onClick={handleCancelButtonClick}>Tilbake</Button>}
            <Button onClick={handleOkButtonClick}>Ok</Button>
        </DialogActions>
    </Dialog>;
};