import {kitFetch, kitGetBlob} from "@ekultur/fetch";
import camelcaseKeysDeep from "camelcase-keys-deep";

// const DAMS_URL =

const _getDamsUrl = () => {
    return window._env_.REACT_APP_DAMS_ADMIN_API;
}

/**
 * Gets the x-quote-mode value appended to each backend-request in order to
 * redirect to the correct backend instance.
 * @returns {string}
 */
export const getXQuoteMode = () => {
    return window._env_.REACT_APP_X_QUOTE_MODE;
};

/**
 * Creates an x-quote-mode header entry in the options object or adds it to an existing array of headers.
 * @param options
 * @returns {{headers}|*|{headers: {"x-quote-mode": string}}}
 */
export const appendXQuoteModeHeader = (options) => {
    const xQuoteModeArg = getXQuoteMode();
    if (options && options.headers) {
        if (!Object.keys(options.headers).includes("x-quote-mode")) {
            options.headers["x-quote-mode"] = xQuoteModeArg;
        }
        return options;
    } else {
        if (!options) {
            return {
                headers: {
                    "x-quote-mode": xQuoteModeArg,
                },
            };
        } else {
            options.headers = {"x-quote-mode": xQuoteModeArg};
            return options;
        }
    }
};

export const damsFetch = async (url, options) => {
    return kitFetch(`${_getDamsUrl()}${url}`, appendXQuoteModeHeader(options)).then(
        (json) => Promise.resolve(camelcaseKeysDeep(json))
    ).catch(e => {
        return Promise.reject(e);
    });
};

export const damsSearch = (params) => {
    return damsFetch(`/search?${params.toString()}`);
};

export const damsFetchPDF = (url, options) => {
    return kitGetBlob(`${_getDamsUrl()}${url}`, appendXQuoteModeHeader(options))
        .then((response) => {
            return response.arrayBuffer();
        })
        .then((data) => Promise.resolve(data));
};

export const solrEscape = (q) => q.replace("(", "(").replace(")", ")");
