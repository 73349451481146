import React, {useEffect} from "react";
import {DamsForm} from "../form/DamsForm";
import {usePostDocuments} from "../documents/usePostDocuments";
import {UPDATE_FORM_DATA, useDocumentDispatch, useDocumentState,} from "../documents/documentContext";
import * as Yup from "yup";
import {
    deleteMarkedClauses,
    deleteMarkedLicenses,
    mergeCopyrightDates,
    mergeCopyrightInfo,
    mergeLicenseInfo
} from "../app/metadataUtils";
import {CLEAR_ALL, useCopyrightDispatch} from "../copyright/copyrightContext";
import {setCopyrightClauseDates, setCopyrightResponsibleValues} from "../form/formHelper";

export const EditMetadataForm = ({model, onSuccess, children}) => {
    const docDispatch = useDocumentDispatch();
    const copyrightDispatch = useCopyrightDispatch();

    // eslint-disable-next-line no-unused-vars
    const [_, postDocuments] = usePostDocuments(); // NOSONAR

    const {formData} = useDocumentState();

    /**
     * Method used to create the initialValues object, based on values stored in the model.
     * @param m
     * @returns {{languages: *[], copyrightTypeResponsible: ([]|*|*[]), subjects: ([]|*|*[]), customIdentifier: (*|string), coordinates: (*|string), description: string, title: string, copyrightTerms: (*|string), copyrightTypeDateUntil: (*|string), persons: *[], copyrightType: (*|string), places: (*|*[]), licenses: *[], productionDate: (*|string), documentationType: (*|{}), licensesAssociated: ([]|*|*[]), producer: ([]|*|*[]), relations: (*|*[]), copyrightTypeOriginator: ([]|*|*[]), copyrightInfo: ([]|*|*[]), remarks: (*|string)}}
     */
    const getInitialValues = m => {
        const {title = '', description = '', content} = m;
        const {
            documentationType = {},
            productionDate = '',
            customIdentifier = '',
            producer = [],
            persons = [],
            places = [],
            subjects = [],
            licenses = [],
            licensesAssociated = [],
            copyrightInfo = [],
            copyrightType = '',
            copyrightTypeDateUntil = '',
            copyrightTypeOriginator = [],
            copyrightTypeResponsible = [],
            copyrightTerms = '',
            relations = [],
            remarks = '',
            languages = [],
            coordinates = ''
        } = content;

        // NOTE: JavaScript deconstructs with default values when the value is undefinede.
        // null is still interpreted as a value, therefore some of the values are altered
        // to a default value in the return statement below.
        return {
            title: title,
            description: description,
            documentationType: documentationType,
            customIdentifier: customIdentifier,
            productionDate: productionDate,
            producer: producer,
            persons: persons,
            places: places,
            subjects: subjects || [],
            licenses: licenses,
            licensesAssociated: licensesAssociated,
            copyrightInfo: copyrightInfo,
            copyrightType: copyrightType,
            copyrightTypeDateUntil: copyrightTypeDateUntil,
            copyrightTypeOriginator: copyrightTypeOriginator,
            copyrightTypeResponsible: copyrightTypeResponsible,
            copyrightTerms: copyrightTerms,
            relations: relations,
            remarks: remarks,
            languages: languages || [],
            coordinates: coordinates
        };
    };

    /**
     * Validate WGS84-based data field value.
     *
     * Exptected format: ddd.ddd, ddd.ddd
     * E.g. Lillehammer: 61.113332, 10.464403
     * NOTE:
     * - Accepts 10 decimals
     * - Values must be separated with comma
     * - Decimal-separator must be . (punctuation).
     * - Returns true if the provided value is not validated correctly.
     *
     * @param value str The string to evaluate
     * @returns {boolean}
     */
    const validateWGS84 = (value) => {
        if (!value || value === "") {
            return false;
        }

        value = value.replace("°", "");
        const mask = /(\+|-|\s*)(\d{1,3}\.\d{1,10})/gi; // NOSONAR
        if (!mask.test(value)) {
            return true;
        }

        const values = value.split(",");
        if (values.length < 2) {
            return true;
        }

        for (let i = 0, max = values.length; i < max; i++) {
            if (values[i] === "" || isNaN(Number(values[i])) || Number(values[i]) < -180 || Number(values[i]) > 180) {
                return true;
            }
        }

        return false;
    };

    const setLicenseValues = (licenses, initialValues) => {
        if (!licenses) {
            return;
        }

        const numLicenses = licenses[0] !== undefined && licenses.length ? licenses.length : 0;
        for (let i = 0; i < numLicenses; i++) {
            initialValues[`license${i}`] = licenses[i];
            initialValues[`licensesAssociated${i}`] = licenses[i]['responsible'];
        }
    };


    /**
     * Hook used to create the initialValues object, and update the formData object with these values.
     */
    useEffect(() => {
        if (!model.content) {
            return;
        }

        const {licenses, copyrightInfo} = model.content;

        const initialValues = getInitialValues(model);

        setLicenseValues(licenses, initialValues);
        setCopyrightResponsibleValues(copyrightInfo, initialValues);
        setCopyrightClauseDates(copyrightInfo, initialValues);

        docDispatch({type: UPDATE_FORM_DATA, formData: initialValues});
    }, [model, docDispatch]);

    /**
     * Yup schema, used when validating the form values.
     * NOTE:
     * The coordinates field is evaluated with the "optional" option, as this field may not be present
     * in some cases.
     */
    const dateRegEx = /(^$)|(\d{2}.\d{2}.\d{4}|\d{4})/g;
    const validationSchema = Yup.object({
        title: Yup.string(),
        description: Yup.string(),
        documentationType: Yup.object(),
        customIdentifier: Yup.string(),
        productionDate: Yup.string()
            .transform(arg => {
                return arg === null ? '' : arg;
            }).matches(dateRegEx, "dd.mm.yyyy | yyyy"),
        producer: Yup.array(),
        persons: Yup.array(),
        places: Yup.array(),
        subjects: Yup.array(),
        licenses: Yup.array(),
        licensesAssociated: Yup.array(),
        copyrightInfo: Yup.array(),
        copyrightType: Yup.string(),
        copyrightTypeDateUntil: Yup.string()
            .transform(arg => {
                return arg === null ? '' : arg;
            }).matches(dateRegEx, "dd.mm.yyyy | yyyy"),
        copyrightTypeOriginator: Yup.array(),
        copyrightTypeResponsible: Yup.array(),
        copyrightTerms: Yup.string(),
        relations: Yup.array(),
        remarks: Yup.string(),
        languages: Yup.array(),
        coordinates: Yup.string()
            .optional()
            .test("has-digits", "WGS84 - lat,lng - eks.:  61.11514, 10.46628", (value, _context) => {
                return !validateWGS84(value);
            })
    });

    const onSubmit = (values) => {
        deleteMarkedClauses(formData, values);
        deleteMarkedLicenses(formData, values);

        mergeLicenseInfo(values);
        mergeCopyrightInfo(values);
        mergeCopyrightDates(values);

        const updatedModel = {
            ...model,
            imageUrl: null,
            title: values.title,
            description: values.description,
            content: {
                ...model.content,
                customIdentifier: values.customIdentifier,
                productionDate: values.productionDate,
                producer: values.producer,
                persons: values.persons,
                places: values.places,
                subjects: values.subjects,
                licenses: values.licenses,
                licensesAssociated: values.licensesAssociated,
                copyrightInfo: values.copyrightInfo,
                copyrightType: values.copyrightType,
                copyrightTypeDateUntil: values.copyrightTypeDateUntil,
                copyrightTypeOriginator: values.copyrightTypeOriginator,
                copyrightTypeResponsible: values.copyrightTypeResponsible,
                copyrightTerms: values.copyrightTerms,
                relations: values.relations,
                remarks: values.remarks,
                languages: values.languages,
                coordinates: values.coordinates,
                documentationType: values.documentationType,
            },
        };

        Promise.all(postDocuments([updatedModel])).then((updatedDocuments) => {
            copyrightDispatch({type: CLEAR_ALL});
            onSuccess(updatedDocuments);
        });
    };

    return (
        <DamsForm initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit}>
            {children}
        </DamsForm>
    );
};
