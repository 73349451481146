import React, {useEffect} from "react";
import {FormikTextField} from "../form/FormikTextField";
import {
    CHOOSE_COLLECTIONS,
    useDocumentDispatch,
    useDocumentState,
    useDocumentTranslation,
} from "../documents/documentContext";
import {InputProducer} from "../person/InputProducer";
import {InputPersons} from "../person/InputPersons";
import {InputPlaces} from "../place/InputPlaces";
import {InputSubjects} from "../subject/InputSubjects";
import {ColumnForm} from "../form/ColumnForm";
import {useFormikContext} from "formik";
import {InputRelatedObjects} from "../related/InputRelatedObjects";
import {InputLanguages} from "../language/InputLanguages";
import {FormikProductionDate} from "../form/FormikProductionDate";
import {InputDocumentationType} from "../project/archeology/InputDocumentationType";
import {FormikWGS84Field} from "../form/FormikWGS84Field";
import {CopyrightSectionWrapper} from "../copyright/CopyrightSectionWrapper";
import {CopyrightProvider} from "../copyright/copyrightContext";

export const FormMetadata = ({
                                 isProjectObject,
                                 collectionId,
                                 disabled = false,
                                 fileUploadMode = false,
                                 batchEdit = false
                             }) => {
    const docDispatch = useDocumentDispatch();
    const t = useDocumentTranslation();
    const {formData, collectionIds} = useDocumentState();
    const {resetForm} = useFormikContext();

    useEffect(() => {
        if (!disabled) {
            resetForm();
        }
    }, [disabled, resetForm]);

    useEffect(() => {
        if (!collectionId || typeof collectionId === 'undefined') {
            return;
        }
        docDispatch({
            type: CHOOSE_COLLECTIONS,
            collectionIds: [collectionId]
        });
    }, [collectionId, docDispatch]);

    return (
        <ColumnForm>
            {/* The title is not to be changed for objects related to a project */}
            {!isProjectObject && <FormikTextField
                formikKey={"title"}
                label={t("title", "Tittel")}
                required={true}
                disabled={disabled}
                fullWidth={true}
                helperText={t("titleHelp", "En kort, beskrivende, tekst")}
                size={"small"}
            />}

            {isProjectObject && <InputDocumentationType
                formikKey={"documentationType"}
                label="Dokumentasjonstype"
                disabled={disabled}
                fullWidth={true}
                size={"small"}
            />}

            <FormikTextField
                formikKey={"description"}
                label={t("description", "Beskrivelse")}
                helperText={t("descriptionHelp", "Omhandler motivet/opptaket")}
                disabled={disabled}
                fullWidth={true}
                size={"small"}
            />
            <FormikTextField
                formikKey={"customIdentifier"}
                label={t("customIdentifier", "Museumsnummer")}
                helperText={t(
                    "customIdentifierHelp",
                    "Unikt identifikasjonsnummer i samlingen"
                )}
                disabled={disabled}
                fullWidth={true}
                size={"small"}
            />

            <FormikProductionDate
                formikKey={"productionDate"}
                label={t("productionDate", "Produksjonsdato")}
                disabled={disabled}
                fullWidth={true}
                size={"small"}
                t={t}
            />

            <InputProducer
                formikKey={"producer"}
                disabled={disabled}
                fullWidth={true}
                label={t("producer", {context: formData.documentType})}
                helperText={t("producerHelpTxt", "Søk, eller legg til ny")}
                createLabel={t("addNewPersonHeader", "Legg til ny person")}
                size={"small"}
            />
            <InputPersons
                formikKey={"persons"}
                disabled={disabled}
                fullWidth={true}
                label={t("persons", "Personer")}
                createLabel={t("addNewPersonHeader", "Legg til ny person")}
                helperText={t("personsHelp", "Som omtales/er avbildet")}
                size={"small"}
            />
            <InputPlaces
                formikKey={"places"}
                disabled={disabled}
                fullWidth={true}
                label={t("places", "Steder")}
                createLabel={t("addNewPlaceHeader", "Legg til nytt sted")}
                size={"small"}
            />

            {isProjectObject && <FormikWGS84Field
                formikKey={"coordinates"}
                label={"Koordinater (WGS84)"}
                disabled={disabled}
                fullWidth={true}
                size={"small"}
            />
            }

            <InputSubjects
                formikKey={"subjects"}
                label={t("subjects", "Emneord")}
                disabled={disabled}
                fullWidth={true}
                size={"small"}
            />
            <InputLanguages
                formikKey={"languages"}
                label={t("languages", "Språk")}
                helperText={t("languagesHelp", "Som brukes i opptaket/teksten")}
                disabled={disabled}
                fullWidth={true}
                size={"small"}
            />

            {!batchEdit && (
                <CopyrightProvider>
                    <CopyrightSectionWrapper t={t} collectionId={collectionId || collectionIds}
                                             fileUploadMode={fileUploadMode}/>
                </CopyrightProvider>)}

            <InputRelatedObjects
                formikKey={"relations"}
                label={t("relations", "Relaterte objekter")}
                disabled={disabled}
                modelUniqueId={formData.uniqueId}
                fullWidth={true}
                helperText={t(
                    "relationsHelp",
                    "Søk etter andre objekt i DAMS på ID, eller tittel/beskrivelse."
                )}
                size={"small"}
            />
            <FormikTextField
                formikKey={"remarks"}
                label={t("remarks", "Kommentar")}
                helperText={t("remarksHelp", "Ytterligere informasjon")}
                disabled={disabled}
                fullWidth={true}
                size={"small"}
            />
        </ColumnForm>
    );
};
