import React, {useState} from "react";
import {Button, Dialog, DialogContent, Typography} from "@mui/material";
import {useTheme} from "@mui/styles";
import {
    CANCEL_CREATE_PROJECT,
    PROJECT_SAVE,
    useProjectDispatch,
    useProjectState,
    useProjectTranslation,
} from "../../projectContext";
import {SelectMuseumUsers} from "../../SelectMuseumUsers";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

export const ArcheologyProjectAddUsers = () => {
    const t = useProjectTranslation();
    const theme = useTheme();
    const projectDispatch = useProjectDispatch();
    const {museumId, acls} = useProjectState();
    const [saving, setSaving] = useState(false);

    const onSaveHandler = () => {
        setSaving(true);
        projectDispatch({
            type: PROJECT_SAVE,
            acls: acls,
            save: true,
        });
    };

    return (
        <Dialog open={true}>
            <DialogTitle>
                <Typography variant={"h6"}>
                    {t('addProjectUsersHeading', 'Gi DAMS-brukere tilgang til prosjektet')}
                </Typography>
                <Typography variant={"body1"}>
                    {t('addProjectUsersDescription', 'Du kan ogå gi brukere tilgang, samt administrere brukernes rettigheter senere.')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <SelectMuseumUsers museumId={museumId}/>
            </DialogContent>
            <DialogActions>
                <Button
                    color={"secondary"}
                    onClick={() => {
                        projectDispatch({type: CANCEL_CREATE_PROJECT});
                    }}
                >
                    {t('btnCancel', 'Avbryt')}
                </Button>

                <LoadingButton
                    color={"secondary"}
                    variant={"contained"}
                    loading={saving}
                    loadingIndicator={"Lagrer..."}
                    sx={{marginLeft: theme.spacing(2)}}
                    onClick={onSaveHandler}
                >
                    {t('btnCreateProject', 'Opprett prosjekt')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
