import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {
    SHOW_ADD_COPYRIGHT_CLAUSE_FIELD,
    SHOW_ADD_LICENSE_FIELD,
    useCopyrightDispatch,
    useCopyrightState
} from "./copyrightContext";
import {CopyrightClauseWrapper} from './CopyrightClauseWrapper';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import Stack from "@mui/material/Stack";
import {LicenseFieldsWrapper} from "./LicenseFieldsWrapper";
import Divider from "@mui/material/Divider";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {useDocumentDispatch, useDocumentState} from "../documents/documentContext";
import {useFormikContext} from "formik";
import {CopyrightTypeFieldsWrapper} from "./CopyrightTypeFieldsWrapper";
import {FormikTextField} from "../form/FormikTextField";

import {deleteCopyrightTypeHandler, deleteHandler} from "./deletehandlers";


/**
 * Button used when adding a license or a copyright clause.
 * @param label
 * @param dispatchValue
 * @returns {JSX.Element}
 * @constructor
 */
export const BtnAdd = ({label, dispatchValue}) => {
    const copyrightDispatch = useCopyrightDispatch();
    return <Button
        sx={{
            marginBottom: 2
        }}
        startIcon={<AddIcon size={"small"}/>}
        variant={'text'}
        color={"secondary"}
        onClick={() => {
            copyrightDispatch({
                type: dispatchValue
            });
        }
        }
    >
        {label}
    </Button>;
};

export const BtnDeleteCopyrightType = ({fileUploadMode}) => {
    const docDispatch = useDocumentDispatch();
    const {formData} = useDocumentState();
    const {setFieldValue} = useFormikContext();

    if (fileUploadMode) {
        return <></>;
    }

    return (<><Divider orientation={"vertical"} flexItem={true}
                       sx={{width: '3px', marginLeft: 1, marginRight: 1}}/>
        <IconButton
            key={`btnDeleteCopyrightType`}
            size={"large"}
            variant={"contained"}
            onClick={() => {
                deleteCopyrightTypeHandler(formData, setFieldValue, docDispatch);
            }}
        >
            <DeleteIcon/>
        </IconButton>
    </>);

}

/**
 * Button used when deleting a license or a copyright clause.
 * @param fileUploadMode    boolean
 * @param objectType    string  license | clause
 * @param object    object  The object to be deleted
 * @param ix    in  The field's index
 * @param values object
 * @returns {JSX.Element|string}
 * @constructor
 */
export const BtnDelete = ({fileUploadMode, objectType, object, ix, values = null}) => {
    const docDispatch = useDocumentDispatch();
    const {formData} = useDocumentState();
    const {setFieldValue} = useFormikContext();

    if (fileUploadMode) {
        return '';
    }
    return (<>
            <Divider orientation={"vertical"} flexItem={true}
                     sx={{width: '3px', marginLeft: 1, marginRight: 1}}/>
            <IconButton
                key={`btnDelete${ix}`}
                size={"large"}
                variant={"contained"}
                onClick={() => deleteHandler(docDispatch, formData, setFieldValue, objectType, ix, object, values)}
            >
                <DeleteIcon/>
            </IconButton>
        </>
    );
};

/**
 * Wrapper that contains the fields that holds the copyright information.
 * @param t method  Translation method
 * @param collectionId str  The model's collection ID
 * @param fileUploadMode str
 * @returns {JSX.Element}
 * @constructor
 */
export const CopyrightSectionWrapper = ({t, collectionId, fileUploadMode}) => {
    const {showAddLicenseField, showAddCopyrightClause} = useCopyrightState();
    const {formData} = useDocumentState();
    const {values} = useFormikContext();
    const [loaded, setLoaded] = useState(false);

    /**
     * Hook used to verify that the formData has been loaded.
     */
    useEffect(() => {
        if (!formData) {
            return;
        }
        if (Object.keys(formData).length > 0) {
            setLoaded(true);
        }
    }, [!fileUploadMode ? formData : undefined]); // NOSONAR

    /**
     * Displays the form fields related to the existing licenses.
     * @returns {*}
     */
    const getExistingLicenses = () => {
        return <><Typography variant={'h6'}>{t('headingLicenses', 'Lisenser')}</Typography>
            {formData?.licenses?.map((_l, ix) => {
                const itemKey = _l.reference.id;
                return <Stack key={`lic-${itemKey}`} model
                              direction={"row"}
                              sx={{marginBottom: 1.5, width: '100%'}}
                              alignItems={'center'}>
                    <LicenseFieldsWrapper key={`licenseWrapper-${itemKey}`}
                                          t={t}
                                          ix={ix}/>
                    <BtnDelete objectType={'license'} object={_l} fileUploadMode={fileUploadMode} ix={ix}
                               values={null}/>
                </Stack>
            })}
        </>
    };

    /**
     * Displays the form fields related to the existing copyright clauses.
     * @returns {JSX.Element}
     */
    function getExistingCopyrightClauses() {
        const copyrightInfo = formData?.copyrightInfo;
        return <>
            <Typography variant={'h6'}>{t('headingCopyrightClause', 'Klausul')}</Typography>
            {copyrightInfo?.length > 0 && (
                copyrightInfo?.filter(c => !c.deleted === true).map((_c, ix) => {
                    const itemKey = _c.sourceId;
                    return <Stack key={`copy-${itemKey}`} direction={"row"} sx={{marginBottom: 1.5, width: '100%'}}
                                  alignItems={'center'}>
                        <CopyrightClauseWrapper key={`copyrightClauseWrapper-${itemKey}`}
                                                t={t}
                                                ix={ix}
                                                collectionId={collectionId}
                        />

                        <BtnDelete objectType={'clause'} object={_c} fileUploadMode={fileUploadMode} ix={ix}
                                   values={values}/>
                    </Stack>
                }))
            }
        </>;
    }

    /**
     * Displays the copyright type.
     * @returns {JSX.Element}
     */
    function getCopyrightType() {
        return <>
            <Typography variant={'h6'} gutterBottom>{t('headingCopyrightType', 'Opphavsrett')}</Typography>
            <Stack key={`ctype`} direction={"row"} sx={{marginBottom: 1.5, width: '100%'}} alignItems={'center'}>
                <CopyrightTypeFieldsWrapper t={t}/>
                <BtnDeleteCopyrightType fileUploadMode={fileUploadMode}/>
            </Stack>
        </>;
    }

    return loaded && (<>
            <Stack alignItems={'flex-start'} sx={{width: '100%'}}>
                <Typography variant={'h5'}
                            sx={{marginBottom: 2}}>{t('headingRights', 'Rettigheter')}</Typography>

                {/* Copyright type */}
                {getCopyrightType()}

                {/* Licenses */}
                {getExistingLicenses()}
                {showAddLicenseField && <LicenseFieldsWrapper key={'noLicenses'}
                                                              t={t}
                                                              ix={formData.licenses.length}/>
                }
                <BtnAdd label={t('btnAddNewLicense', 'Legg til nytt lisensfelt')}
                        dispatchValue={SHOW_ADD_LICENSE_FIELD}/>

                {/* Clauses */}
                {getExistingCopyrightClauses()}

                {showAddCopyrightClause &&
                    <CopyrightClauseWrapper key={'noCopyrightClauses'}
                                            t={t}
                                            ix={formData.copyrightInfo.length}
                                            collectionId={collectionId}/>
                }

                <BtnAdd label={t('btnAddCopyrightClause', 'Legg til klausuler')}
                        dispatchValue={SHOW_ADD_COPYRIGHT_CLAUSE_FIELD}/>
            </Stack>

            <FormikTextField formikKey={'copyrightTerms'}
                             label={t('copyrightTerms', 'Rettighetsbetingelser')}
                             helperText={t('copyrightTermsHelpText', 'Betingelser som gjelder rettighetene for objektet')}
                             fullWidth={true}
                             multiline={true}
                             size={"small"}/>
        </>
    );

};