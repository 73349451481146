import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import React from "react";
import {TOGGLE_OPEN_CONFIRM_DIALOG, useFileUploadDispatch, useFileUploadState} from "./fileUploadContext";

export const UploadStepperActionButtons = ({numSteps, nextCallback}) => {
    const {projectId, activeStep, uploadedFiles} = useFileUploadState();
    const fileUploadDispatch = useFileUploadDispatch();

    const showCancelButton = () => (activeStep === 0 && uploadedFiles.length === 0);
    const showEndButton = () => (projectId === -1 && activeStep < (numSteps - 1));

    /**
     * Displays the dialog that allows the user to confirm whether to cancel the wizard or not.
     */
    const handleCancel = () => {
        fileUploadDispatch({
            type: TOGGLE_OPEN_CONFIRM_DIALOG
        });
    };

    /**
     * Button that allows the user to cancel/terminate the wizard, before any files have been uploaded.
     */
    const getCancelButton = () => {
        if (!showCancelButton()) {
            return;
        }
        return <Button onClick={handleCancel} sx={{marginRight: '8px'}} color={"secondary"}>Avbryt</Button>;
    };

    /**
     * Button that allows the user to end the wizard when the files have already been uploaded.
     */
    const getEndButton = () => {
        if (!showEndButton()) {
            return;
        }
        return <Button onClick={handleCancel}
                       variant={"outlined"}
                       disabled={activeStep === 0}
                       sx={{marginRight: '8px'}}
                       color={"secondary"}>Avslutt</Button>;
    };

    const getFinishWizardButton = () => {
        const buttonText = activeStep === numSteps - 1 ? 'Avslutt' : 'Fortsett'
        return <Button onClick={nextCallback} variant={"contained"} disabled={uploadedFiles.length === 0}
                       color={"secondary"}>
            {buttonText}
        </Button>;
    }

    return <Stack direction={"row"} justifyContent={'flex-end'} sx={{
        position: 'fixed',
        bottom: 0,
        margin: 0,
        padding: '16px',
        textAlign: 'right',
        width: '100%',
        backgroundColor: '#673AB7',
    }}>
        {getCancelButton()}
        {getEndButton()}
        {getFinishWizardButton()}
    </Stack>;
}