import {
    SET_QUERY,
    SET_SEARCHING,
    useFoldersDispatch,
    useFoldersState,
    useFoldersTranslation,
} from "../folder/FoldersContext";
import {useFolderSearch} from "../folder/useFolderSearch";
import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent} from "@mui/material";
import {TOGGLE_ADD_TO_FOLDER, useResultActionDispatch, useResultActionState} from "./ResultActionContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import FolderIcon from "@mui/icons-material/Folder";
import Grid from "@mui/material/Grid";
import {SelectableFolderItem} from "../folder/SelectableFolderItem";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {usePostFolderDocument} from "../folder/usePostFolderDocument";
import {SET_SELECTED, UNSELECT_ALL, useSearchDispatch} from "./SearchContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useMyMuseums} from "../museum/useMyMuseums";


/**
 * Renders the AddToFolderModal component.
 *
 * @param {Object} selectedDocuments - The selected documents to be added to a folder.
 * @return {JSX.Element} The AddToFolderModal component JSX.
 */
export const AddToFolderModal = ({selectedDocuments}) => {

    const t = useFoldersTranslation();

    const myMuseums = useMyMuseums({});

    const {showAddToFolder} = useResultActionState();
    const {query, sort, start, rows, fq, results} = useFoldersState();

    const dispatch = useResultActionDispatch();
    const foldersDispatch = useFoldersDispatch();
    const searchDispatch = useSearchDispatch();
    const snackbarDispatch = useSnackbarDispatch();

    const [inputValue, setInputValue] = useState('');
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [ready, setReady] = useState(false);

    const [postFolderDocumentResponse, postFolderDocument, clearAll] = usePostFolderDocument(selectedFolder);

    /**
     * Hook used to get the list of folders.
     */
    useFolderSearch({
        query: query,
        sort: sort,
        start: start,
        rows: rows,
        documentType: '(Folder)',
        folderType: "(!ProjectArcheology !ProjectGeneral)",
        fq: fq,
        museums: myMuseums.map(m => m.id).join(','),
        status: '(-trash)'
    });


    /**
     * Shows the error snackbar if one or more documents were not added to the selected folder.
     */
    const showErrorSnackbar = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("addToFolderErrorTitle", "Feilet"),
                body: t(
                    "addToFolderErrorMessage",
                    "{{count}} dokumenter ble ikke lagt til i mappen {{folderName}}",
                    {
                        count: postFolderDocumentResponse.error,
                        folderName: results.models.find(
                            (model) => model.id === selectedFolder
                        ).title,
                    }
                ),
                type: "error",
            },
        });
    };

    /**
     * Snackbar displayed if all documents were successfully added to the selected folder.
     */
    const showSuccessSnackbar = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("addToFolderSuccessTitle", "Lagret"),
                body: t(
                    "addToFolderSuccessMessage",
                    "{{count}} dokumenter ble lagt til i mappen {{folderName}}",
                    {
                        count: postFolderDocumentResponse.success,
                        folderName: results.models.find(
                            (model) => model.id === selectedFolder
                        ).title,
                    }
                ),
                type: "success",
            },
        });
    }

    /**
     * Close the dialog
     */
    const onHide = () => {
        setSelectedFolder(null);
        dispatch({type: TOGGLE_ADD_TO_FOLDER});
    };

    /**
     * Add the selected documents to the selected folder.
     */
    const addToFolderHandler = () => {
        selectedDocuments.forEach(model => postFolderDocument({documentId: model.uniqueId}));
    };

    /**
     * Search for a folder matching the search-criteria.
     */
    const searchOnEnter = (event) => {
        const q = inputValue === "" ? "*" : inputValue;
        if ("Enter" === event.key) {
            foldersDispatch({
                type: SET_QUERY,
                query: q,
                folderContext: true,
            });
            foldersDispatch({type: SET_SEARCHING});
        }
    };

    /**
     * Await initial search until museums have been resolved.
     */
    useEffect(() => {
        if (myMuseums.length === 0 || ready) {
            return;
        }

        setReady(true);
        foldersDispatch({
            type: SET_SEARCHING
        });
    }, [myMuseums, ready, foldersDispatch]);

    /**
     * Hook used to determine whether the list of folders have been loaded.
     */
    useEffect(() => {
        if (!results) {
            return;
        }
        setLoaded(true);
    }, [results]);

    useDeepCompareEffect(() => {
        const {pending, error, success, failingIds} = postFolderDocumentResponse;

        if (pending > 0) {
            return;
        }

        // Documents are added to the folders, unselect selected documents in the search results.
        searchDispatch({type: UNSELECT_ALL});

        if (error > 0) {
            showErrorSnackbar();

            // Make the failing documents appear still selected in the search results.
            searchDispatch({
                type: SET_SELECTED,
                selected: [failingIds]
            });
            onHide();
        }

        if (success > 0) {
            clearAll();
            showSuccessSnackbar();
            onHide();
        }
    }, [postFolderDocumentResponse]);

    return loaded && (
        <Dialog
            open={showAddToFolder}
            onClose={onHide}
            PaperProps={{
                style: {
                    minWidth: '80%',
                    minHeight: '80%',
                    overflow: 'hidden',
                    overflowY: 'auto'
                }
            }}>
            <DialogTitle>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    {t('addFoldersHeader', 'Legg til i mappe')}
                    <TextField
                        id="dams-search"
                        label={t("searchInFolders", "Søk i mapper")}
                        type="search"
                        variant="standard"
                        size={"small"}
                        onKeyDown={searchOnEnter}
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                    />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                >
                    {
                        results.models.map(folder => (
                            <Grid item key={folder.id}>
                                <SelectableFolderItem
                                    folder={folder}
                                    selected={folder.id === selectedFolder}
                                    onSelect={() => setSelectedFolder(folder.id)}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"secondary"} onClick={onHide}>
                    {t('cancel', 'Avbryt')}
                </Button>
                <LoadingButton
                    loading={postFolderDocumentResponse.pending > 0}
                    variant={"contained"}
                    color={"primary"}
                    onClick={addToFolderHandler}
                    loadingPosition={"start"}
                    startIcon={<FolderIcon/>}
                >
                    {t('addToFolderButton', 'Legg i mappe')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
