import React from "react";
import {CLOSE, OPEN, useResultActionDispatch, useResultActionState,} from "./ResultActionContext";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";

const ResultActions = ({t, children, numSelected}) => {
    const dispatch = useResultActionDispatch();
    const {anchorEl} = useResultActionState();

    const handleClose = () => {
        dispatch({type: CLOSE});
    };

    const handleClick = (event) => {
        if (anchorEl) {
            handleClose();
        } else {
            dispatch({
                type: OPEN,
                anchorEl: event.currentTarget,
            });
        }
    };

    return (
        <>
            <ButtonGroup
                variant="text"
                color="secondary"
                aria-label="search-results-action-button"
            >
                <Button
                    aria-controls={Boolean(anchorEl) ? "action-menu" : undefined}
                    aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                    aria-label="trigger action"
                    aria-haspopup="menu"
                    onClick={handleClick}
                >
                    {t("choices", "Valg")}&nbsp;({numSelected})
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </>
    );
};

ResultActions.propTypes = {
    t: PropTypes.func.isRequired
};

export {ResultActions};