import React from "react";
import {InputPersons} from "../person/InputPersons";

/**
 * Input field used in combination with a license, in order to set the associated person/company.
 * @param t     method  Translation method
 * @param ix    int The field's index number
 * @param size  str Input field size large | medium | small
 * @returns {JSX.Element}
 * @constructor
 */
export const FormikSelectAssociated = ({t, ix, size}) => {
    const k = `licensesAssociated${ix}`;
    return <InputPersons disabled={false}
                         label={t('lblSelectAssociated', 'Tilknyttede personer/firma')}
                         formikKey={k}
                         createLabel={t('lblAddPersonCompany', 'Legg til person/firma')}
                         alwaysActive={true}
                         fullWidth={true}
                         size={size}/>;
};