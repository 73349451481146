import {WithSaveCircle} from "../../app/WithSaveCircle";
import {Grow, Modal, Paper} from "@mui/material";
import {FormCreateFolder} from "../../folder/FormCreateFolder";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {RESET_CREATE_FOLDER, useResultActionDispatch, useResultActionState} from "../ResultActionContext";
import {useAuthsState} from "../../auths/authsContext";
import {useFolderShareEmail} from "../../folder/useFolderShareEmail";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../../app/damsFetch";
import {PromisePool} from "@supercharge/promise-pool";
import {clientLog} from "../../clientLog";
import {
    CREATE_FOLDER_STATE_RESET,
    FOLDER_CREATED,
    UPDATE_PROGRESS,
    useCreateFolderDispatch
} from "./CreateFolderContext";
import {ProgressSnackbar} from "./ProgressSnackbar";
import {saveNewTags} from "../../folder/tags/saveNewTags";

const PREFIX = "CreateFolderModalNew";

const classes = {
    modal: `${PREFIX}-modal`,
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
};

const StyledModal = styled(Modal)(({theme}) => ({
    [`&.${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.content}`]: {
        outline: "none",
        [theme.breakpoints.down("md")]: {
            height: "100%",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            height: (props) => (props['isFullscreen'] ? "100%" : "90%"),
            width: (props) => (props['isFullscreen'] ? "100%" : "70%"),
        },
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(4),
        height: "100%",
    },
}));

export const CreateFolderModalNew = ({selectedDocuments, onHideCallback}) => {
    const {showCreateFolder} = useResultActionState();

    const resultActionDispatch = useResultActionDispatch();
    const createFolderDispatch = useCreateFolderDispatch();

    const {userData} = useAuthsState();

    const [setEmailRecipients, sendEmailNotifications] = useFolderShareEmail();

    const [processing, setProcessing] = useState(false);

    const onHide = () => {
        createFolderDispatch({type: CREATE_FOLDER_STATE_RESET});
        closeCreateDialog();
        uncheckSelectedFiles();
    };

    const closeCreateDialog = () => {
        resultActionDispatch({type: RESET_CREATE_FOLDER});
    };

    const uncheckSelectedFiles = () => {
        onHideCallback();
    };

    /**
     *  Saving new tags to the DAMS database, if required, and appending them to the folderData object.
     * @param folderData
     * @returns {Promise<void>}
     */
    const appendTags = async (folderData) => {
        const {tags, collectionId} = folderData;
        if (!tags || tags.length === 0) {
            return;
        }

        const {results: newTags} = await saveNewTags(collectionId, tags);
        if (newTags) {
            folderData.tags = [
                ...folderData.tags.filter(t => !t.inputValue).map(t => ({reference: {...t}})),
                ...newTags
            ];
        } else if (!folderData.tags) {
            folderData.tags = newTags
        }
    };

    const save = async (folderData) => {

        setProcessing(true);
        uncheckSelectedFiles();

        const {folderName} = folderData;
        await appendTags(folderData);

        // Create the necessary folder ACLs.
        const usersAcl = createUsersAcl();
        const emailsAcl = createFolderAcls(folderData);

        // Create the folder, and add the selected documents.
        try {
            const createdFolder = await createFolder(folderData, emailsAcl, usersAcl);
            createFolderDispatch({type: FOLDER_CREATED, created: true});
            closeCreateDialog();

            const {results} = await addDocumentsToFolder(createdFolder, selectedDocuments);

            // Log warning, number of failed documents
            const failedDocuments = [...results].filter(r => !r.success);
            clientLog('warn',
                `${failedDocuments.length} documents not added to the folder ${folderName}`,
                'createfoldermodal');

            await sendEmails(createdFolder.id, emailsAcl, usersAcl);

            // Hide the processing dialog.
            setProcessing(false);

            // Reset state, and hide the create folder dialog.
            onHide();
        } catch (error) {
            clientLog('error', `failed to create the folder: ${error.toString()}`, 'createfoldermodal');
            setProcessing(false);
        }
    };

    const sendEmails = async (folderId, emailsAcl, usersAcl) => {
        return new Promise(async (resolve) => {

            // Set the recipients that will receive email.
            setEmailRecipients(null, emailsAcl, usersAcl);

            const res = await sendEmailNotifications(folderId); // NOSONAR
            const {results} = res;
            if (results.length > 0) {
                const successCount = [...results].filter(r => r.success)?.length || 0;
                const failedCount = results.length - successCount;

                clientLog('info',
                    `failed e-mails: ${failedCount} of total ${results.length}`,
                    'createfoldermodal');

                clientLog('info',
                    `successful e-mails: ${successCount} of total ${results.length}`,
                    'createfoldermodal');
            }
            resolve();
        });
    };


    const createFolder = async (folderJson, emailsAcl, usersAcl) => {
        const {collectionId, folderAvailability, folderName, tags} = folderJson;
        const folder = {
            collectionId: collectionId,
            locale: "no",
            status: folderAvailability,
            title: folderName,
            description: "",
            content: {
                usersAcl: usersAcl,
                tags: tags
            },
            acls: emailsAcl,
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep(folder))
        };
        return await damsFetch('/folders', options);
    };

    const addDocumentsToFolder = async (folder, documents) => {
        return await PromisePool
            .withConcurrency(4)
            .for(documents)
            .onTaskFinished((document, pool) => {
                const percentage = parseInt(pool.processedPercentage());
                createFolderDispatch({
                    type: UPDATE_PROGRESS,
                    percentage: percentage
                });
            })
            .process(async (document) => {
                return await addDocumentToFolder(folder, document);
            });
    };

    /**
     * Adds the specified document to the specified folder, and returns the results:
     *   {
     *     results: [
     *          { id: <doc id>, success: true | false },
     *          ...
     *      },
     *      errors: [
     *          { id: <doc id>, success: false },
     *          ...
     *      ]
     *   }
     * @param folder JSON   A JSON-object, holding the folder information.
     * @param document JSON A JSON-object, holding the document information.
     * @returns {Promise<{success: boolean, id}>}
     */
    const addDocumentToFolder = async (folder, document) => {
        const {id} = folder;
        const {id: docId} = document;

        // Get document
        try {
            const doc = await damsFetch(`/documents/${docId}`);
            if (doc) {
                // Keep existing folder refs. for the document.
                const existingFolderIds = doc['folderIds'];

                // Update folder refs.
                const folderIds = existingFolderIds ? [...existingFolderIds, id] : [id];

                const body = JSON.stringify(
                    decamelizeKeysDeep({
                        ...doc,
                        content: {
                            ...doc['content'],
                        },
                        folderIds: folderIds
                    })
                );
                await damsFetch(`/documents`, {
                    method: "POST",
                    body: body
                });
                return {id: docId, success: true};
            }
        } catch (error) {
            clientLog('error',
                `add document to folder failed: ${error.toString()}`,
                'createfoldermodal');
            return {id: docId, success: false};
        }
    };

    const createUsersAcl = () => {
        return [
            {
                referenceType: "user_acl",
                _action: "put",
                reference: {
                    locale: "no",
                    _action: "put",
                    title: userData.uniqueId,
                    status: "published",
                },
            },
        ];
    };

    const createFolderAcls = (folderJson) => {
        return [
            ...folderJson.readShare.map((readAcl) => ({
                access: "read",
                email: readAcl.email,
                expires_at: readAcl.expiresAt,
            })),
            ...folderJson.writeShare.map((writeAcl) => ({
                access: "write",
                email: writeAcl.email,
                expires_at: writeAcl.expiresAt,
            })),
        ];
    };

    return (<>
            <StyledModal
                className={classes.modal}
                open={showCreateFolder}
                onClose={onHide}
                closeAfterTransition
            >
                <div className={classes.content}>
                    <WithSaveCircle saving={false}>
                        <Grow in={showCreateFolder}>
                            <Paper className={classes.paper}>
                                <FormCreateFolder onSave={save} onHide={onHide}/>
                            </Paper>
                        </Grow>
                    </WithSaveCircle>
                </div>
            </StyledModal>
            {processing && <ProgressSnackbar/>}
        </>
    );
};
