import {decamelize} from "../app/decamelize";

/**
 * Functional component exposing method used to cache view settings, such as sort by column, display type etc.
 * for the different types of search results throughout the application.
 * @param storageKey str    Name of the key the values are stored under in localStorage.
 * @returns {{setDisplayType: setDisplayType, getDisplayType: (function(): string), getTableCols: (function(): *), setNumberOfRows: setNumberOfRows, getSortCriteria: (function(): string), setSortColumnAndOrder: setSortColumnAndOrder, getNumberOfRows: (function(): number), setTableCols: setTableCols}}
 * @constructor
 */
export const FolderSearchSettings = (storageKey) => {

    const defaultConfig = {
        numRows: 10,
        displayType: "grid",
        sort: {
            column: "createdAt",
            order: "desc",
        },
    };

    const _getConfig = () => {
        const configStr = localStorage.getItem(storageKey);
        if (configStr) {
            return JSON.parse(configStr);
        } else {
            return defaultConfig;
        }
    };

    function setNumberOfRows(value) {
        let c = {..._getConfig(), numRows: value};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function setDisplayType(value) {
        let c = {..._getConfig(), displayType: value};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function setSortColumnAndOrder(column, order) {
        let c = {..._getConfig(), sort: {column: column, order: order}};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function setTableCols(columns) {
        let c = {..._getConfig(), columns: columns};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function getTableCols() {
        return _getConfig().columns;
    }

    function getSortCriteria() {
        const c = _getConfig().sort;
        return decamelize(c.column) + " " + c.order;
    }

    function getNumberOfRows() {
        return _getConfig().numRows;
    }

    function getDisplayType() {
        return _getConfig().displayType;
    }

    return {
        getNumberOfRows,
        getDisplayType,
        getSortCriteria,
        getTableCols,
        setDisplayType,
        setNumberOfRows,
        setSortColumnAndOrder,
        setTableCols,
    };
};
