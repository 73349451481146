import {VolumeUpOutlined} from "@mui/icons-material";
import {DamsFileIcon} from "../../files/DamsFileIcon";
import React from "react";
import Box from "@mui/material/Box";
import {useStyles} from "./resultCardStyles";

export const ResultCardAudioIcon = ({mimeType, model, icon = true}) => {
    const classes = useStyles();
    return (
        <Box>
            <div className={classes['fileThumbContainer']}>
                <VolumeUpOutlined sx={{fontSize: 135, color: "#bbb"}}/>
            </div>
            <div className={classes['fileTypeContainer']}>
                {icon && <DamsFileIcon
                    mimeType={mimeType}
                    filename={model?.content?.mediae[0]?.reference?.title}
                    size={36}
                />}
            </div>
        </Box>
    );
};
