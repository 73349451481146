import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFoldersState} from "../FoldersContext";
import {AccordionFilter} from "../../search/AccordionFilter";
import {AccordionFilterTitle} from "../../search/filters/AccordionFilterTitle";
import List from "@mui/material/List";
import {useOptions} from "../tags/useOptions";
import {setFq} from "../tags/setFq";
import {renderCheckboxes} from "./renderCheckboxes";


/**
 * Method used to display the folder tags filter, used to fetch folders, based on specified tags.
 * @returns {JSX.Element}
 * @constructor
 */
export const FilterTags = ({callback}) => {

    const {t} = useTranslation('dams.folder', {useSuspense: false});

    const {fq, results} = useFoldersState();

    const facetKey = "tags.reference.title";

    const [loading, setLoading] = useState(true);

    const [facets, setFacets] = useState({});

    const [selected, setSelected] = useState([]);

    // Value entered by the user in order to search for a filter-/facet value.
    const [searchValue, setSearchValue] = useState('');

    const options = useOptions(loading, searchValue, selected, facets);

    const count = Object.keys(facets).length;   // The total number of different facet-values available.

    /**
     * Callback used when the user types the search criteria.
     * Used to filter the list of available filter-values/-options.
     * @param event
     */
    const searchForFilters = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    /**
     * Handles the click event of a checkbox and updates the selected filters.
     *
     * @param {string} value - The value of the checkbox that was clicked.
     * @return {void} This function does not return a value.
     */
    const handleCheckboxClick = (value) => {
        const filters = setFq(facetKey, value, selected);
        setSelected(filters);
        callback(filters);
    };

    /**
     * Hook used to extract the available tag facet-values from the current result set.
     */
    useEffect(() => {
        if (!results || !results.facets) {
            return;
        }

        if (Object.keys(results.facets).includes('tags')) {
            setFacets(results.facets['tags']);
            setLoading(false);
        }
    }, [results]);

    /**
     * Removes the checked filter values, when the user has clicked the "reset filters" button.
     */
    useEffect(() => {
        if (!fq || fq?.length === 0) {
            setSelected([]);
        }
    }, [fq]);

    return (!loading && Object.keys(facets).length > 0 &&
        <AccordionFilter
            title={
                <AccordionFilterTitle
                    title={`${t("tags", "Tags")} (${count})`}
                    value={searchValue}
                    label={t('tagsSearch', 'Søk...')}
                    onChangeCallback={searchForFilters}
                    setSearchValueCallback={() => {
                        setSearchValue('');
                    }}
                />
            }
            count={count}
        >
            <List>{renderCheckboxes(options, facets, handleCheckboxClick, selected, facetKey)}</List>
        </AccordionFilter>
    );

};