import {Box, Button, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    ARCHEOLOGY_PROJECT_SHOW_METADATA,
    CLEAR_ARCHEOLOGY_PROJECT_READ,
    useProjectDispatch,
    useProjectState,
    useProjectTranslation,
} from "../../projectContext";
import {useTheme} from "@emotion/react";

export const ArcheologyProjectChosenProject = () => {
    const t = useProjectTranslation();
    const theme = useTheme();
    const projectDispatch = useProjectDispatch();
    const {projectData} = useProjectState();

    const project = projectData.archeologyRemote;
    const {projectStart, projectEnd, projectType, name} = project;

    return (
        <>
            <Typography variant={"h6"} sx={{marginBottom: theme.spacing(4)}}>
                {t('chosenProjectHeading', 'Arkeologiprosjekt')}
            </Typography>
            <Typography variant={"subtitle1"} sx={{marginBottom: theme.spacing(1)}}>
                {t('chosenProjectLabel', 'Du har valgt')}:
            </Typography>
            <Box
                sx={{
                    backgroundColor: "#ddd",
                    padding: theme.spacing(2),
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box>
                    <Typography variant={"h6"}>
                        {name} ({projectStart} - {projectEnd})
                    </Typography>
                    <Typography variant={"subtitle1"} color={"secondary"}>
                        {projectType}
                    </Typography>
                </Box>
                <CheckCircleIcon
                    color={"secondary"}
                    sx={{marginLeft: theme.spacing(4), width: 40, height: 40}}
                />
            </Box>

            {/* Action buttons */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: theme.spacing(4),
                }}
            >
                <Button
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={() => {
                        projectDispatch({
                            type: CLEAR_ARCHEOLOGY_PROJECT_READ,
                        });
                    }}
                >
                    {t('btnGoBack', 'Gå tilbake')}
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    sx={{marginLeft: theme.spacing(2)}}
                    onClick={() => {
                        projectDispatch({
                            type: ARCHEOLOGY_PROJECT_SHOW_METADATA,
                        });
                    }}
                >
                    {t('btnCreateProject', 'Opprett prosjekt')}
                </Button>
            </Box>
        </>
    );
};
