import React from "react";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {FormikSelectAssociated} from "./FormikSelectAssociated";
import {FormikSelectLicense} from "../license/FormikSelectLicense";


/**
 * Wrapper that contains the fields related to one license entry.
 * @param t method  Translation method
 * @param ix    int The wrapper's index number
 * @returns {JSX.Element}
 * @constructor
 */
export const LicenseFieldsWrapper = ({t, ix}) => {
    const k = `license${ix}`;
    return <>
        <Stack direction={'row'} sx={{width: '100%'}}>
            <SubdirectoryArrowRightIcon sx={{color: '#ddd'}}/>
            <Box sx={{width: '100%'}}>
                <FormikSelectLicense
                    disabled={false}
                    formikKey={k}
                    label={t("licenses", "Lisenser")}
                    helperText={t("licensesHelpTxt", "Velg fra listen")}
                    fullWidth={true}
                    sx={{minWidth: '100%'}}
                    size={"small"}
                />
                <FormikSelectAssociated t={t} ix={ix} size={"small"}/>
            </Box>
        </Stack>
    </>;
}