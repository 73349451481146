import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {useTheme} from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    ARCHEOLOGY_PROJECT_ADD_USERS,
    CANCEL_CREATE_PROJECT,
    useProjectDispatch,
    useProjectState,
    useProjectTranslation,
} from "../../projectContext";
import {useEffect, useState} from "react";
import {getHrLatLngStr} from "../../projectUtility";

export const ArcheologyRemoteProjectShowMetadata = () => {
    const t = useProjectTranslation();
    const theme = useTheme();
    const projectDispatch = useProjectDispatch();
    const {projectData} = useProjectState();
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        setMetadata(projectData.archeologyRemote);
    }, [metadata]);

    const renderRows = () => {
        const {
            externalProjectUUID, projectId, name, projectType, projectStart, projectEnd, projectStatus, projectLatLng,
            projectMonumentPeriod, projectMonumentType, projectDescription, projectSystemId, projectSystemName
        } = metadata;

        const rowConfigs = [
            {label: t('projectUUID', 'UUID'), value: externalProjectUUID},
            {label: t('projectId', 'Prosjekt ID'), value: projectId},
            {label: t('projectName', 'Navn'), value: name},
            {label: t('projectType', 'Type'), value: projectType},
            {label: t('projectStarted', 'Tid start'), value: projectStart},
            {label: t('projectEnded', 'Tid slutt'), value: projectEnd},
            {label: t('projectStatus', 'Status'), value: projectStatus},
            {label: t('projectLatLng', 'LatLng'), value: getHrLatLngStr(projectLatLng?.lat, projectLatLng?.lng)},
            {label: t('projectDated', 'Datering'), value: projectMonumentPeriod || "<uspesifisert>"},
            {label: t('projectMonumentType', 'Monumenttype'), value: projectMonumentType || "<uspesifisert>"},
            {label: t('projectDescription', 'Beskrivelse'), value: projectDescription},
            {label: t('projectSystemId', 'System ID') + ': ', value: projectSystemId || '<uspesifisert>'},
            {label: t('projectSystemName', 'Systemnavn') + ': ', value: projectSystemName || '<uspesifisert>'}
        ];

        return rowConfigs.map((c) => (
            <Grid
                key={"k" + c.label}
                container
                direction={"row"}
                sx={{marginTop: theme.spacing(1)}}
            >
                <Grid item sx={{width: 100}}>
                    <Typography variant={"body1"} sx={{fontWeight: "bold"}}>
                        {c.label}
                    </Typography>
                </Grid>
                <Grid item sx={{marginLeft: theme.spacing(2)}}>
                    <Typography variant={"body1"}>{c.value}</Typography>
                </Grid>
            </Grid>
        ));
    };

    return (Object.keys(metadata).length > 0 && (
            <Paper>
                <Box
                    sx={{
                        padding: theme.spacing(4),
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CheckCircleIcon
                        color={"secondary"}
                        sx={{marginRight: theme.spacing(4), width: 40, height: 40}}
                    />
                    <Typography variant={"h5"}>
                        {t('dataTransferSuccessful', 'Informasjonsoverføringen var vellykket!')}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: theme.spacing(2),
                        paddingLeft: theme.spacing(2)
                    }}
                >
                    <Grid item md={4}>
                        {renderRows()}
                    </Grid>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        padding: theme.spacing(4),
                    }}
                >
                    <Button
                        color={"secondary"}
                        onClick={() => {
                            projectDispatch({type: CANCEL_CREATE_PROJECT});
                        }}
                    >
                        {t('btnCancel', 'Avbryt')}
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            projectDispatch({
                                type: ARCHEOLOGY_PROJECT_ADD_USERS,
                            });
                        }}
                        sx={{
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        {t('btnContinue', 'Fortsett')}
                    </Button>
                </Box>
            </Paper>)
    );
};
