import React, {useEffect, useRef, useState} from "react";
import {styled} from "@mui/material/styles";
import {
    EDITED_METADATA,
    ENABLE_NEXT_BUTTON,
    UPDATE_FORM_DATA,
    useDocumentDispatch,
    useDocumentState,
    useDocumentTranslation,
} from "../documents/documentContext";
import {useMarkFiles} from "../files/useMarkFiles";
import {usePostDocuments} from "../documents/usePostDocuments";
import {ModalProgressIndicator} from "../app/ModalProgressIndicator";
import {ModalUnsavedDialog} from "./ModalUnsavedDialog";
import {createMedia} from "./documentFactory";
import * as Yup from "yup";
import {DamsModal} from "../app/DamsModal";
import {MappingDialog} from "./mappingdialog/MappingDialog";
import {useMetadataMapping} from "./useMetadataMapping";
import {MetadataGrid} from "./MetadataGrid";
import {CircularProgress, useMediaQuery} from "@mui/material";
import {MetadataGridSmallScreen} from "./smallscreen/MetadataGridSmallScreen";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
    deleteMarkedClauses,
    deleteMarkedLicenses,
    mergeCopyrightDates,
    mergeCopyrightInfo,
    mergeLicenseInfo
} from "../app/metadataUtils";

import _ from 'lodash';
import {getFileExtensionFromFilename, getMimeTypeFromFilename} from "../damsFileObjectDefinitions";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import {appendCopyrights, appendLicenses} from "../form/formHelper";

const PREFIX = "MetaStep";

const classes = {
    rjsf: `${PREFIX}-rjsf`,
};

const Root = styled("div")(() => ({
    [`& .${classes.rjsf}`]: {
        width: "100%",
        height: "100%",
    },
}));

const documentTypeToConfig = (documentType) => {
    switch (documentType) {
        case "StillImage":
            return "image";
        case "Video":
            return "video";
        case "Audio":
            return "audio";
        case "Misc":
            return "document";
        default:
            throw new Error(`Unknown documentType ${documentType}`);
    }
};

export const MetaStep = () => {
    const t = useDocumentTranslation();
    const toolbarRef = useRef();
    const smallScreen = useMediaQuery("(max-width: 768px)");
    const docDispatch = useDocumentDispatch();

    const {formData, files, saved} = useDocumentState();

    const [
        checked,
        checkFile,
        checkAll,
        clearAll,
        getCheckedFilenames,
        numberOfChecked,
    ] = useMarkFiles(files);

    const [viewType, setViewType] = useState("gridlist");
    const [showMappingDialog, setShowMappingDialog] = useState(false);
    const [mappedFiles, setMappedFiles] = useState({});
    const [initialSave, setInitialSave] = useState(false);
    const [initialSaveComplete, setInitialSaveComplete] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [postDocumentsResponse, postDocuments, postDocumentsInChunks] = usePostDocuments(); // NOSONAR

    const [mappingConfig] = useMetadataMapping({
        collectionId: formData.collectionId,
    });

    const [documentsSaved, setDocumentsSaved] = useState(false);

    /**
     * Gets the document object matching the specified filename from the list of saved documents.
     * @param fileName
     * @returns {*}
     */
    const getSavedDocument = (fileName) => {
        return saved.find(
            (document) => document.content.mediae[0].reference.title === fileName
        );
    }

    /**
     * Sets the initial form values.
     */
    const getInitialValues = () => {
        let values = {
            title: "",
            description: "",
            customIdentifier: "",
            remarks: "",
            productionDate: "",
            producer: [],
            persons: [],
            places: [],
            subjects: [],
            licenses: [],
            licensesAssociated: [],
            copyrightType: '',
            copyrightTerms: '',
            copyrightTypeResponsible: [],
            copyrightInfo: [],
            copyrightTypeDateUntil: '',
            copyrightTypeOriginator: [],
            languages: [],
        };

        if (1 === numberOfChecked() && getSavedDocument(getCheckedFilenames()[0])) {

            clearCopyrightFields();
            clearLicenseFields();

            let obj = {};
            const document = getSavedDocument(getCheckedFilenames()[0]);
            appendLicenses(document, obj);
            appendCopyrights(document, obj);

            const {title, description = '', content} = document;
            const {
                customIdentifier = '', productionDate = '', producer = [], persons = [], places = [],
                subjects = [], licenses = [], licensesAssociated = [], copyrightInfo = [], copyrightType = [],
                copyrightTypeDateUntil = '', copyrightTypeOriginator = [], copyrightTypeResponsible = [],
                copyrightTerms = '', languages = [], remarks = ''
            } = content;

            // NOTE: JavaScript deconstructs with default values when the value is undefinede.
            // null is still interpreted as a value, therefore some of the values are altered
            // to a default value in the values statement below.
            values = {
                ...obj,
                ...{
                    title: title,
                    description: description,
                    customIdentifier: customIdentifier,
                    productionDate: productionDate,
                    producer: producer,
                    persons: persons,
                    places: places,
                    subjects: subjects || [],
                    licenses: licenses,
                    licensesAssociated: licensesAssociated,
                    copyrightInfo: copyrightInfo,
                    copyrightType: copyrightType,
                    copyrightTypeDateUntil: copyrightTypeDateUntil,
                    copyrightTypeOriginator: copyrightTypeOriginator,
                    copyrightTypeResponsible: copyrightTypeResponsible,
                    copyrightTerms: copyrightTerms,
                    languages: languages || [],
                    remarks: remarks,
                }
            };
        } else if (
            1 === numberOfChecked() &&
            Object.keys(mappedFiles).includes(getCheckedFilenames()[0])
        ) {

            clearCopyrightFields();
            clearLicenseFields();

            const mappedMeta = mappedFiles[getCheckedFilenames()[0]];
            values = {
                title: "",
                description: "",
                customIdentifier: "",
                remarks: "",
                productionDate: "",
                producer: [],
                persons: [],
                places: [],
                subjects: [],
                licenses: [],
                licensesAssociated: [],
                copyrightType: '',
                copyrightInfo: [],
                copyrightTypeDateUntil: '',
                copyrightTypeOriginator: [],
                copyrightTerms: '',
                copyrightTypeResponsible: [],
                languages: [],
                ...mappedMeta,
            };
        }
        docDispatch({type: UPDATE_FORM_DATA, formData: {...formData, ...values}});
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t("titleRequired", "Tittel er påkrevd")),
    });

    /**
     * Method used to save the files checked for editing.
     * @param values
     * @param actions   object  Formik actions object.
     */
    const saveDocuments = (values, actions) => {
        let documents = files
            .filter((file) => checked[file.title] === true);

        documents = documents.map((file) => {
            deleteMarkedClauses(formData, values);
            deleteMarkedLicenses(formData, values);

            mergeLicenseInfo(values);
            mergeCopyrightInfo(values);
            mergeCopyrightDates(values);

            const savedDocument = getSavedDocument(file.title);

            return {
                ...formData,
                title: values.title,
                description: values.description,
                uniqueId: savedDocument
                    ? savedDocument.uniqueId
                    : null,
                content: {
                    customIdentifier: values.customIdentifier,
                    productionDate: values.productionDate,
                    producer: values.producer,
                    persons: values.persons,
                    places: values.places,
                    subjects: values.subjects,
                    licenses: values.licenses,
                    licensesAssociated: values.licensesAssociated,
                    copyrightInfo: values.copyrightInfo,
                    copyrightType: values.copyrightType,
                    copyrightTypeDateUntil: values.copyrightTypeDateUntil,
                    copyrightTypeOriginator: values.copyrightTypeOriginator,
                    copyrightTypeResponsible: values.copyrightTypeResponsible,
                    copyrightTerms: values.copyrightTerms,
                    relations: values.relations,
                    languages: values.languages,
                    remarks: values.remarks,
                    mediae: savedDocument
                        ? savedDocument.content.mediae
                        : [
                            createMedia({
                                title: file.title,
                                dmsId: file.dmsId,
                                filesize: file.filesize,
                                fileExtension: getFileExtensionFromFilename(file.title),
                                mimeType: file.mimeType,
                            }),
                        ],
                },
            }
        });

        postDocumentsInChunks(documents).then(() => {
            setDocumentsSaved(true);
            actions.setSubmitting(false);
        });
    };

    useEffect(() => {
        if (documentsSaved) {
            docDispatch({type: EDITED_METADATA, files: saved});
            setDocumentsSaved(false);
        }
    }, [saved, documentsSaved]);


    const createAutoSaveObject = (values, fileObj) => {
        const fileTitle = fileObj.name;
        const fileSize = fileObj.size;
        return {
            ...formData,
            title: values.title ? values.title : fileTitle,
            description: values.description,
            uniqueId: getSavedDocument(fileTitle)
                ? getSavedDocument(fileTitle).uniqueId
                : null,
            content: {
                customIdentifier: values.customIdentifier,
                productionDate: values.productionDate,
                producer: values.producer,
                persons: values.persons,
                places: values.places,
                subjects: values.subjects ? values.subjects : [],
                licenses: values.licenses,
                licensesAssociated: values.licensesAssociated,
                languages: values.languages ? values.languages : [],
                copyrightInfo: values.copyrightInfo,
                copyrightType: values.copyrightType,
                copyrightTypeDateUntil: values.copyrightTypeDateUntil,
                copyrightTypeOriginator: values.copyrightTypeOriginator,
                copyrightTypeResponsible: values.copyrightTypeResponsible,
                copyrightTerms: values.copyrightTerms,
                remarks: values.remarks || '',
                mediae: [
                    createMedia({
                        title: fileTitle,
                        dmsId: values.dmsId || null,
                        filesize: fileSize,
                        fileExtension: getFileExtensionFromFilename(fileTitle),
                        mimeType: getMimeTypeFromFilename(fileTitle)
                    }),
                ],
            },
        };
    }

    /**
     * Method used to "autosave" the files after uploading.
     */
    const autoSaveMappedFiles = (dispatchComplete) => {
        // Initially mappedFiles is an object where each key represents the name of the file.
        // After merging with downloaded metadata, the mappedFields object is an Array, where each file is represented.
        let documents;
        if (Array.isArray(mappedFiles)) {
            documents = mappedFiles.map(mf => {
                return createAutoSaveObject(mf, mf.file);
            });
        } else {
            const filenames = Object.keys(mappedFiles);
            documents = filenames.map((fileName) => {
                const values = mappedFiles[fileName];
                const file = files.find((f) => f.title === fileName);
                return createAutoSaveObject(values, file.file);
            });
        }

        if (documents.length === 0) {
            return;
        }

        postDocumentsInChunks(documents).then(() => {
            setInitialSaveComplete(true);
            clearAll();                         // Clear file-selection
            if (dispatchComplete) {
                docDispatch({type: ENABLE_NEXT_BUTTON});
            }
        });
    };

    /**
     * Triggered when the user decides to close the mapping dialog.
     */
    const mappingDialogCloseHandler = () => {
        setShowMappingDialog(false);
        autoSaveMappedFiles(true);
    };

    /**
     * "Resets" the values of the copyright clause fields, when the user cancels the edit operation.
     */
    const clearCopyrightFields = () => {

        // Deleting "additional" fields, that are not part of the original form.
        const keys = Object.keys(formData);
        keys.forEach(k => {
            if (k.indexOf('copyrightClause') > -1
                || k.indexOf('copyrightFromDate') > -1
                || k.indexOf('copyrightToDate') > -1
                || k.indexOf('copyrightResponsible') > -1
            ) {
                delete formData[k];
            }
        });

        delete formData['copyrightInfo'];

        // Resetting the values of the rest of the fields.
        const cleanFormData = {
            ...formData,
            ...{
                copyrightInfo: [],
                copyrightTerms: '',
                copyrightTypeDateUntil: '',
                copyrightTypeOriginator: '',
                copyrightTypeResponsible: [],
            }
        };
        docDispatch({type: UPDATE_FORM_DATA, formData: cleanFormData});
    };

    /**
     * "Resets" the values of the license fields, when the user cancels the edit operation.
     */
    const clearLicenseFields = () => {
        const keys = Object.keys(formData);
        keys.forEach(k => {
            if (k.indexOf('license') > -1) {
                delete formData[k];
            }
        });
        docDispatch({type: UPDATE_FORM_DATA, formData: formData});
    };

    const isValidConfig = config => {
        let valid = true;
        if (Array.isArray(config)) {
            // Verify if one or more entries is just a string, if that's the case, is in illegal config. and
            // we will skip it.
            for (let i = 0, max = config.length; i < max; i++) {
                const isString = Object.prototype.toString.call(config[i]) === "[object String]";
                if (isString) {
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    };


    /**
     * Hook used to display the edit dialog, if required.
     * NOTE: key values must be verified!
     */
    useDeepCompareEffect(() => {
        if (!initialSaveComplete) {
            return;
        }

        const mappingConfigForDocumentType = mappingConfig[documentTypeToConfig(formData.documentType)];
        if (!mappingConfigForDocumentType) {
            return;
        }

        if (!isValidConfig(mappingConfigForDocumentType)) {
            return;
        }

        if (mappingConfigForDocumentType) {
            let state = false;
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(mappingConfigForDocumentType)) {  // NOSONAR
                // If any of the config. keys has a value, set state to true, in order to display the metadata dialog.
                if (value.length > 0) {
                    state = true;
                    break;
                }
            }
            if (state) {
                setShowMappingDialog(state);
            }
        }
    }, [initialSaveComplete, formData.documentType, setShowMappingDialog]);


    /**
     * Hook used to map the title of the files upon loading.
     */
    useEffect(() => {
        const mf = {};
        files.forEach((file) => {
            mf[file.title] = {title: file.title, dmsId: file.dmsId};
        });
        setMappedFiles(mf);
    }, []);

    /**
     * Hook used to automatically save the mapped files, with their title.
     */
    useEffect(() => {
        if (!initialSave && mappedFiles && Object.keys(mappedFiles).length > 0) {
            autoSaveMappedFiles();
            setInitialSave(true);
        }
    }, [initialSave, mappedFiles])


    /**
     * Hook used to get the initial values.
     */
    useEffect(() => {
        getInitialValues();
    }, [saved, checked]);


    /**
     * Method used to update the mappedFiles array after metadata extraction is run.
     * @param data Array    An array of objects, containing the mapped metadata for each file.
     */
    const updateMappedFiles = (data) => {
        const merged = _.merge(data, mappedFiles);
        setMappedFiles(merged);
    };

    return (
        <Root
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                maxHeight: '100%'
            }}>
            {!initialSaveComplete &&
                <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                          open={!initialSaveComplete}>
                    <Box sx={{
                        padding: 0,
                        margin: 0,
                        position: "absolute",
                        outline: "none",
                        left: "50%",
                        top: "40%",
                        transform: "translate(-50%, 40%)",
                        zIndex: 999
                    }}>
                        <CircularProgress color={"primary"} size={120} disableShrink={true}/>
                    </Box>
                </Backdrop>
            }

            <DamsModal
                open={showMappingDialog}
                onHide={() => setShowMappingDialog(false)}
            >
                <MappingDialog
                    files={files}
                    mapping={mappingConfig[documentTypeToConfig(formData.documentType)]}
                    collectionId={formData.collectionId}
                    onHide={mappingDialogCloseHandler}
                    onFinished={updateMappedFiles}
                />
            </DamsModal>
            {smallScreen && (
                <MetadataGridSmallScreen
                    clearAllCallback={() => {
                        clearAll();
                        clearCopyrightFields();
                        clearLicenseFields();
                    }}
                    checkAllCallback={checkAll}
                    checked={checked}
                    checkFileCallback={checkFile}
                    saveDocumentsCallback={saveDocuments}
                    validationSchema={validationSchema}
                    numberOfCheckedCallback={numberOfChecked}
                />
            )}

            {!smallScreen && (
                <MetadataGrid
                    toolbarRef={toolbarRef}
                    viewType={viewType}
                    setViewTypeCallback={setViewType}
                    clearAllCallback={() => {
                        clearAll();
                        clearCopyrightFields();
                        clearLicenseFields();
                    }}
                    checkAllCallback={checkAll}
                    checked={checked}
                    checkFileCallback={checkFile}
                    saveDocumentsCallback={saveDocuments}
                    validationSchema={validationSchema}
                    numberOfCheckedCallback={numberOfChecked}
                />
            )}

            <ModalProgressIndicator
                pending={postDocumentsResponse.pendingDocuments}
                total={numberOfChecked()}
            />
            <ModalUnsavedDialog/>
        </Root>
    );
};
