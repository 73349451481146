import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";

/**
 * Async method used to fetch the list of folders that represents a DAMS-project.
 * @param query
 * @param sort
 * @param fl
 * @param start
 * @param rows
 * @param fq
 * @param status
 * @param museums
 * @param expand
 * @param onlyMine
 * @param onlyOthers
 * @param folderType
 * @returns {Promise<*>}
 */
export const projectsSearch = async ({
                                         query,
                                         sort,
                                         fl,
                                         start = 0,
                                         rows = 100,
                                         fq = {},
                                         status = '(-trash)',
                                         museums = [],
                                         expand = false,
                                         onlyMine = false,
                                         onlyOthers = false,
                                         folderType = "",
                                     }) => {

    const params = {
        q: query,
        sort: sort,
        fl: fl,
        expand: expand,
        start: start,
        rows: rows,
        documentType: `(Folder)`,
        museums: museums,
        onlyMine: onlyMine,
        onlyOthers: onlyOthers,
        status: status,
    };

    if (folderType !== "") {
        params.folderType = folderType;
    }

    const searchParams = new URLSearchParams(decamelizeKeysDeep(params));

    if (Object.keys(fq).length) {
        const fqGrouped = fq.reduce((acc, object) => {
            if (acc[object.key]) {
                acc[object.key].push(object.value);
            } else {
                acc[object.key] = [object.value];
            }
            return acc;
        }, {});

        const fqParam = Object.keys(fqGrouped)
            .map(
                (key) =>
                    `${key}:(${fqGrouped[key].map(encodeURIComponent).join(" OR ")})`
            )
            .join(",");

        searchParams.append("fq", fqParam);
    }

    return await damsFetch(`/folders/search?${searchParams.toString()}`);
};