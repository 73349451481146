import React, {useRef} from "react";
import {Fade, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {PROJECT_SHOW_DETAILS_OVERLAY, useProjectViewDispatch, useProjectViewState,} from "./projectViewContext";
import {ArcheologyProjectViewDetails} from "./ArcheologyProjectViewDetails";
import {ArcheologyProjectViewDetailsSmallScreen} from "./smallscreen/ArcheologyProjectViewDetailsSmallScreen";
import {ArcheologyProjectAdmin} from "../ArcheologyProjectAdmin";
import {useNavigate} from "react-router-dom";
import {ArcheologyProjectAdminSmallScreen} from "./smallscreen/ArcheologyProjectAdminSmallScreen";
import {ButtonProjectActiveStateAdmin} from "../../ButtonProjectActiveStateAdmin";
import {ButtonProjectMembersAdmin} from "../../ButtonProjectMembersAdmin";
import {ButtonInfo} from "../ButtonInfo";
import {useProjectTranslation} from "../../projectContext";
import {ArrowBack} from "@mui/icons-material";

export const ArcheologyProjectViewHeader = () => {
    const {project, showDetails, showAdmin} = useProjectViewState();
    const projectViewDispatch = useProjectViewDispatch();
    const headerRef = useRef();
    const navigate = useNavigate();
    const t = useProjectTranslation();

    const theme = useTheme();

    const smallScreen = useMediaQuery("(max-width: 768px)");
    const smUp = useMediaQuery(theme.breakpoints.up('sm')); //600px up

    const handleInfoClick = () => {
        projectViewDispatch({
            type: PROJECT_SHOW_DETAILS_OVERLAY,
            project: project,
        });
    };

    const showOverlay = (bgColor, overlayContent) => {
        const headerHeight = headerRef?.current?.getBoundingClientRect().height;
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: headerHeight + "px",
                    left: 0,
                    width: "100%",
                    height: "500px",
                    backgroundColor: bgColor,
                    borderTop: "solid 1px #6f6f6f",
                    zIndex: 999,
                }}
            >
                {overlayContent}
            </Box>
        );
    };

    // Show project details
    const getProjectDetails = () => {
        return showOverlay(
            "#757575",
            <ArcheologyProjectViewDetails model={project}/>
        );
    };

    // Admin users
    const getProjectAdmin = () => {
        return showOverlay("white", <ArcheologyProjectAdmin/>);
    };

    return (
        <Box
            ref={headerRef}
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                color: "white",
                backgroundColor: "#757575",
                paddingBlock: 1,
                paddingInline: {
                    xs: 1,
                    sm: 2,
                },
                marginInline: {
                    xs: 0,
                    md: 3,
                },
                borderRadius: {
                    xs: 0,
                    md: 1,
                },
            }}
        >
            <Tooltip title={t('tooltipTitle', 'Tilbake til prosjekter')}>
                <Avatar sx={{color: "white"}}>
                    <IconButton
                        onClick={() => navigate("/all-projects/")}
                        aria-label={t('lblToProjects', 'Til prosjekter')}
                    >
                        <ArrowBack/>
                    </IconButton>
                </Avatar>
            </Tooltip>

            {/* Project title */}
            <Typography
                variant={"h6"}
                fontWeight={"medium"}
                marginLeft={smallScreen ? 1 : 2}
                noWrap
                sx={{
                    flexShrink: 1,
                    flexBasis: 'auto',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                {smUp && t('headingProject', 'Prosjekt') + ': '}
                {project.title}
            </Typography>

            {/* Adm. icons to the right */}
            <Box
                sx={{
                    marginLeft: 'auto',
                    display: 'flex',
                }}
            >
                <ButtonProjectActiveStateAdmin/>
                <ButtonProjectMembersAdmin/>
                <ButtonInfo clickCallback={handleInfoClick}/>
            </Box>
            {/* Show project details or admin */}
            {(project && smallScreen) && (
                <>
                    <ArcheologyProjectViewDetailsSmallScreen
                        model={project}
                        show={showDetails}
                    />
                    <ArcheologyProjectAdminSmallScreen model={project} show={showAdmin}/>
                </>
            )}
            {!smallScreen && (
                <>
                    <Fade in={showDetails}>{getProjectDetails()}</Fade>
                    <Fade in={showAdmin}>{getProjectAdmin()}</Fade>
                </>
            )}
        </Box>
    );
};
