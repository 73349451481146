import React from "react";
import {useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {TOGGLE_COLUMN, useResultTableDispatch, useResultTableState} from "./ResultTableContext";

export const ResultTableColumnSelector = ({t}) => {
    const theme = useTheme();
    const {availableColumns} = useResultTableState();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const resultTableDispatch = useResultTableDispatch();

    return (
        <>
            <Button
                aria-describedby="showColumns"
                onClick={handleClick}
                color={"secondary"}
                endIcon={<ViewColumnIcon/>}
            >
                {t("showColumns", "Vis kolonner")}:
            </Button>
            <Popover
                id={"showColumns"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >

                <FormGroup style={{padding: theme.spacing(2)}}>
                    {availableColumns.map(a => {
                        const label = a.getLabel(t);
                        const id = a.id;
                        const checked = a.checked;
                        return (
                            <FormControlLabel
                                key={label}
                                control={<Checkbox
                                    checked={checked}
                                    name={label}
                                    onClick={() => {
                                        resultTableDispatch({type: TOGGLE_COLUMN, id: id});
                                    }}/>}
                                label={label}
                            />);
                    })}
                </FormGroup>
            </Popover>
        </>
    );
};
