import {transform} from "lodash";
import {damsFetch} from "../../app/damsFetch";

export const lookupMapping = (fileMeta, lookups) => {
    const fileMetaLowerCaseKeys = transform(fileMeta, (result, value, key) => {
        result[key.toLowerCase()] = value;
    });
    if (!Array.isArray(lookups)) {
        lookups = [lookups];
    }
    const lookupsLowerCase = lookups.map((lookup) => lookup.toLowerCase());
    const lookupFound = lookupsLowerCase.find((lookup) => fileMetaLowerCaseKeys[lookup]);
    return lookupFound ? fileMetaLowerCaseKeys[lookupFound] : null;
};

export const parseExifDate = (value) => {
    // EXIF DATE: YYYY:MM:DD
    const exifDateMask = /(\d{4}:\d{2}:\d{2})/gi;
    const m = value.match(exifDateMask);

    let d = null;

    if (m && m.length > 0) {
        const parts = m[0].split(":");
        d = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], 0, 0, 0));
    }

    return {
        isValid: d !== null,
        value: d
    };
};

export const parseDate = (norwegianUser, value) => {
    let d = null;

    const dateformatRegEx = norwegianUser
        ? /^((\d{2}.)(\d{2}.))?(\d{4})$/gi // dd.mm.yyyy
        : /^(\d{4})((-\d{2})(-\d{2}))?$/gi; // yyyy-mm-dd

    const m = value.match(dateformatRegEx);

    if (m && m.length > 0) {
        let parts;
        if (norwegianUser) {
            parts = value.split(".");
            d = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 0, 0, 0));
        } else {
            parts = value.split("-");
            d = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], 0, 0, 0));
        }
    }

    return {
        isValid: d !== null,
        value: d
    };
};

export const getProductionDate = (norwegianUser, value) => {
    if (!value) {
        return {
            isValid: false,
            value: null
        };
    }
    let prodDate = parseExifDate(value);
    if (!prodDate.isValid) {
        prodDate = parseDate(norwegianUser, value);
    }
    return prodDate;
};

export const findValue = (norwegianUser, fileMeta, mappingKey, mappingValue) => {
    const value = lookupMapping(fileMeta, mappingValue);
    const prodDate = getProductionDate(norwegianUser, value);
    switch (mappingKey) {
        case "title":
            return {title: value || ''};
        case "description":
            return {description: value || ''};
        case "customIdentifier":
            return {customIdentifier: value || ''};
        case "productionDate":
            return {productionDate: prodDate.isValid ? prodDate.value : ''};
        case "producer":
            return {};
        default:
            return {[mappingKey]: value};
    }
};

export const keyToValue = (norwegianUser, mapping, meta) => {
    return Object.keys(mapping)
        .reduce(
            (acc, key) => ({
                ...acc,
                ...findValue(norwegianUser, meta, key, mapping[key]),
            }),
            {}
        );
};

/**
 * Resolves, when the objects matching the specified DMS IDs has achieved the state "ok" or "done",
 * and are thereby ready for metadata extraction.
 * @param collectionId
 * @param dmsIds
 * @returns {Promise<unknown>}
 */
export const filesReadyForMetadataExtraction = async (collectionId, dmsIds) => {
    let intervalId = null;
    return new Promise((resolve) => {
        intervalId = setInterval(async () => {
            let state = await _metadataReady(collectionId, dmsIds);
            if (state) {
                clearInterval(intervalId);
                intervalId = null;
                resolve(state);
            }
        }, 2000)
    });
};

/**
 * Wrapper function, used to determine whether all files have been converted/processed.
 * @param collectionId
 * @param dmsIds
 * @returns {Promise<boolean>}
 * @private
 */
const _metadataReady = async (collectionId, dmsIds) => {
    const res = await _getObjectStatusForMultiple(collectionId, dmsIds);
    const failed = [...res]
        .filter(f => f.status === 'failed' || f.status === 'fail' || f.status === 'error').length;
    const success = [...res]
        .filter(f => f.status === 'ok' || f.status === 'done').length;
    return failed + success === dmsIds.length;
};

/**
 * Gets the current conversion status for the specified DMS IDs.
 * @param collectionId
 * @param dmsIds
 * @returns {Promise<*>}
 * @private
 */
const _getObjectStatusForMultiple = async (collectionId, dmsIds) => {
    return await damsFetch('/dms/object-status/',
        {
            method: 'POST',
            body: JSON.stringify({
                collectionId: collectionId,
                mediaId: dmsIds
            })
        });
};