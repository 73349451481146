import {CreateFolderCard} from "./CreateFolderCard";
import Box from "@mui/material/Box";
import {alpha} from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import {InputBase, Stack, useMediaQuery, useTheme} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import React, {useState} from "react";
import {SET_QUERY, SET_SEARCHING, useFoldersDispatch} from "../../folder/FoldersContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useNewFilesTranslation} from "../../files/NewFilesTranslationContext";
import {useAuthsState} from "../../auths/authsContext";
import Typography from "@mui/material/Typography";

export const AvailableFoldersToolbar = () => {
    const t = useNewFilesTranslation();
    const theme = useTheme();
    const foldersDispatch = useFoldersDispatch();

    const {userData} = useAuthsState();

    const [inputValue, setInputValue] = useState("");
    const [showCard, setShowCard] = useState(false);

    const smallScreen = useMediaQuery("(max-width: 1365px)");

    const searchOnEnter = (event) => {
        if ("Enter" === event.key) {
            let q = "*" + inputValue + "*";
            if (!inputValue || inputValue === "") {
                q = "*";
            }
            foldersDispatch({
                type: SET_QUERY,
                query: q,
                folderContext: true,
                onlyMine: true,
            });
            foldersDispatch({type: SET_SEARCHING});
        }
    };

    useDeepCompareEffect(() => {
        if (!userData['contributor'] && userData['appAccess']?.museums?.length > 0) {
            setShowCard(true);
        }
    }, [userData]);

    const getSearchField = () => {
        return <Box
            sx={{
                position: "relative",
                borderRadius: theme.shape.borderRadius,
                backgroundColor: alpha(theme.palette.common.black, 0.05),
                "&:hover": {
                    backgroundColor: alpha(theme.palette.common.black, 0.1),
                },
                marginRight: theme.spacing(2),
                marginLeft: 0,
                width: "100%",
                [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(3),
                    width: "auto",
                },
            }}
        >
            <Box
                sx={{
                    padding: theme.spacing(0, 2),
                    height: "100%",
                    position: "absolute",
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <SearchIcon/>
            </Box>
            <InputBase
                sx={{
                    color: "inherit",
                    "& .MuiInputBase-input": {
                        padding: theme.spacing(1, 1, 1, 0),
                        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                        transition: theme.transitions.create("width"),
                        width: "100%",
                        [theme.breakpoints.up("md")]: {
                            width: "20ch",
                        },
                    },
                }}
                placeholder={t('placeholderSearch', 'Søk i mapper') + '...'}
                inputProps={{"aria-label": "søk"}}
                onKeyDown={searchOnEnter}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
            />
        </Box>;
    }

    const getHelpText = () => {
        return <Typography sx={{marginTop: '8px'}}>
            {t('displayingOnlyTenFolders', 'Viser kun 10 mapper, sortert alfabetisk. Forsøk "Søk", eller opprett ny(e).')}
        </Typography>;
    }

    return <>
        {!smallScreen && <Toolbar>
            <Stack direction={"column"} sx={{width: '100%', marginTop: '16px'}}>
                <Stack direction={"row"}
                       alignItems={"center"}
                       justifyContent={'space-between'}
                       sx={{width: '100%'}}>
                    {showCard && <CreateFolderCard/>}
                    {getSearchField()}
                </Stack>
                {getHelpText()}
            </Stack>
        </Toolbar>}
        {smallScreen && <Toolbar>
            <Stack direction={"column"}>
                {showCard && <CreateFolderCard/>}
                <Box mt={'8px'}>
                    {getSearchField()}
                    {getHelpText()}
                </Box>
            </Stack>
        </Toolbar>}
    </>;
};