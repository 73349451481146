import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useMuseumNameFromCollectionId} from "../../../museum/useMuseumNameFromCollectionId";
import {makeStyles} from "@mui/styles";
import {useProjectTranslation} from "../../projectContext";
import {useProjectViewState} from "../../archeology/projectview/projectViewContext";
import {getBrowserLocale} from "../../../utility";
import Chip from "@mui/material/Chip";
import {getHrDateTime} from "../../projectUtility";

const useStyles = makeStyles((theme) => ({
    boxProjectIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 56,
        height: 76,
        border: "solid 1px #ddd",
        color: "white",
        backgroundColor: "white",
        marginBottom: theme.spacing(2),
    },
    gridContentColumnLeft: {
        width: "20%",
        paddingLeft: theme.spacing(1),
        color: "white",
    },
    gridContentColumnCenter: {
        width: "50%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: "white",
        borderLeft: "solid 1px white",
        borderRight: "solid 1px white",
    },
    gridContentColumnRight: {
        width: "25%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        color: "white",
    },
    gridFactColumn: {
        width: "50%",
        marginBottom: theme.spacing(1),
    },
    buttonToolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
}));

export const GeneralProjectViewDetails = ({
                                              model,
                                              onHide,
                                              handleOpenProject,
                                          }) => {
    const language = getBrowserLocale(true);
    const tp = useProjectTranslation();
    const classes = useStyles();
    const [getMuseumName] = useMuseumNameFromCollectionId();
    const museumName = getMuseumName(model.collectionId);

    const {facets} = useProjectViewState();

    const {content, createdAt} = model;
    const {generalprojectref} = content;
    const {
        id,
        description,
        type,
        startedAt,
        endedAt,
        responsible,
        contributors,
        relatedProjects,
        remarks,
        extReferences
    } = generalprojectref;

    const getProjectId = () => {
        return id;
    }

    const getProjectType = () => {
        return type?.name;
    };

    const getProjectDescription = () => {
        return description;
    };

    const getHrProjectPeriod = () => {
        const started = startedAt !== null ? getHrDateTime(startedAt, language).split(' ')[0] : '';
        const ended = endedAt !== null ? getHrDateTime(endedAt, language).split(' ')[0] : '';
        return `${started} - ${ended}`;
    };

    const getExtReferences = () => {
        return extReferences;
    };

    const getRemarks = () => {
        return remarks;
    }

    const getResponsible = () => {
        return <Box>{responsible.map(r => (
            <Chip color="secondary" key={`r${r.id}`} label={r.name} sx={{margin: '4px'}}/>))}
        </Box>;
    };

    const getContributors = () => {
        return <Box>{contributors.map(c => (
            <Chip color="secondary" key={`c${c.id}`} label={c.name} sx={{margin: '4px'}}/>))}
        </Box>;
    };

    const getRelatedProjects = () => {
        if (!relatedProjects || relatedProjects?.length === 0) {
            return <></>;
        } else {
            return <Box mt={2}>
                <Typography variant={"caption"}>{tp('lblRelatedProjects', 'Relaterte prosjekter')}</Typography>
                <Box>{relatedProjects?.map(r => {
                    return <Typography key={`related${r.id}`} variant={"caption"}>{r.title}</Typography>
                })}</Box>
            </Box>;
        }
    };

    return (museumName && model && (
        <Paper
            sx={{
                outline: "none !important",
                width: "100%",
                backgroundColor: "#55524F",
                paddingBottom: 1,
            }}
        >

            {/* Main content */}
            <Box
                sx={{
                    paddingLeft: 4,
                    paddingRight: 4,
                }}
            >
                <Grid
                    container
                    direction={"row"}
                    columnSpacing={4}
                    marginTop={0}
                    sx={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 4,
                        paddingRight: 4,
                    }}
                >
                    {/* 1st column */}
                    <Grid className={classes.gridContentColumnLeft} item>
                        <Box>
                            <Typography variant={"caption"}>
                                {tp("lblProjectId", "Prosjekt ID")}: {getProjectId()}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant={"caption"}>
                                {tp("lblMuseum", "Museum")}: {museumName}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant={"caption"}>
                                {tp("lblCreated", "Opprettet")}: {createdAt && getHrDateTime(createdAt, language)}
                            </Typography>
                        </Box>

                        {getRelatedProjects()}
                    </Grid>
                    {/* 2nd column */}
                    <Grid className={classes.gridContentColumnCenter} item>
                        <Grid container direction={"row"}>
                            <Grid className={classes.gridFactColumn}>
                                <Typography variant={"caption"}>
                                    {tp("lblPeriod", "Periode")}: {getHrProjectPeriod()}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction={"row"}>
                            <Grid className={classes.gridFactColumn}>
                                <Typography variant={"caption"}>
                                    {tp("lblProjectType", "Prosjekttype")}: {getProjectType()}
                                </Typography>
                            </Grid>
                            <Grid className={classes.gridFactColumn}>
                                <Typography variant={"caption"}>
                                    {tp("lblExternalReference")}: {getExtReferences()}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant={"caption"} marginTop={1} marginBottom={1}>
                            {tp('projectViewResponsible', 'Ansvarlige')}
                        </Typography>
                        <Grid container direction={"row"}>
                            <Grid className={classes.gridFactColumn} sx={{width: '100% !important'}}>
                                <Typography variant={"caption"}>
                                    {getResponsible()}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant={"caption"}>
                            {tp('projectViewParticipants', 'Deltagere')}
                        </Typography>
                        <Grid container direction={"row"}>
                            <Grid className={classes.gridFactColumn} sx={{width: '100% !important'}}>
                                {getContributors()}
                            </Grid>
                        </Grid>

                        <Grid container direction={"column"}>
                            <Grid item>
                                <Typography variant={"caption"} marginTop={1}>
                                    {tp('lblDescription', 'Beskrivelse')}
                                </Typography>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {getProjectDescription()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item marginTop={1}>
                                <Typography variant={"caption"}>
                                    {tp('lblRemarks', 'Kommentarer')}
                                </Typography>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {getRemarks()}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* 3rd column */}
                    <Grid className={classes.gridContentColumnRight} item>
                        <Grid container direction={"row"}>
                            <Grid item sx={{width: "200px"}}>
                                <Typography variant={"caption"}>
                                    {tp('lblImage', 'Bilde')}: {facets.documentTypes?.StillImage?.count || 0}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>
                                    {tp('lblModel', 'Modell')}: {facets.documentTypes?.Modell?.count || 0}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"}>
                            <Grid item sx={{width: "200px"}}>
                                <Typography variant={"caption"}>
                                    {tp('lblDocument', 'Dokument')}: {facets.documentTypes?.Dokument?.count || 0}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>
                                    {tp('lblGeodata', 'Geodata')}: {facets.documentTypes?.Geodata?.count || 0}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"}>
                            <Grid item sx={{width: "200px"}}>
                                <Typography variant={"caption"}>
                                    {tp('lblAudio', 'Lyd')}: {facets.documentTypes?.Audio?.count || 0}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>
                                    {tp('lblTable', 'Tabell')}: {facets.documentTypes?.Tabell?.count || 0}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"}>
                            <Grid item sx={{width: "200px"}}>
                                <Typography variant={"caption"}>
                                    {tp('lblVideo', 'Film')}: {facets.documentTypes?.Video?.count || 0}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.gridFactColumn}/>
                        </Grid>
                    </Grid>
                </Grid>
                {onHide && handleOpenProject && (
                    <Box className={classes.buttonToolbar}>
                        <Button color={"secondary"} variant={"text"} onClick={onHide}>
                            {tp('btnClose', 'Lukk')}
                        </Button>
                        <Button
                            onClick={handleOpenProject}
                            color={"secondary"}
                            variant={"contained"}
                            sx={{marginLeft: 2}}
                        >
                            {tp('btnOpenProject', 'Åpne prosjekt')}
                        </Button>
                    </Box>
                )}
            </Box>
        </Paper>
    ));
};
