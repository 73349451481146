import React, {useState} from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useFolderTranslation} from "../../folder/MyFoldersContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {CreateFolderModal} from "../../folder/CreateFolderModal";

export const CreateFolderCard = () => {
    const t = useFolderTranslation();
    const [showCreateFolder, setShowCreateFolder] = useState(false);

    return (<>
            <LoadingButton
                startIcon={<AddCircleIcon/>}
                variant="contained"
                color={"secondary"}
                onClick={() => {
                    setShowCreateFolder(true)
                }}
                size={"medium"}
                sx={{marginRight: '8px'}}
            >
                {t("createFolder", "Opprett mappe")}
            </LoadingButton>
            <CreateFolderModal
                show={showCreateFolder}
                onHide={() => setShowCreateFolder(false)}
            />
        </>
    );
};
