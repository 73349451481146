import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { SearchSettings } from "./SearchSettings";
import { FolderSearchSettings } from "./FolderSearchSettings";
import { useParams } from "react-router-dom";
import { decamelize } from "../app/decamelize";
import { camelCase } from "lodash";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import Box from "@mui/material/Box";

export const SearchResultsOrderByMenu = ({ sort, t, selectHandler }) => {
    const params = useParams();
    const searchSettings = SearchSettings('dams.searchResults.config');
    const folderSearchSettings = FolderSearchSettings('dams.folderSearchResults.config');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [order, setOrder] = useState("asc");

    const folderId = params.folderId;

    const viewType = !folderId
        ? searchSettings.getDisplayType()
        : folderSearchSettings.getDisplayType();

    const showOrderMenu = viewType.indexOf("table") === -1;

    const options = [
        {
            key: 1,
            value: "createdAt desc",
            displayValue: t("created", "Opprettet"),
        },
        {
            key: 2,
            value: "title asc",
            displayValue: t("alphabetic", "Alfabetisk"),
        },
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOptionClick = (value) => {
        handleClose();

        const parts = value.split(" ");
        const prop = parts[0];

        const direction = order === "asc" ? "desc" : "asc";
        setOrder(direction);

        const sortValue = decamelize(prop) + " " + direction;

        !folderId
            ? searchSettings.setSortColumnAndOrder(prop, direction)
            : folderSearchSettings.setSortColumnAndOrder(prop, direction);

        selectHandler(sortValue);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Renders the currently selected value.
     * @returns {*}
     */
    const findOption = () => {
        const option = options.find(opt => {
            const sortCamelCaseLower = camelCase(sort.substring(0, sort.indexOf(' '))).toLowerCase()
            return opt.value.toLowerCase().indexOf(sortCamelCaseLower) > -1;
        });

        if (option) {
            return option.displayValue;
        } else {
            return t("sortedBySomethingElse", "Annet");
        }
    };

    /**
     * Renders the direction arrow next to the button.
     * @returns {JSX.Element}
     */
    const getSortDirection = () => {
        const direction = sort.substring(sort.indexOf(' ') + 1);
        return direction === 'asc' ? <ArrowDropDown /> : <ArrowDropUp />;
    };

    if (!showOrderMenu) {
        return <></>;
    } else {
        return (
            <>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Typography>{t("sort", "Sorter")}:</Typography>
                    <Button
                        aria-controls="order"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color={"secondary"}
                        endIcon={getSortDirection()}
                    >
                        {findOption()}
                    </Button>
                </Box>

                {showOrderMenu && (
                    <Menu
                        id="order"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {options.map((option) => (
                            <MenuItem
                                key={option.key}
                                value={option.key}
                                onClick={() => handleOptionClick(option.value)}
                            // selected={option.value === sort}
                            >
                                {option.displayValue}
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </>
        );
    }
};
