import React from "react";
import {Formik} from "formik";
import {DatePickerProvider} from "./DatePickerContext";

export const DamsForm = ({
                             initialValues,
                             onSubmit,
                             validationSchema,
                             children,
                         }) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            <DatePickerProvider>{children}</DatePickerProvider>
        </Formik>
    );
};
