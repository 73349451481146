import React, {useRef, useState} from "react";
import {useDocumentCount} from "./useDocumentCount";
import {AdminContainer} from "../admin/AdminContainer";
import {useFolderCount} from "./useFolderCount";
import {useTheme} from "@mui/material";
import {damsFetch} from "../app/damsFetch";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import IndexingStats from "./IndexingStats";
import SolrStatus from "./SolrStatus";
import ListIcon from "@mui/icons-material/List";

export const Reindex = () => {
    const theme = useTheme();
    const documentCount = useDocumentCount();
    const folderCount = useFolderCount();

    const [isIndexingDocuments, setIsIndexingDocuments] = useState(false);
    const [isIndexingFolders, setIsIndexingFolders] = useState(false);

    let localStorageKeyDocs = "dams.indexing.docs.jobId";
    let localStorageKeyFolders = "dams.indexing.folders.jobId";

    let refDocumentStats = useRef();
    let refFolderStats = useRef();

    const getOngoingJobId = (jobType) => {
        let key =
            jobType === "documents" ? localStorageKeyDocs : localStorageKeyFolders;
        return window.localStorage.getItem(key);
    };

    const indexAllDocuments = () => {
        damsFetch("/scheduler/reindex/documents/")
            .then((res) => {
                refDocumentStats.current.startPolling(res.jobId);
                window.localStorage.setItem(localStorageKeyDocs, res.jobId);
                setIsIndexingDocuments(true);
            })
            .catch((_) => setIsIndexingDocuments(false));
    };

    const indexAllFolders = () => {
        damsFetch("/scheduler/reindex/folders/")
            .then((res) => {
                refFolderStats.current.startPolling(res.jobId);
                window.localStorage.setItem(localStorageKeyFolders, res.jobId);
                setIsIndexingFolders(true);
            })
            .catch((_) => setIsIndexingFolders(false));
    };

    const stopIndexing = (indexingType, numIndexed) => {
        let count = indexingType === "documents" ? documentCount : folderCount;
        if (count > 0 && numIndexed >= count) {
            if (indexingType !== "documents") {
                setIsIndexingFolders(false);
            } else {
                setIsIndexingDocuments(false);
            }
        }
    };

    const startOrInProgress = () => {
        return (
            <div>
                <Typography>
                    Det er {documentCount} dokumenter og {folderCount} mapper i databasen.
                </Typography>
                <Box
                    sx={{marginTop: 2, marginBottom: 2}}
                    key={"doc-container"}
                >
                    <LoadingButton
                        sx={{width: "250px", marginBottom: 2}}
                        variant={"contained"}
                        color={"secondary"}
                        onClick={indexAllDocuments}
                        loadingPosition="end"
                        endIcon={<ListIcon/>}
                        loading={isIndexingDocuments}
                    >
                        Indekser dokumenter
                    </LoadingButton>
                    <IndexingStats
                        ref={refDocumentStats}
                        key={"stats-container"}
                        statsType={"documents"}
                        pollInterval={30000}
                        total={documentCount}
                        stopIndexingCallback={stopIndexing}
                        jobId={getOngoingJobId}
                    />
                </Box>

                <Box
                    sx={{marginTop: 2, marginBottom: 2}}
                    key={"button-container"}
                >
                    <LoadingButton
                        sx={{width: "250px", marginBottom: 2}}
                        variant={"contained"}
                        color={"secondary"}
                        onClick={indexAllFolders}
                        loadingPosition="end"
                        endIcon={<ListIcon/>}
                        loading={isIndexingFolders}
                    >
                        Indekser Mapper
                    </LoadingButton>
                    <IndexingStats
                        ref={refFolderStats}
                        key={"indexing-stats-container"}
                        statsType={"folders"}
                        pollInterval={30000}
                        total={folderCount}
                        stopIndexingCallback={stopIndexing}
                        jobId={getOngoingJobId}
                    />
                </Box>

                <SolrStatus/>
            </div>
        );
    };

    return (
        <AdminContainer>
            <Paper
                sx={{
                    margin: theme.spacing(4),
                    padding: theme.spacing(4),
                    width: "100%",
                }}
            >
                <Typography variant={"h3"} component={"h1"}>
                    Reindekser databasen
                </Typography>
                {startOrInProgress()}
            </Paper>
        </AdminContainer>
    );
};
