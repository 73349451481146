import {UPDATE_FORM_DATA} from "../documents/documentContext";

/**
 * Handle used to delete a copyright clause entry.
 * @param formData  object  object containing all the form fields and their values.
 * @param setFieldValue method   Formik method used to set a specific field value.
 * @param values array  Array holding the previously set values.
 * @param obj
 *
 * NOTE:
 * Object data is maintained in two parallel structures:
 * - formData - directly manipulated and updated via documentDispatch()
 * - Formik - each field is updated vid setField(key, value). Using setFieldValue(key, undefined) removes the field from
 *            the formik structure.
 */
const _deleteCopyrightClauseHandler = (formData, setFieldValue, values, obj) => {
    const clauseIx = formData.copyrightInfo.findIndex(c => c.uniqueId === obj.uniqueId);

    if (clauseIx === -1) {
        return;
    }

    // Mark the copyrightInfo entry as deleted - remove it from the GUI!
    formData.copyrightInfo[clauseIx].deleted = true;

    // Remove the data from the formData object.
    formData.copyrightInfo.splice(clauseIx, 1);

    //
    // Rebuild the values array, based on the data in the formData object.
    //

    // Removing copyrightFromDate- and copyrightToDate entries.
    const keysArr = Object.keys(values);
    keysArr.forEach(k => {
        if (k.indexOf('copyrightFromDate') > -1 || k.indexOf('copyrightToDate') > -1) {
            delete values[k];
        }
    });

    // Add the necessary entries to the values object - the values being saved!
    for (let i = 0, max = values.copyrightInfo.length; i < max; i++) {
        values[`copyrightFromDate${i}`] = values.copyrightInfo[i]['fromDate'];
        values[`copyrightToDate${i}`] = values.copyrightInfo[i]['toDate'];
    }
};

/**
 * Handler used to delete a license entry.
 * @param formData  object  object containing all the form fields and their values.
 * @param setFieldValue method   Formik method used to set a specific field value.
 * @param obj   The object representing the license
 *
 * NOTE:
 * Object data is maintained in two parallel structures:
 * - formData - directly manipulated and updated via documentDispatch()
 * - Formik - each field is updated vid setField(key, value). Using setFieldValue(key, undefined) removes the field from
 *            the formik structure.
 */
const _deleteLicenseHandler = (formData, setFieldValue, obj) => {
    const licenseIx = formData['licenses'].findIndex(l => l.reference.uniqueId === obj.reference.uniqueId);

    // Remove license[ix]
    delete formData[`license${licenseIx}`];

    // Remove from licensesAssociated[ix]
    delete formData[`licensesAssociated${licenseIx}`];

    // Remove from licenses array
    formData.licenses = formData.licenses.filter(l => l.reference.uniqueId !== obj.reference.uniqueId);

    // Update formik field
    setFieldValue('licenses', formData.licenses);
    setFieldValue(`license${licenseIx}`, undefined);

    //
    // The routines below is used for restructuring/reindexing the remaining licenses.
    //

    const keysArr = Object.keys(formData);
    const numKeys = keysArr.length;
    const associated = [];
    const licenses = [];

    // Reindex/rename licensesAssociatedIX keys accordingly.
    keysArr.forEach((k) => {
        if (k.indexOf('licensesAssociated') > -1) {
            if (typeof (formData[k]) !== 'undefined' && typeof (formData[k][0]) !== 'undefined') {
                associated.push(formData[k]);
            }
            delete formData[k];
            setFieldValue(k, undefined);
        }
    });

    // Reconstruct licensesAssociatedIX keys.
    associated.forEach((a, ix) => {
        formData[`licensesAssociated${ix}`] = a;
        setFieldValue(`licensesAssociated${ix}`, a);
    });

    // Reindex/rename license
    keysArr.forEach((k) => {
        for (let i = 0; i < numKeys; i++) {
            if (k.indexOf(`license${i}`) > -1) {
                licenses.push(formData[k]);
                delete formData[k];
            }
        }
    });

    // Reconstruct licenseIX keys.
    licenses.forEach((l, ix) => {
        formData[`license${ix}`] = l;
        setFieldValue(`license${ix}`, l);
    });
}


/**
 * Handler triggered when the user clicks the delete icon,
 * used to delete copyright clauses and licenses.
 * @param docDispatch method    Dispatcher method used to "relay" messages when data is altered.
 * @param formData  object  object containing all the form fields and their values.
 * @param setFieldValue method   Formik method used to set a specific field value.
 * @param objectType    {*}     clause || license object
 * @param ix    integer     The index number in the fields array
 * @param obj   {*}     The object selected for deletion
 * @param values array  Array holding the previously set values.
 */
export const deleteHandler = (docDispatch, formData, setFieldValue, objectType, ix, obj, values) => {
    if (objectType === 'clause') {
        _deleteCopyrightClauseHandler(formData, setFieldValue, values, obj);
    } else if (objectType === 'license') {
        _deleteLicenseHandler(formData, setFieldValue, obj);
    } else {
        return;
    }

    docDispatch({
        type: UPDATE_FORM_DATA,
        formData: formData,
    });
};

/**
 * Method used to delete the copyright type.
 * @param formData  object  object containing all the form fields and their values.
 * @param setFieldValue method   Formik method used to set a specific field value.
 * @param docDispatch method    Dispatcher method used to "relay" messages when data is altered.
 */
export const deleteCopyrightTypeHandler = (formData, setFieldValue, docDispatch) => {
    // Update formData fields
    formData['copyrightType'] = '';
    formData['copyrightTypeOriginator'] = [];
    formData['copyrightTypeResponsible'] = [];
    formData['copyrightTypeDateUntil'] = '';

    // Update formik fields
    setFieldValue('copyrightType', '');
    setFieldValue('copyrightTypeOriginator', []);
    setFieldValue('copyrightTypeResponsible', []);
    setFieldValue('copyrightTypeDateUntil', 'delete');

    docDispatch({
        type: UPDATE_FORM_DATA,
        formData: formData
    });
};
