import React from "react";
import { makeStyles } from "@mui/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  main: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export const FileCardErrorIcon = ({ show }) => {
  const classes = useStyles();

  if (show) {
    return (
      <div className={classes.main}>
        <ErrorOutlineIcon color={"error"} />
      </div>
    );
  } else {
    return null;
  }
};
