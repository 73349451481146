import React from "react";
import Grid from "@mui/material/Grid";
import {UploadedFilesToolbar} from "../files/UploadedFilesToolbar";
import {ViewTypeOption} from "../files/ViewTypeOption";
import {DamsForm} from "../form/DamsForm";
import {FormMetadata} from "./FormMetadata";
import {UploadedFilesGrid} from "../files/UploadedFilesGrid";
import {UploadedFilesTable} from "../files/UploadedFilesTable";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import {useDocumentState} from "../documents/documentContext";
import {FormikSubmitButton} from "../form/FormikSubmitButton";
import {useNewFilesTranslation} from "../files/NewFilesTranslationContext";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

export const MetadataGrid = ({
                                 toolbarRef,
                                 viewType,
                                 setViewTypeCallback,
                                 clearAllCallback,
                                 checkAllCallback,
                                 checked,
                                 checkFileCallback,
                                 saveDocumentsCallback,
                                 validationSchema,
                                 numberOfCheckedCallback,
                             }) => {
    const t = useNewFilesTranslation();
    const {formData, files, saved} = useDocumentState();

    const getNumMissingTitle = () => {
        let numMissingTitle = files.length;
        if (saved && saved.length > 0) {
            const hasTitle = files.filter((f) => {
                const m =
                    undefined !==
                    saved.find((s) => s.content.mediae[0].reference.sourceId === f.dmsId);
                if (m) {
                    return f;
                }
                return null;
            }).length;
            numMissingTitle = files.length - hasTitle;
        }
        return numMissingTitle;
    };

    const getViewBySelectedType = () => {
        if ("gridlist" === viewType) {
            return (
                <UploadedFilesGrid
                    checked={checked}
                    checkFile={checkFileCallback}
                    smallscreen={false}
                />
            );
        } else {
            return (
                <UploadedFilesTable checked={checked} checkFile={checkFileCallback}/>
            );
        }
    };

    return (
        <DamsForm
            onSubmit={saveDocumentsCallback}
            initialValues={formData}
            validationSchema={validationSchema}
        >
            <Paper elevation={3} sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}>
                <Grid
                    container
                    direction={"row"}
                    alignContent={"flex-start"}
                    justifyContent={"flex-start"}
                >
                    <Grid item md={8}>
                        <div ref={toolbarRef}>
                            <UploadedFilesToolbar
                                checkAllCallback={checkAllCallback}
                                clearAllCallback={clearAllCallback}
                                numberOfCheckedCallback={numberOfCheckedCallback}
                            >
                                <Box sx={{flexGrow: 1}}/>
                                <ViewTypeOption
                                    viewType={viewType}
                                    setViewType={setViewTypeCallback}
                                />
                            </UploadedFilesToolbar>
                        </div>

                        {/* Uploaded files list */}
                        <Box sx={{paddingLeft: 2, paddingRight: 2}}>
                            {getViewBySelectedType()}
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        {0 === numberOfCheckedCallback() &&
                            getNumMissingTitle() === files.length && (
                                <Box sx={{paddingTop: 3, paddingRight: 2}}>
                                    <Typography variant={"h6"}>
                                        {t("addMetadata", "Legg til metadata")}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {t(
                                            "addMetadataHelp",
                                            "Velg bilder for å redigere metadata. Velg flere bilder samtidig for å legge til den " +
                                            "samme informasjonen på alle de valgte bildene. Bilder må ha minimum " +
                                            "tittelfeltet utfylt for å kunne lagres."
                                        )}
                                    </Typography>
                                </Box>
                            )}

                        {0 < numberOfCheckedCallback() && (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        paddingTop: 3,
                                    }}
                                >
                                    <Typography variant={"h6"}>
                                        {t(
                                            "editMetadataNumberOfCheckedObjects",
                                            "Rediger metadata for {{numChecked}} valgte bilder.",
                                            {numChecked: numberOfCheckedCallback()}
                                        )}
                                    </Typography>
                                    <Box flexGrow={1}/>
                                    <CloseIcon
                                        onClick={clearAllCallback}
                                        sx={{cursor: "pointer", color: "GrayText"}}
                                    />
                                </Box>

                                <Box sx={{padding: 2}}>
                                    <FormMetadata disabled={0 === numberOfCheckedCallback()}
                                                  initialValues={formData}
                                                  fileUploadMode={typeof (checkFileCallback) !== 'undefined'}/>
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>

                {/* Bottom toolbar */}
                <Box padding={2}>
                    <Divider/>
                    <Box sx={{display: "flex", marginTop: 2, alignItems: "center"}}>
                        <InfoIcon sx={{marginRight: 1, color: "GrayText"}}/>
                        <Box>
                            <Typography color={"GrayText"}>
                                {t(
                                    "editMetadataMissingTitle",
                                    "{{numMissingTitle}} bilder mangler tittel",
                                    {numMissingTitle: getNumMissingTitle()}
                                )}
                            </Typography>
                        </Box>
                        <Box flexGrow={1}/>
                        {numberOfCheckedCallback() > 0 && (
                            <Box>
                                <Button onClick={clearAllCallback} sx={{marginRight: 1}}>
                                    {t('btnCancel', 'Avbryt')}
                                </Button>
                                <FormikSubmitButton variant={"contained"} color={"secondary"}>
                                    {t("btnSaveEditedMetadata", "Lagre metadata")}
                                </FormikSubmitButton>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Paper>
        </DamsForm>
    );
};
