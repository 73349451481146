import React, { useEffect } from "react";
import { ColumnForm } from "../form/ColumnForm";
import { useFormikContext } from "formik";
import { useMetaMappingTranslation } from "./MetaMappingContext";
import { FormikArrayTextField } from "../form/FormikArrayTextField";
import { FormikSubmitButton } from "../form/FormikSubmitButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";

export const FormMappingConfig = ({
  activeType,
  configActiveType,
  selectedField,
  disabled = false,
}) => {
  const t = useMetaMappingTranslation();
  const { resetForm, dirty } = useFormikContext();

  useEffect(() => {
    if (!disabled) {
      resetForm();
    }
  }, [disabled, resetForm]);

  const getFieldLabel = () => {
    const field = configActiveType.fields[selectedField];
    return t(field.transKey, field.transDefaultValue);
  };

  const getFieldKey = () => {
    return activeType + selectedField;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ColumnForm>
        <FormikArrayTextField
          formikKey={getFieldKey()}
          label={getFieldLabel()}
          required={true}
          disabled={disabled}
          fullWidth={true}
        />
      </ColumnForm>
      <Toolbar>
        <Box flexGrow={1} />
        <Button onClick={resetForm}>{t("reset", "Angre")}</Button>
        <FormikSubmitButton
          variant={"contained"}
          color={"secondary"}
          disabled={!dirty}
        >
          {t("save", "Lagre")}
        </FormikSubmitButton>
      </Toolbar>
    </Box>
  );
};
