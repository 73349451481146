import React, {useRef} from "react";
import {EditActions} from "./EditActions";
import {DialogContent} from "@mui/material";
import {FormMetadata} from "../metadata/FormMetadata";

export const EditContentActionsBelow = ({isProjectObject, collectionId}) => {
    const cardContentRef = useRef(null);

    return (
        <>
            <DialogContent ref={cardContentRef} sx={{overflowY: "auto"}}>
                <FormMetadata isProjectObject={isProjectObject} collectionId={collectionId}/>
            </DialogContent>
            <EditActions/>
        </>
    );
};
