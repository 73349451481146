import React from "react";
import Box from "@mui/material/Box";
import {MyFoldersGrid} from "./MyFoldersGrid";
import {AvailableFoldersToolbar} from "./AvailableFoldersToolbar";
import {useGetUploadFolders} from "../../folder/useGetUploadFolders";

export const AvailableFolders = () => {
    useGetUploadFolders({fileUploaderV2: true});
    return (<Box>
        <AvailableFoldersToolbar/>
        <Box sx={{paddingTop: '32px', paddingLeft: '16px', paddingRight: '16px'}}>
            <MyFoldersGrid/>
        </Box>
    </Box>);
};
