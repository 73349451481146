import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useFoldersTranslation} from "../folder/FoldersContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {TOGGLE_DELETE_FILES, useResultActionDispatch, useResultActionState,} from "./ResultActionContext";
import {damsFetch} from "../app/damsFetch";
import {Box, Button, Grow, Modal, Paper, Toolbar, Typography,} from "@mui/material";
import {PromisePool} from "@supercharge/promise-pool";
import {SET_DELETING, useSearchDispatch} from "./SearchContext";

const PREFIX = "DeleteFilesModal";

const classes = {
    modal: `${PREFIX}-modal`,
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
    marginLeft: `${PREFIX}-marginLeft`,
    marginBottom: `${PREFIX}-marginBottom`,
    toolbar: `${PREFIX}-toolbar`,
    break: `${PREFIX}-break`,
};

const StyledModal = styled(Modal)(({theme}) => ({
    [`&.${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.content}`]: {
        outline: "none",
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(4),
        height: "100%",
    },

    [`& .${classes.marginLeft}`]: {
        marginLeft: theme.spacing(2),
    },

    [`& .${classes.marginBottom}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.toolbar}`]: {
        padding: 0,
        flexWrap: "wrap",
        marginBottom: theme.spacing(1),
    },

    [`& .${classes.break}`]: {
        flexBasis: "100%",
        height: 0,
    },
}));

export const DeleteFilesModal = ({selectedDocuments, onSuccess}) => {
    const {showDeleteFiles} = useResultActionState();
    const dispatch = useResultActionDispatch();
    const searchDispatch = useSearchDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const t = useFoldersTranslation();
    const [documents, setDocuments] = useState(null);

    useEffect(() => {
        setDocuments(selectedDocuments);
    }, [selectedDocuments]);

    const deleteDocument = (id) => {
        return damsFetch("/documents/" + id, {
            method: "DELETE",
        })
            .then((json) => {
                return Promise.resolve(json);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const deletedDocumentsCallback = (count) => {
        if (count === 0) {
            return;
        }
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("deleteFilesSuccessTitle", "Sletting fullført"),
                body: t("deleteFilesSuccessBody", "{{count}} filer er nå slettet.", {count: count}),
            },
        });
    };

    const failedDeletionCallback = (count) => {
        if (count === 0) {
            return;
        }

        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("deleteFilesFailedTitle", "Sletting feilet"),
                body: t(
                    "deleteFilesFailedBody",
                    "{{count}} av de valgte filene ble ikke slettet.",
                    {count: count}
                ),
                type: "error",
            },
        });
    };

    const deleteDocuments = (docs) => {
        onHide();
        PromisePool
            .withConcurrency(4)
            .for(docs)
            .process(async (doc, index, pool) => {
                return await deleteDocument(doc.uniqueId);
            })
            .then((res) => {
                failedDeletionCallback(res.errors.length);
                deletedDocumentsCallback(res.results.length);
                onSuccess(documents);
            });
    };

    const onHide = () => {
        dispatch({type: TOGGLE_DELETE_FILES});
    };

    const deleteFilesHandler = () => {
        if (documents === null || documents.length === 0) {
            return;
        }
        searchDispatch({type: SET_DELETING});
        deleteDocuments(documents);
    };

    return (<StyledModal
            className={classes.modal}
            open={showDeleteFiles || false}
            closeAfterTransition
        >
            <div className={classes.content}>
                <Grow in={showDeleteFiles}>
                    <Paper className={classes.paper}>
                        <Toolbar className={classes.toolbar}>
                            <Typography component={"h2"} variant={"h6"}>
                                {t("deleteDocumentsHeader", "Slette dokumenter")}
                            </Typography>
                            <div className={classes.break}/>
                            <Typography component={"div"}>
                                {t(
                                    "deleteDocumentsConfirm",
                                    "Er du sikker på at du ønsker å slette {{count}} {{text}}?",
                                    {
                                        count: selectedDocuments.length,
                                        text:
                                            selectedDocuments.length > 1
                                                ? "dokumenter"
                                                : "dokument",
                                    }
                                )}
                            </Typography>
                        </Toolbar>
                        <Toolbar>
                            <Box flexGrow={1}/>
                            <Button onClick={onHide}>{t("cancel", "Avbryt")}</Button>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                className={classes.marginLeft}
                                disabled={false}
                                onClick={deleteFilesHandler}
                            >
                                {t("removeDocumentsButton", "Ok")}
                            </Button>
                        </Toolbar>
                    </Paper>
                </Grow>
            </div>
        </StyledModal>
    );
};
