import {SET_DONE_SEARCHING, SET_ERROR, SET_RESULT, useFoldersDispatch, useFoldersState,} from "./FoldersContext";
import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import useDeepCompareEffect from "use-deep-compare-effect";
import {clientLog} from "../clientLog";

export const useFolderSearch = ({
                                    query,
                                    sort,
                                    fl,
                                    start = 0,
                                    rows = 100,
                                    fq = [],
                                    status = '(-trash)' || status,
                                    museums = [],
                                    expand = false,
                                    onlyMine = false,
                                    onlyOthers = false,
                                    folderType = "",
                                }) => {

    const {privateOnly, searching} = useFoldersState();

    const searchDispatch = useFoldersDispatch();

    const getFacet = (facet) => {
        if (!facet) {
            return 0;
        }

        let counter = {};
        for (let i = 0; i < facet.length; i += 2) {
            counter[facet[i]] = {
                count: facet[i + 1],
            };
        }
        return counter;
    };

    const getStats = (stats) => ({
        min: stats.min,
        max: stats.max,
    });

    const clearExistingResults = () => {
        searchDispatch({
            type: SET_RESULT,
            count: 0,
            models: [],
            facets: {},
            stats: {},
        });
    };

    useDeepCompareEffect(() => {
        if ((!onlyOthers && !onlyMine) && (!museums || museums?.length === 0)) {
            return;
        }

        if (!searching) {
            return;
        }

        clearExistingResults();

        const params = {
            q: query,
            sort: sort,
            fl: fl,
            expand: expand,
            start: start,
            rows: rows,
            documentType: `(Folder)`,
            museums: museums,
            onlyMine: onlyMine,
            onlyOthers: onlyOthers,
            status: privateOnly ? '(-trash private)' : status,
            facetField: "tags.reference.title,created_by_name", // Add additional filter fields here!
            facetLimit: 100,
            statsField: "created_at,updated_at,production_date,created_by_name",
        };

        if (folderType !== "") {
            params.folderType = folderType;
        }

        const searchParams = new URLSearchParams(decamelizeKeysDeep(params));

        if (Object.keys(fq).length) {
            const fqGrouped = fq.reduce((acc, object) => {
                if (acc[object.key]) {
                    acc[object.key].push(object.value);
                } else {
                    acc[object.key] = [object.value];
                }
                return acc;
            }, {});

            const fqParam = Object.keys(fqGrouped)
                .map(
                    (key) =>
                        `${key}:(${fqGrouped[key].map(encodeURIComponent).join(" OR ")})`
                )
                .join(",");
            searchParams.append("fq", fqParam);
        }

        damsFetch(`/folders/search?${searchParams.toString()}`)
            .then((json) => {
                const {facetFields, statsFields} = json;
                searchDispatch({
                    type: SET_RESULT,
                    count: json['count'],
                    models: json['models'],
                    facets: {
                        tags: getFacet(facetFields['tagsReferenceTitle']),
                        createdByName: getFacet(facetFields['createdByName'])
                    },
                    stats: {
                        createdAt: getStats(statsFields['createdAt']),
                        updatedAt: getStats(statsFields['updatedAt']),
                        productionDate: getStats(statsFields['productionDate']),
                        createdByName: getStats(statsFields['createdByName'])
                    },
                });
                searchDispatch({type: SET_DONE_SEARCHING});
            })
            .catch((error) => {
                clientLog('error', error, 'useFolderSearch');
                searchDispatch({type: SET_ERROR});
            });
    }, [
        query,
        sort,
        fl,
        start,
        rows,
        fq,
        expand,
        searchDispatch,
        onlyMine,
        onlyOthers,
        museums,
        searching
    ]);
};
