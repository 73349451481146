import React from "react";
import {useDocumentState} from "../documents/documentContext";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import {makeStyles} from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined, IndeterminateCheckBoxOutlined,} from "@mui/icons-material";
import {useTheme} from "@emotion/react";

const useStyles = makeStyles((theme) => ({
    checkbox: {
        cursor: "pointer",
        marginRight: theme.spacing(2),
    },
}));

export const UploadedFilesToolbar = ({
                                         children,
                                         clearAllCallback,
                                         checkAllCallback,
                                         numberOfCheckedCallback,
                                     }) => {
    const theme = useTheme();
    const classes = useStyles();
    const {files} = useDocumentState();
    const t = useNewFilesTranslation();

    const getCheckbox = () => {
        const checked = numberOfCheckedCallback();
        if (checked === 0) {
            return (
                <CheckBoxOutlineBlankOutlined
                    onClick={checkAllCallback}
                    className={classes.checkbox}
                />
            );
        } else if (checked > 0 && checked !== files.length) {
            return <IndeterminateCheckBoxOutlined onClick={clearAllCallback} className={classes.checkbox}/>;
        } else {
            return (
                <CheckBoxOutlined
                    onClick={clearAllCallback}
                    className={classes.checkbox}
                />
            );
        }
    };

    return (
        <Toolbar
            sx={{padding: 0, flexWrap: "wrap", marginBottom: theme.spacing(1)}}
        >
            {getCheckbox()}
            <Typography variant={"h6"} component={"h2"}>
                {t("uploaded", "Opplastet")} ({files.length})
            </Typography>
            {children}
        </Toolbar>
    );
};
