import React, {useEffect, useState} from "react";
import {useMyMuseums} from "./useMyMuseums";
import {damsFetch} from "../app/damsFetch";
import {useAuthsState} from "../auths/authsContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {ListItemButton} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import {useDmsStatusState} from "../dmsstatus/DmsStatusContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuseumIcon from "@mui/icons-material/Museum";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import {DialogWrapper} from "../app/DialogWrapper";
import {useNavigate} from "react-router-dom";

/**
 * Component that displays the select museum dialog that appears in the beginning of the different wizards.
 * @param selectedMuseumCallback
 * @param collectionId
 * @param dialogTitle
 * @param projectContext    bool    True if displayed in project context, filtering out museums without projects enabled.
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectMuseumDialog = ({selectedMuseumCallback, collectionId, dialogTitle, projectContext = false}) => {
    const navigate = useNavigate();

    const {userData, museumFeatures} = useAuthsState();
    const {dmsStatus} = useDmsStatusState();

    const username = userData?.name;

    const {museumCollections} = useAuthsState();

    const myMuseums = useMyMuseums({onlyAdministrativeMuseums: false});

    // Holds the list of museums the user has access to.
    const [accessibleMuseums, setAccessibleMuseums] = useState();

    const snackbarDispatch = useSnackbarDispatch();

    const [openDialog, setOpenDialog] = useState(false);

    const showDmsError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: "DAMS feil - kontakt support",
                body: "Filopplasting ikke tilgjengelig. Kan ikke nå lagringsområdet.",
                type: "error",
            },
        });
    };

    /**
     * Tags the user's museums that has an invalid DMS-config with "incorrectConfig"=true.
     * @param museumsList Array List of the user's museums.
     * @returns {*}
     */
    const invalidateMenuItemsWithInvalidConfig = (museumsList) => {
        return museumsList.map((am) => {
            const incorrect = dmsStatus['missingConfigs'].includes(am['ekultur_id']);
            return {...am, incorrectConfig: incorrect};
        });
    };

    /**
     * Tags all the user's museums with "incorrectConfig"=true.
     * @param museumsList   Array   List ov user's museums.
     * @returns {*}
     */
    const invalidateAllMenuItems = (museumsList) => {
        return museumsList.map((m) => {
            return {...m, incorrectconfig: true};
        });
    };

    /**
     * Handler triggered when the dialog closes.
     * @param event object  Event object.
     * @param reason string The reason for why the dialog was closed.
     */
    const dialogCloseHandler = (event, reason) => {
        if ('continue' === reason) {
            setOpenDialog(false);
        }
    };

    /**
     * Handler triggered when the user clicks the Cancel button.
     * The dialog is closed, and the upload-wizard is "terminated", leading the user back to "home".
     */
    const dialogCancelHandler = () => {
        setOpenDialog(false);
        navigate('/');
    };

    /**
     * Returns the DialogActions section, used to render the buttons in the dialog.
     * @param cId   int The collection ID of the selected museum, or null if the user has access to only one museum.
     * @returns {JSX.Element}
     */
    const getDialogActions = (cId) => {
        let btnContinueDisabled = false;
        let onClickMethod = () => {
            selectedMuseumCallback(cId);
            setTimeout(() => {
                dialogCloseHandler(null, 'continue');
            }, 200);
        };

        if (!cId) {
            btnContinueDisabled = collectionId === -1;
            onClickMethod = () => {
                dialogCloseHandler(null, 'continue');
            };
        }

        return <DialogActions>
            <Button onClick={dialogCancelHandler}>Avbryt</Button>
            <Button variant={"contained"}
                    onClick={onClickMethod}
                    disabled={btnContinueDisabled}>
                Fortsett
            </Button>
        </DialogActions>;
    };

    /**
     * Returns a List containing a single museum - the one the user has access to.
     * @param museum    object  The museum the user has access to.
     * @returns {JSX.Element}
     */
    const getSingleMuseumList = (museum) => {
        const {incorrectConfig, name} = museum;
        return <List>
            <ListItem>
                <ListItemButton disabled={incorrectConfig} selected={!incorrectConfig}>
                    <ListItemIcon>
                        <MuseumIcon/>
                    </ListItemIcon>
                    <ListItemText primary={name}
                                  secondary={incorrectConfig ? 'Feil: Når ikke lagringsløsningen' : ''}/>
                </ListItemButton>
            </ListItem>
        </List>;
    };

    /**
     * Returns a List of museums, the user has access to.
     * @param museums Array The list of museums the user has access to.
     * @returns {JSX.Element}
     */
    const getMuseumList = museums => {
        let showMuseums = museums;
        if (projectContext) {
            showMuseums = museums.filter((m) => {
                return museumFeatures.find(mf => (mf.id === m.id && mf.features['archeology'] === true));
            });
        }
        return <List>
            {showMuseums.map(museum => {
                const {id, incorrectConfig, name} = museum;
                return <ListItem key={id}>
                    <ListItemButton disabled={incorrectConfig}
                                    selected={museum.collectionId === collectionId}
                                    onClick={() => selectedMuseumCallback(museum)}>
                        <ListItemIcon>
                            <MuseumIcon/>
                        </ListItemIcon>
                        <ListItemText primary={name}
                                      secondary={incorrectConfig ? 'Feil: Når ikke lagringsløsningen' : ''}/>
                    </ListItemButton>
                </ListItem>
            })}
        </List>;
    };

    /**
     * Hook used to fetch the username for the current user.
     */
    useEffect(() => {
        if (username) {
            damsFetch("/current-user/", {
                method: "POST",
                body: JSON.stringify({name: username}),
            }).then(null);
        }
    }, [username]);

    /**
     * Hook used to "mark" museums that has an invalid config. for DMS, and is therefore unavailable
     * when attempting to upload files or save data.
     *
     * NOTE: quit hook if dmsStatus is not set or a museum (collectionId) has already been selected.
     */
    useEffect(() => {
        if (!dmsStatus
            || !dmsStatus['missingConfigs']
            || collectionId > -1
            || !museumCollections
            || museumCollections?.length === 0) {
            return;
        }

        let museumsList = myMuseums.map(m => {
            const collectionId = museumCollections.find(mc => mc.museumId === m.id).collectionId;
            return {
                ...m,
                collectionId: collectionId
            };
        });

        if (dmsStatus.errorCount > 0) {
            museumsList = invalidateAllMenuItems(museumsList);
            showDmsError();
        } else {
            museumsList = invalidateMenuItemsWithInvalidConfig(museumsList);
        }
        setAccessibleMuseums(museumsList);
    }, [myMuseums, dmsStatus, museumCollections]);

    /**
     * Await opening the dialog until the contents are ready.
     */
    useEffect(() => {
        if (collectionId > -1) {
            return;
        }

        setTimeout(() => {
            setOpenDialog(true);
        }, 300);
    }, [accessibleMuseums, collectionId]);

    /**
     * Current user has not access to any museum, show a warning, and return to DAMS frontpage.
     * @returns {JSX.Element}
     */
    const getNoAccessDialog = () => {

        const dialogActions = () => {
            return <DialogActions>
                <Button onClick={() => {
                    navigate('/');
                }}>Lukk</Button>
            </DialogActions>;
        };

        return <DialogWrapper openDialog={openDialog}
                              closeHandler={() => {
                                  navigate('/');
                              }}
                              dialogActions={dialogActions}>
            <Typography>Beklager - det har oppstått en ukjent feil.</Typography>
            <Typography>Oppgaven kan ikke gjennomføres, vi tar deg til DAMS forside.</Typography>
        </DialogWrapper>;
    };

    /**
     * Current user has access to only one museum, show it.
     * @returns {JSX.Element}
     */
    const getOneMuseumOnlyDialog = (userMuseum) => {
        return <DialogWrapper
            openDialog={openDialog}
            closeHandler={dialogCloseHandler}
            dialogActions={() => getDialogActions(userMuseum)}>
            <Typography>Du er i ferd med å utføre en oppgave mot</Typography>
            {getSingleMuseumList(userMuseum)}
        </DialogWrapper>;
    };

    /**
     * Current user has access to multiple museums, show them.
     * @returns {JSX.Element}
     */
    const getMultipleMuseumsDialog = () => {
        return <DialogWrapper openDialog={openDialog}
                              dialogTitle={dialogTitle}
                              closeHandler={dialogCloseHandler}
                              dialogActions={getDialogActions}>
            <Stack direction={"column"}>
                <Typography>
                    Vi ser du har tilgang til flere enn ett museum, velg museet du ønsker å utføre oppgaven mot:
                </Typography>
                {getMuseumList(accessibleMuseums)}
            </Stack>
        </DialogWrapper>;
    }

    // Render the dialog.
    if (!accessibleMuseums) {
        return getNoAccessDialog();
    } else if (accessibleMuseums?.length === 1) {
        return getOneMuseumOnlyDialog(accessibleMuseums[0]);
    } else {
        return getMultipleMuseumsDialog();
    }
};
