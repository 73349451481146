import "react-app-polyfill/stable";
import React, {useEffect} from "react";
import "./index.css";
import "./app/i18n";
import {App} from "./app/App";

import {createRoot} from "react-dom/client";

import * as Sentry from "@sentry/react";

import * as serviceWorker from './serviceWorker';
import {createRoutesFromChildren, useLocation, useNavigationType} from "react-router-dom";
import {matchRoutes} from "react-router";

Sentry.init({
    dsn: "https://8d0372db9ba34f90831274056d515551@o1289125.ingest.sentry.io/4504598320775168",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    environment: process.env.NODE_ENV,
    release: window._env_.REACT_APP_VERSION,
    enabled: ['production', 'beta'].includes(window._env_.REACT_APP_X_QUOTE_MODE),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const onSWUpdate = (registration) => {
    const event = new CustomEvent('serviceWorkerUpdate');
    document.dispatchEvent(event);
    serviceWorker = registration.waiting;
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App/>);

// Register the Service Worker.
serviceWorker.register({
    onUpdate: onSWUpdate
});