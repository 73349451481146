import React from "react";
import { Reindex } from "../../index/Reindex";
import { useIsAppAdmin } from "../../auths/useIsAppAdmin";
import { AdministrateMetadataMapping } from "../../metadata/AdministrateMetadataMapping";
import { useIsAdminOfAtleastOneMuseum } from "../../auths/useIsAdminOfAtleastOneMuseum";

export const AdministrateMetadataMappingRoute = () => {
  const isAppAdmin = useIsAppAdmin();
  const isMuseumAdmin = useIsAdminOfAtleastOneMuseum();
  const museumAdminRoutes = isMuseumAdmin || isAppAdmin;

  if (museumAdminRoutes) {
    return <AdministrateMetadataMapping />;
  } else {
    return <></>;
  }
};

export const AdminReindexRoute = () => {
  const isAppAdmin = useIsAppAdmin();
  if (isAppAdmin) {
    return <Reindex />;
  } else {
    return <></>;
  }
};
