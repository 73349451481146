import React, {createContext, useContext, useReducer} from "react";
import {DocumentProvider} from "./documentContext";

const BatchEditStateContext = createContext(undefined);
const BatchEditDispatchContext = createContext(undefined);

export const STEP_FORWARD = "batchEditContext/stepForward";
export const SELECT_FIELDS = "batchEditContext/selectFields";
export const UNSELECT_FIELDS = "batchEditContext/unselectFields";
export const SELECT_ALL_FIELDS = "batchEditContext/selectAllFields";
export const UNSELECT_ALL_FIELDS = "batchEditContext/unselectAllFields";

const initialState = {
    availableFields: [
        "title",
        "description",
        "productionDate",
        "producer",
        "persons",
        "places",
        "subjects",
        "remarks",
        "languages",
        "relations",
    ],
    selectedFields: [],
    activeStepIndex: 0,
};

const batchEditReducer = (state, action) => {
    switch (action.type) {
        case STEP_FORWARD:
            return {
                ...state,
                activeStepIndex: 1 + state.activeStepIndex,
            };
        case SELECT_FIELDS:
            return {
                ...state,
                selectedFields: [...state.selectedFields, ...action.fields],
            };
        case SELECT_ALL_FIELDS:
            return {
                ...state,
                selectedFields: state.availableFields,
            };
        case UNSELECT_FIELDS:
            return {
                ...state,
                selectedFields: state.selectedFields.filter(
                    (selectedField) => !action.fields.includes(selectedField)
                ),
            };
        case UNSELECT_ALL_FIELDS:
            return {
                ...state,
                selectedFields: [],
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const BatchEditProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        batchEditReducer,
        initialState,
        undefined
    );

    return (
        <DocumentProvider>
            <BatchEditStateContext.Provider value={state}>
                <BatchEditDispatchContext.Provider value={dispatch}>
                    {children}
                </BatchEditDispatchContext.Provider>
            </BatchEditStateContext.Provider>
        </DocumentProvider>
    );
};

export const useBatchEditState = () => {
    const context = useContext(BatchEditStateContext);
    if (undefined === context) {
        throw new Error(
            "useBatchEditState must be used within an BatchEditProvider."
        );
    } else {
        return context;
    }
};

export const useBatchEditDispatch = () => {
    const context = useContext(BatchEditDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useBatchEditDispatch must be used within an BatchEditProvider."
        );
    } else {
        return context;
    }
};
