import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridViewSharp";

export const GridViewButton = ({ onToggle, viewMode }) => {
  const color = () => ("grid" === viewMode ? "primary" : "default");

  return (
    <Tooltip title="Vis rutenett">
      <IconButton
        aria-label={"choose-grid-view"}
        onClick={() => onToggle("grid")}
        color={color()}
        size="large">
        <GridViewIcon />
      </IconButton>
    </Tooltip>
  );
};
