import {FormikSelectCopyrightType} from "./FormikSelectCopyrightType";
import React from "react";
import {FormikDateField} from "../form/FormikDateField";
import Stack from "@mui/material/Stack";
import {InputProducer} from "../person/InputProducer";

export const CopyrightTypeFieldsWrapper = ({t}) => {
    return <Stack sx={{width: '100%'}}>
        <FormikSelectCopyrightType t={t}/>
        <FormikDateField formikKey={'copyrightTypeDateUntil'}
                         label={"Opphavsrett gjelder til"}
                         helperText={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                         placeholder={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                         size={"small"}
        />
        <InputProducer
            formikKey={"copyrightTypeOriginator"}
            disabled={false}
            fullWidth={true}
            label={t('lblOriginator', 'Opphavsperson')}
            helperText={t('lblSearchOrAddNew', 'Søk eller legg til ny')}
            createLabel={t('lblAddNewPerson', 'Legg til ny person')}
            size={"small"}
        />
        <InputProducer
            formikKey={"copyrightTypeResponsible"}
            disabled={false}
            fullWidth={true}
            label={t('lblOriginatorPerson', 'Forvalter av opphavsretten')}
            helperText={t('lblSearchOrAddNew', 'Søk eller legg til ny')}
            createLabel={t('lblAddNewPerson', 'Legg til ny person')}
            size={"small"}
        />
    </Stack>;
};