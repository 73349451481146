import { styled } from "@mui/material/styles";
import React from "react";
import { InputRecipientsSearch } from "./InputRecipientsSearch";
import { Formik } from "formik";
import { FormikSubmitButton } from "../form/FormikSubmitButton";
import { useAuthsState } from "../auths/authsContext";
import { FormikTextField } from "../form/FormikTextField";
import { useSearchTranslation } from "../search/SearchContext";
import { useEmail } from "../folder/useEmail";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const PREFIX = "DialogShare";

const classes = {
  marginBottom: `${PREFIX}-marginBottom`,
  dialogPaper: `${PREFIX}-dialogPaper`,
  dialogInfo: `${PREFIX}-dialogInfo`,
  dialogActions: `${PREFIX}-dialogActions`,
};

const StyledFormik = styled(Formik)(({ theme }) => ({
  [`& .${classes.marginBottom}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.dialogPaper}`]: {
    minHeight: 400,
    maxHeight: 400,
    marginBottom: -32,
  },
}));

export const DialogShare = ({
  open,
  closeDialogCallback,
  collectionId,
  modelId,
}) => {
  const theme = useTheme();
  const t = useSearchTranslation();
  const { userData } = useAuthsState();
  const [sendEmail] = useEmail();

  const getBaseUrl = (_) => {
    const l = window.location;
    return `${l.protocol}//${l.host}`;
  };

  const getShareUrl = (_) => {
    return getBaseUrl() + `/search/?view=${modelId}`;
  };

  const createMessageBody = (message) => {
    return (
      "Hei\n\n" +
      userData.name +
      " " +
      t("txtShareMessageBody", "har delt et DAMS-dokument med deg") +
      ":\n" +
      getShareUrl() +
      "\n\n" +
      t("txtShareMessageFromSender", "Melding fra avsender") +
      ":\n" +
      message
    );
  };

  const getRecipients = (formValues) => {
    return formValues.recipients.map((v) => {
      return v.email;
    });
  };

  const handleCancelClick = (_) => {
    closeDialogCallback();
  };

  const handleOnSubmit = (formValues) => {
    const messageBody = createMessageBody(formValues.message);
    const recipients = getRecipients(formValues);
    sendEmail(
      t("shareMailSubject", "DAMS delt dokument"),
      messageBody,
      recipients,
      t("shareSnackTitle", "Deling av dokument")
    );
    closeDialogCallback();
  };

  return (
    <StyledFormik
      initialValues={{
        recipients: [],
        message: "",
      }}
      validationSchema={null}
      onSubmit={(values, { resetForm }) => {
        handleOnSubmit(values);
        resetForm();
      }}
    >
      <Dialog
        onClose={closeDialogCallback}
        open={open}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>{t("shareDocumentHeading", "Dele dokument")}</DialogTitle>
        <DialogContentText sx={{ padding: theme.spacing(2) }}>
          {t(
            "shareDocumentText",
            "Del dokumentet med andre brukere av DAMS, ved å skrive inn en melding og å velge mottager. " +
              "Mottager vil motta en mail med en direkte kobling til det delte dokumentet."
          )}
        </DialogContentText>
        <DialogContent className={classes.dialogPaper}>
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <FormikTextField
              formikKey={"message"}
              label={t("shareDocumentLabelMessage", "Melding")}
              required={false}
              fullWidth={true}
              multiline={true}
              rows={10}
              maxLength={256}
              autoFocus={true}
            />
          </Box>
          <InputRecipientsSearch
            formikKey={"recipients"}
            label={t("shareDocumentLabelRecipients", "Mottagere")}
            disabled={false}
            fullWidth={true}
            collectionId={collectionId}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
        >
          <Button onClick={() => handleCancelClick()}>
            {t("btnCancel", "Avbryt")}
          </Button>
          <FormikSubmitButton color={"primary"} variant={"contained"}>
            {t("btnContinue", "Fortsett")}
          </FormikSubmitButton>
        </DialogActions>
      </Dialog>
    </StyledFormik>
  );
};
