import React, {useState} from "react";
import {PROJECT_SET_COLLECTION_ID, useProjectDispatch} from "../../projectContext";
import {SelectMuseumDialog} from "../../../museum/SelectMuseumDialog";
import {GeneralProjectForm} from "../GeneralProjectForm";

export const GeneralProjectCreate = () => {
    const projectDispatch = useProjectDispatch();
    const [collectionId, setCollectionId] = useState();

    /**
     * Method called when the user has selected the museum.
     * @param museum
     */
    const handleSelectedMuseum = museum => {
        const {collectionId, id} = museum;

        setCollectionId(collectionId);

        projectDispatch({
            type: PROJECT_SET_COLLECTION_ID,
            collectionId: collectionId,
            museumId: id
        });
    };

    /**
     * Holds the form's initial values.
     * @type {{endedAt: string, responsible: *[], name: string, description: string, startedAt: string, active: boolean, id: string, contributors: *[], type: {name: string, id: string}, remarks: string, extReferences: string}}
     */
    const initialValues = {
        id: '',
        name: '',
        description: '',
        type: {id: '', name: ''},
        startedAt: '',
        endedAt: '',
        active: true,
        contributors: [],
        responsible: [],
        relatedProjects: [],
        remarks: '',
        extReferences: ''
    };

    return <>
        {
            !collectionId &&
            <SelectMuseumDialog dialogTitle={'Opprette prosjekt'}
                                selectedMuseumCallback={handleSelectedMuseum}
                                projectContext={true}/>
        }
        {
            collectionId &&
            <GeneralProjectForm collectionId={collectionId} initialValues={initialValues}/>
        }
    </>;
};