import React, {useState} from "react";
import {DialogShare} from "./DialogShare";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const ButtonShare = ({t, model, showLabel = false}) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleShareClick = (_) => {
        setDialogOpen(true);
    };

    const handleDialogClose = (_) => {
        setDialogOpen(false);
    };


    return model && (
        <>
            <IconButton
                color={"secondary"}
                onClick={() => handleShareClick()}
                size={showLabel ? "small" : "large"}
            >
                <ShareIcon/>
                {showLabel && (
                    <Typography variant={"caption"} sx={{marginLeft: 1}}>
                        {t('btnShare', 'Dele')}
                    </Typography>
                )}
            </IconButton>
            <DialogShare
                open={dialogOpen}
                collectionId={model.collectionId}
                modelId={model.uniqueId}
                closeDialogCallback={handleDialogClose}
            />
        </>
    );
};

ButtonShare.propTypes = {
    t: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
    showLabel: PropTypes.bool
};

export {ButtonShare};