import React from "react";
import {styled} from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {useResultTableState} from "./ResultTableContext";

const PREFIX = "ResultTableHead";

const classes = {
    visuallyHidden: `${PREFIX}-visuallyHidden`,
};

const StyledTableHead = styled(TableHead)(() => ({
    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export const ResultTableHead = ({
                                    numSelected,
                                    rowCount,
                                    orderBy,
                                    order,
                                    onRequestSort,
                                    onSelectAllClick,
                                    t,
                                }) => {

    const {columnsToRender} = useResultTableState();

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const getSortLabel = (headCell) => {
        if (headCell.disableSorting) {
            return headCell.getLabel(t);
        }

        return (
            <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
            >
                {headCell.getLabel(t)}
                {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
                ) : null}
            </TableSortLabel>
        );
    };

    return (
        <StyledTableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{"aria-label": "select all"}}
                    />
                </TableCell>
                {columnsToRender.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {getSortLabel(headCell)}
                    </TableCell>
                ))}
            </TableRow>
        </StyledTableHead>
    );
};
