import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Switch} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useEffect, useState} from "react";
import {useSearchTranslation} from "../search/SearchContext";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";

/**
 * Panel used when downloading files, allowing the user to specify the format for the
 * video files being downloaded.
 * @param multiple
 * @param videoFormatCallback
 * @returns {JSX.Element}
 * @constructor
 */
const SetVideoFormat = ({multiple, videoFormatCallback}) => {

    const t = useSearchTranslation();

    const [checked, setChecked] = useState(false);
    const [radioOption, setRadioOption] = useState('original');

    /**
     * Handle changes to the switch that turns custom image size on/off.
     * @param event
     */
    const handleSwitchToggle = (event) => {
        const format = event.target.checked
        setChecked(format);
        videoFormatCallback({format: format, selected: checked});
    };

    const handleRadioChange = event => {
        const format = event.target.value;
        setRadioOption(format);
        videoFormatCallback({format: format, selected: checked});
    };

    /**
     * Give the user a choice of different video formats.
     * NOTE: This is only displayed if multiple files have been chosen.
     * @returns {JSX.Element}
     */
    const getMultipleChoicePanel = () => {
        if (!multiple) {
            return <></>;
        } else {
            return <>
                <Divider orientation={"horizontal"} sx={{width: '100%', marginTop: '8px', marginBottom: '8px'}}/>
                <Typography variant={"subtitle1"} mt={2} mb={1}>
                    {t('oneOrMoreIsVideo', 'Vi ser at en eller flere av de merkede filene er video-filer')}
                </Typography>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={checked}
                                         onChange={handleSwitchToggle}/>}
                        label={t('useCustomVideoFormat', 'Ønsker å benytte et annet video-format')}
                    />
                </FormGroup>
            </>;
        }
    };

    /**
     * Hook used to decide whether to activate the multiple choice panel information or not.
     * If multiple files are specified, the panel will be displayed.
     */
    useEffect(() => {
        setChecked(!multiple);
    }, []);

    return <Box>
        {getMultipleChoicePanel()}
        <Box sx={{
            visibility: !checked ? 'hidden' : 'visible'
        }}>

            <FormControl>
                <RadioGroup
                    aria-labelledby="custom-video-format-group"
                    defaultValue={radioOption}
                    name="radio-custom-video-format-group"
                    value={radioOption}
                    onChange={handleRadioChange}>
                    <FormControlLabel value="original" control={<Radio/>}
                                      label={t('videoFormatOptionOriginal', 'Original')}/>
                    <FormControlLabel value="mp4" control={<Radio/>}
                                      label={t('videoFormatOptionMp3', 'mp4')}/>
                </RadioGroup>
            </FormControl>
        </Box>
    </Box>;
};

SetVideoFormat.propTypes = {
    multiple: PropTypes.bool.isRequired,
    videoFormatCallback: PropTypes.func.isRequired
};

export {SetVideoFormat};