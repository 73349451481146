import Uppy from '@uppy/core';
import {Dashboard} from '@uppy/react';
import {DmsUppyUpload} from '@ekultur/dms-uppy-upload';
import React, {useEffect, useState} from "react";
import {useUppyTranslations} from "./useUppyTranslations";
import {getDmsIdFromModel} from "../utility";
import Webcam from '@uppy/webcam';
import {STATUS} from '@ekultur/dms-upload';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {
    ADD_FAILED,
    ADD_FILES,
    ADD_SAVED,
    ADD_UPLOADED_NOT_PROCESSED,
    REMOVE_FILES_NOT_PROCESSED,
    useFileUploadDispatch,
    useFileUploadState
} from "./fileUploadContext";
import {usePostDocuments} from "../documents/usePostDocuments";
import {getMimeTypeFromFilename, getObjectTypeFromFilename} from "../damsFileObjectDefinitions";
import Box from "@mui/material/Box";
import {Dialog, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import {clientLog} from "../clientLog";
import {getXQuoteMode} from "../app/damsFetch";
import {getThumbnailUrl} from "../getThumbnailUrl";

const componentName = 'StepFileUpload';

/**
 * Asynchronously retrieves the maximum resolution of the user's webcam.
 *
 * @return {Promise<{width: number, height: number}>} A promise that resolves to an object
 * containing the maximum width and height of the webcam.
 * @throws {Error} If no webcam is found, or is not accessible.
 */
const getMaxResolution = async () => {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');

        const constraints = {
            video: {
                deviceId: videoDevices[0].deviceId
            }
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        const settings = track.getSettings();

        track.stop();

        const maxWidth = capabilities.width?.max || settings.width;
        const maxHeight = capabilities.height?.max || settings.height;

        clientLog('info', `max camera resolution: ${maxWidth}x${maxHeight}`, 'setfileupload');

        return {width: maxWidth, height: maxHeight};
    } catch (error) {
        clientLog('error', error.message, 'setfileupload');
        clientLog('warn',
            'failed to retrieve camera resolution, using 1920x1080',
            'setfileupload');
        return {width: 1920, height: 1080};
    }
};


/**
 * Handler triggered before upload, in order to provide a valid mime type for all files.
 * (The browser does not always resolve the mime-type.)
 * @param files
 */
const onBeforeUploadHandler = files => {
    /*
       {
            "source": "react:Dashboard",
            "id": "uppy-2cylinderengine/glb-1e-application/octet-stream-1838084-1695291811517",
            "name": "2CylinderEngine.glb",
            "extension": "glb",
            "meta": {
                "relativePath": null,
                "name": "2CylinderEngine.glb",
                "type": "application/octet-stream"
            },
            "type": "application/octet-stream",
            "data": {},
            "progress": {
                "percentage": 0,
                "bytesUploaded": 0,
                "bytesTotal": 1838084,
                "uploadComplete": false,
                "uploadStarted": null
            },
            "size": 1838084,
            "isRemote": false,
            "remote": ""
        }
     */
    for (let i = 0, max = Object.keys(files).length; i < max; i++) {
        const f = Object.values(files)[i];
        const filename = f.data.name;

        if (f.source !== 'Webcam' && (!f.data.type || f.data.type === '')) {
            const mimeType = getMimeTypeFromFilename(filename);
            if (mimeType) {
                const msg = `resolved mime-type for: ${filename} to ${mimeType}`;
                clientLog('warn', msg, componentName);

                // Construct a new file object with the new mime type, replacing the old one.
                f.data = new File([f.data], filename, {type: mimeType});

                // Update metadata for the Uppy object.
                f.meta.type = mimeType;
                f.type = mimeType;
            } else {
                const errMsg = `unable to resolve mime-type for: ${filename}`;
                clientLog('error', errMsg, componentName);
            }
        }
    }
};


/**
 * Instanciates Uppy GUI.
 * @param config
 * @returns {Uppy}
 */
const createUppy = async (config) => {
    const maxResolution = await getMaxResolution();
    const webcamConfig = {
        modes: ['picture'],
        showVideoSourceDropdown: true,
        preferredImageMimeType: 'image/jpeg',
        videoConstraints: {
            ...maxResolution,
            facingMode: 'environment',
        },
    };
    return new Uppy({
        onBeforeUpload: onBeforeUploadHandler,
    })
        .use(DmsUppyUpload, config)
        .use(Webcam, webcamConfig);
};


/**
 * Presents the new GUI, used when uploading files.
 * @returns {JSX.Element}
 * @constructor
 */
export const StepFileUpload = ({nextStepCallback}) => {
    const locale = useUppyTranslations();
    const [uppy, setUppy] = useState(undefined);

    // Files uploaded
    const [postDocumentsResponse,
        // eslint-disable-next-line no-unused-vars
        postDocuments,  // NOSONAR
        postDocumentsInChunks] = usePostDocuments();

    const snackbarDispatch = useSnackbarDispatch();
    const docDispatch = useFileUploadDispatch();
    const fileUploadDispatch = useFileUploadDispatch();
    const {saved, collectionId, projectId, uploadedFilesNotProcessed} = useFileUploadState();
    const [processing, setProcessing] = useState(false);

    /**
     * If an error occured, show a snackbar indicating that something failed.
     */
    const showError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: "Filopplasting",
                body: "Opplasting feilet for en eller flere filer.",
                type: "error",
            },
        });
        setProcessing(false);
    };

    /**
     * Callback triggered during upload, receives a separate object for each file being processed/uploaded.
     * @param upload    object  Object containing the upload status.
     */
    const uploadHandler = upload => {
        if (upload.status === STATUS.SUCCESS) {
            const msg = `finished uploading: ${JSON.stringify(upload)}`;
            clientLog('info', msg, componentName);
        }
    };

    /**
     * Callback executed when all files have completed uploading.
     * @param res object    An object containing the upload result.
     */
    const uploadCompletedHandler = res => {
        if (res.failed.length > 0) {
            fileUploadDispatch({type: ADD_FAILED, failed: res.failed});
            showError();
        }

        const uploadedFiles = res.finished.map(f => {
            let filename = f.uppyFile.data.name;
            if (f.uppyFile.source === 'Webcam') {
                filename = f.uppyFile.name;
            }
            const objectType = getObjectTypeFromFilename(filename);
            const mimeType = getMimeTypeFromFilename(filename);
            return {
                ...f,
                objectType: objectType,
                mimeType: mimeType
            }
        });

        processAndSave(uploadedFiles, res);
    };

    /**
     * Method used to fetch missing thumbnails and create the DAMS-objects, representing each uploaded file.
     * @param files
     * @param uploadResults
     */
    const processAndSave = (files, uploadResults) => {
        setProcessing(true);
        saveToDams(files);
        fileUploadDispatch({
            type: ADD_UPLOADED_NOT_PROCESSED,
            files: uploadResults.finished
        })
    };

    /**
     * Callback triggered when the user clicks on the "Done" button after all uploads have completed.
     */
    const doneButtonClickHandler = () => {
        // Move to next step: Edit metadata.
        nextStepCallback();
    };

    /**
     * Returns a list of headers used when requesting an upload-URL from DMSF.
     * @param cid   int Collection ID
     * @returns {{"x-collection-id": *, "x-quote-mode": string, "Content-Type": string}}
     */
    const createRequestHeaders = (cid) => {
        return {
            'x-collection-id': cid,
            'x-quote-mode': getXQuoteMode(),
            'Content-Type': 'application/json'
        };
    };

    const createDocumentObject = (file, cid) => {

        const {uppyFile, objectType, dmsId, mimeType} = file;
        const {data, size, extension, source} = uppyFile;

        const name = (source === 'Webcam') ? uppyFile.name : data.name;

        return {
            "schema_id": 1,
            "documentType": objectType,
            "locale": "no",
            "status": "published",
            "collectionId": cid,
            "title": name,
            "customIdentifier": "",
            "remarks": "",
            "productionDate": "",
            "producer": [],
            "persons": [],
            "places": [],
            "subjects": [],
            "licenses": [],
            "licensesAssociated": [],
            "copyrightType": "",
            "copyrightTerms": "",
            "copyrightTypeResponsible": [],
            "copyrightInfo": [],
            "copyrightTypeDateUntil": "",
            "copyrightTypeOriginator": [],
            "language": [],
            "uniqueId": null,
            "content": {
                "subjects": null,
                "languages": null,
                "remarks": "",
                "mediae": [{
                    "referenceType": "media",
                    "_action": "put",
                    "reference": {
                        "status": "published",
                        "title": name,
                        "_action": "put",
                        "locale": "no",
                        "source": "dms",
                        "sourceId": dmsId,
                        "content": {
                            "filesize": size,
                            "fileExtension": extension,
                            "mimeType": mimeType
                        }
                    }
                }]
            }
        };
    };

    /**
     * Creates a new "document" entry for each of the uploaded files in the DAMS database.
     * @param files Array   The uploaded files
     */
    const saveToDams = (files) => {
        const documents = files.map(f => createDocumentObject(f, collectionId));

        postDocumentsInChunks(documents).then(res => {
            const successfulFiles = res.results;
            if (successfulFiles) {
                if (files.length === successfulFiles.length) {
                    // DAMS-objects successfully created.
                    fileUploadDispatch({
                        type: ADD_SAVED,
                        documents: successfulFiles
                    });
                }
            }

            if (postDocumentsResponse.failedDocuments["length"] > 0) {
                // One or more DAMS-objects was not created, retry!
                const failingFiles = [];
                for (let i = 0, max = files.length; i < max; i++) {
                    const f = files[i];
                    if (!successfulFiles.find(r => r.title === f.title)) {
                        failingFiles.push(f);
                    }
                }

                clientLog('warn',
                    'one or more files failed uploading - retrying',
                    componentName);
                clientLog('debug',
                    `failing files: ${JSON.stringify(failingFiles)}`,
                    componentName);

                saveToDams(failingFiles);
            }
        });
    };

    const getUppyConfig = (cid) => {
        const headers = createRequestHeaders(cid);

        let commitHeaders = headers;
        delete commitHeaders["Content-Type"];   // EMSF-test does not accept this content-type when committing.

        return {
            uploadHandler: uploadHandler,
            finishedHandler: uploadCompletedHandler,
            orderUploadEndpoint: {
                url: `${window._env_.REACT_APP_DAMS_ADMIN_API}/v2/dms/order-upload-url/`,
                method: 'POST',
                headers: headers
            },
            commitMultipartEndpoint: {
                url: `${window._env_.REACT_APP_DAMS_ADMIN_API}/v2/dms/commit-multipart/`,
                headers: commitHeaders
            },
            chunkSize: 1e8,
            limit: 4,
            retry: 0,
            mock: false
        };
    };

    /**
     * Hook used to intanciate Uppy.
     */
    useEffect(() => {
        if (collectionId === -1 && projectId === -1) {
            return;
        }
        const uppyConfig = getUppyConfig(collectionId);
        createUppy(uppyConfig).then(u => setUppy(u));
    }, [collectionId])

    const getWithThumbnailUrl = async (models) => {
        return await Promise.allSettled(models
            .filter(f => {
                return f.documentType === 'StillImage';
            })
            .map(async (o) => {
                const collectionId = o.collectionId;
                const dmsId = getDmsIdFromModel(o);
                try {

                    const thumbnailUrl = await getThumbnailUrl(collectionId, dmsId);
                    return {
                        ...o,
                        thumbnail: thumbnailUrl
                    };
                } catch (e) {
                    // Failed to get thumbnail, setting default.
                    return {
                        ...o,
                        thumbnail: '/no-thumbnail.jpg'
                    };
                }
            }));
    };

    /**
     * Hook used to add a thumbnail to the objects representing the uploaded files,
     * after being saved to DAMS, and add these to the document context for further processing.
     */
    useEffect(() => {
        if (uploadedFilesNotProcessed.length === 0 || saved.length === 0) {
            return;
        }

        // Add thumbnail/preview from Uppy.
        const mappedWithPreview = saved?.map(r => {
            const sourceFileObj = uploadedFilesNotProcessed
                .filter(f => Object.keys(f).includes('uppyFile'))
                .find(f => f.uppyFile.name === r.content.mediae[0].reference.title);
            if (typeof (sourceFileObj) !== 'undefined') {
                return {
                    ...r,
                    thumbnail: sourceFileObj.uppyFile.preview
                }
            }
        }).filter(m => m !== undefined);

        // Fallback: Add thumbnail for objects where Uppy failed to create a preview/thumbnail.
        const withoutThumbnail = mappedWithPreview?.filter(f => !f?.thumbnail);
        if (typeof (withoutThumbnail) !== 'undefined' && withoutThumbnail.length > 0) {
            // Missing thumbnails - add thumbnails.
            getWithThumbnailUrl(withoutThumbnail).then(data => {
                const filesToAdd = [
                    ...mappedWithPreview.filter(f => f.thumbnail),
                    ...mappedWithPreview.filter(f => f.documentType !== 'StillImage'),
                    ...data.filter(d => d.status === 'fulfilled').map(d => (d.value))
                ];

                setProcessing(false);
                docDispatch({
                    type: ADD_FILES,
                    uploadedFiles: filesToAdd
                });

                docDispatch({
                    type: REMOVE_FILES_NOT_PROCESSED,
                    files: filesToAdd
                });

            });
        } else {
            setProcessing(false);

            // No missing thumbnails - add files, with preview/thumbnail.
            docDispatch({
                type: ADD_FILES,
                uploadedFiles: mappedWithPreview
            });

            docDispatch({
                type: REMOVE_FILES_NOT_PROCESSED,
                files: mappedWithPreview
            });
        }
    }, [saved, docDispatch]);

    /*
    Render the Uppy/fileupload dashboard once the user has selected a museum.
     */
    return (<Box>
        {
            (Boolean(collectionId > -1 || projectId > -1) && uppy) && !processing &&
            <Dashboard uppy={uppy}
                       plugins={['DmsUppyUpload', 'Webcam']}
                       inline={true}
                       proudlyDisplayPoweredByUppy={false}
                       centerSingleFile={false}
                       doneButtonHandler={doneButtonClickHandler}
                       locale={locale}
                       disabled={collectionId === -1}
                       showProgressDetails={true}
                       hideCancelButton={true}
            />
        }
        <Dialog open={processing}>
            <DialogTitle>Behandler opplastede filer</DialogTitle>
            <DialogContent>
                <LinearProgress variant={"indeterminate"} color={"secondary"}/>
                <Typography sx={{marginTop: '16px'}}>
                    Etterbehandler filer, og oppretter nødvendige objekter.
                </Typography>
            </DialogContent>
        </Dialog>
    </Box>);
};
