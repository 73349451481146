import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {AdminContainer} from "../admin/AdminContainer";
import {CollectionSelector} from "../museum/CollectionSelector";
import {useMetadataMapping} from "./useMetadataMapping";
import {DamsForm} from "../form/DamsForm";
import {MetaMappingProvider} from "./MetaMappingContext";
import {FormMappingConfig} from "./FormMappingConfig";
import {MappingAccordion} from "./MappingAccordion";
import {MappingTypeTabPanel} from "./MappingTypeTabPanel";
import {MappingTypeTabs} from "./MappingTypeTabs";
import {useMetadataConfig} from "./useMetadataConfig";
import {useProjectState} from "../project/projectContext";

const PREFIX = "AdministrateMetadataMapping";

const classes = {
    container: `${PREFIX}-container`,
};

const StyledAdminContainer = styled(AdminContainer)(({theme}) => ({
    [`& .${classes.container}`]: {
        width: "100%",
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
    },
}));

export const AdministrateMetadataMapping = () => {
    const {hasArcheologyModule} = useProjectState();
    const [getMetadataConfig] = useMetadataConfig(hasArcheologyModule);

    // Init config. for archeology project
    const metadataConfig = getMetadataConfig(hasArcheologyModule);

    const [activeType, setActiveType] = useState("image");

    const [menuOpen, setMenuOpen] = useState(false);
    const [collectionId, setCollectionId] = useState(0);
    const [selectedField, setSelectedField] = useState("");
    const [existingValues, saveMetadataMapping] = useMetadataMapping({
        collectionId: collectionId,
    });

    const museumCollectionSelected = (museumCollectionId) => {
        setCollectionId(museumCollectionId);
    };

    const saveHandler = (values) => {
        const fieldName = activeType + selectedField;
        saveMetadataMapping(
            activeType,
            selectedField,
            values[fieldName].filter((val) => "" !== val)
        ).then(null);
    };

    /**
     * Gets the configuration for the active/selected object type.
     * @returns {json}
     */
    const getConfigActiveType = () => {
        return metadataConfig[activeType];
    };

    /**
     * Gets the configuration for the active/selected field below the selected object type.
     * @returns {json}
     */
    const getActiveFieldValues = () => {
        const configActiveType = getConfigActiveType();
        const values = configActiveType["fields"][selectedField].values;
        return {[activeType + selectedField]: values};
    };


    /**
     * Returns all the configuratoin for all fields for the selected object type.
     * @param key string  The object type
     * @returns {json}
     */
    const getFieldsConfigByKey = (key) => {
        return metadataConfig[key].fields;
    };


    /**
     * Gets the initial values, used when rendering the form for each of the properties.
     * @returns {{[p: string]: *}|(function(): *)|{}}
     */
    const getFormInitialValues = () => {
        if (Object.keys(existingValues).includes(activeType)) {
            const activeTypeDef = existingValues[activeType];
            if (Object.keys(activeTypeDef).includes(selectedField)) {
                return {[activeType + selectedField]: existingValues[activeType][selectedField]};
            } else {
                return getActiveFieldValues();
            }
        } else {
            return getActiveFieldValues();
        }
    }

    /**
     * Renders the form for the selected field below the selected object type.
     * @returns {JSX.Element|null}
     */
    const getForm = () => {
        if (selectedField) {
            return (
                <DamsForm
                    onSubmit={saveHandler}
                    initialValues={getFormInitialValues()}
                >
                    <FormMappingConfig
                        activeType={activeType}
                        selectedField={selectedField}
                        configActiveType={getConfigActiveType()}
                    />
                </DamsForm>
            );
        } else {
            return null;
        }
    };

    const getAllFieldValues = (key) => {
        const fields = getFieldsConfigByKey(key);
        const valuesArray = Object.keys(fields).map((k) => {
            return [[activeType + k], fields[k].values];
        });
        return Object.fromEntries(valuesArray);
    };

    /**
     * Get existing values, map from "complete object" to a simplified version:
     *
     *             {
     *                 "image": {
     *                     "title": [
     *                         "file:FileName"
     *                     ]
     *                 }
     *             }
     *
     *           ... to this structure:
     *           {imagetitle: ['file:FileName']}
     *
     *           NOTE: Does only return a value, when a field has been actively selected!
     *
     * @returns {{[p: string]: *}|{}}
     */
    const getExistingValues = () => {
        if (Object.keys(existingValues).includes(activeType)) {
            const activeTypeDef = existingValues[activeType];

            if (Object.keys(activeTypeDef).includes(selectedField)) {
                return {[activeType + selectedField]: existingValues[activeType][selectedField]};
            } else {
                return {};
            }
        } else {
            return {};
        }
    };

    const renderFieldAccordionByObjectType = () => {
        return Object.keys(metadataConfig).map((configKey) => {
            return (
                <MappingTypeTabPanel
                    value={configKey}
                    key={crypto.randomUUID()}
                >
                    <MappingAccordion
                        activeType={activeType}
                        selectedField={selectedField}
                        setSelectedFieldCallback={setSelectedField}
                        fields={{...getFieldsConfigByKey(configKey)}}
                        values={{
                            ...getAllFieldValues(activeType),
                            ...getExistingValues(),
                        }}
                        show={Boolean(collectionId)}
                    >
                        {getForm()}
                    </MappingAccordion>
                </MappingTypeTabPanel>
            );
        });
    };

    /**
     * Prevents displaying the object tabs when the menu is active, and the user
     * is about to select another museum.
     */
    const menuOpenCallback = () => {
        const menuOpenState = menuOpen;
        if (menuOpenState) {
            setCollectionId(0);
        }
        setMenuOpen(menuOpenState);
    };

    return (
        <StyledAdminContainer>
            <MetaMappingProvider>
                <CollectionSelector
                    onSelect={museumCollectionSelected}
                    onlyAdministrativeMuseums={true}
                    menuOpenCallback={menuOpenCallback}
                />
                <div className={classes.container}>
                    <MappingTypeTabs
                        show={Boolean(collectionId) && !menuOpen}
                        activeType={activeType}
                        onChangeType={setActiveType}
                    >
                        {renderFieldAccordionByObjectType()}
                    </MappingTypeTabs>
                </div>
            </MetaMappingProvider>
        </StyledAdminContainer>
    );
};
