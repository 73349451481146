import Stack from "@mui/material/Stack";

import {FormikSelectCopyrightClause} from "./FormikSelectCopyrightClause";
import React, {useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {FormikSelectCopyrightResponsible} from "./FormikSelectCopyrightResponsible";
import {HIDE_ADD_COPYRIGHT_CLAUSE_FIELD, useCopyrightDispatch} from "./copyrightContext";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Box from "@mui/material/Box";
import {FormikDateField} from "../form/FormikDateField";
import {UPDATE_FORM_DATA, useDocumentDispatch, useDocumentState} from "../documents/documentContext";

export const CopyrightClauseWrapper = ({t, ix, collectionId}) => {

    const [showDateAndResponsible, setShowDateAndResponsible] = useState(true);
    const copyrightDispatch = useCopyrightDispatch();
    const {formData} = useDocumentState();

    const docDispatch = useDocumentDispatch();

    /**
     * Adds the copyright clause to the formData object.
     * @param value
     */
    const onCopyrightClauseChangeHandler = (value) => {
        if (value !== undefined) {
            setShowDateAndResponsible(true);

            // Make sure the formData object is updated with the latest license-changes.
            formData.copyrightInfo = [...formData.copyrightInfo, value];
            docDispatch({
                type: UPDATE_FORM_DATA,
                formData: formData
            });

            copyrightDispatch({
                type: HIDE_ADD_COPYRIGHT_CLAUSE_FIELD
            });
        }
    };

    /**
     * Sets the value of the specified value field, and updates the formData object.
     * @param i    int The index-value in the array of fields.
     * @param name  str The name of the field.
     * @param value str The value.
     */
    const setDateFieldValue = (i, name, value) => {
        if (!formData.copyrightInfo || formData.copyrightInfo.length === 0) {
            return;
        }
        formData.copyrightInfo[i][name] = value;
        docDispatch({
            type: UPDATE_FORM_DATA,
            formData: formData
        });
    };

    /**
     * Adds the "from" date value to the formData object.
     * @param value
     */
    const fromDateChangeHandler = (value) => {
        setDateFieldValue(ix, 'fromDate', value);
    };

    /**
     * Adds the "to" date value to the formData object.
     * @param value
     */
    const toDateChangeHandler = (value) => {
        setDateFieldValue(ix, 'toDate', value);
    };

    return <Stack sx={{width: '100%'}}>
        <FormikSelectCopyrightClause copyrightClauseOnChangeHandler={onCopyrightClauseChangeHandler}
                                     ix={ix}
                                     collectionId={collectionId}
                                     size={"small"}
        />
        {showDateAndResponsible &&
            <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Stack direction={"row"}>
                    <SubdirectoryArrowRightIcon sx={{color: '#ddd'}} fontSize={"large"}/>
                    <FormikDateField formikKey={`copyrightFromDate${ix}`}
                                     label={t('copyrightClauseValidFrom', 'Klausul gjelder fra')}
                                     disabled={false}
                                     helperText={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     placeholder={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     onChangeCallback={fromDateChangeHandler}
                                     size={"small"}
                    />
                    <Box sx={{marginLeft: 1}}/>
                    <FormikDateField formikKey={`copyrightToDate${ix}`}
                                     label={t('copyrightClauseValidTo', 'Klausul gjelder til')}
                                     disabled={false}
                                     helperText={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     placeholder={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     onChangeCallback={toDateChangeHandler}
                                     size={"small"}
                    />
                </Stack>
                <Stack direction={"row"} sx={{width: '100%'}}>
                    <SubdirectoryArrowRightIcon sx={{color: '#ddd'}} fontSize={"large"}/>
                    <Box sx={{width: '100%'}}>
                        <FormikSelectCopyrightResponsible t={t}
                                                          ix={ix}
                                                          size={"small"}/>
                    </Box>
                </Stack>

            </LocalizationProvider>
        }
    </Stack>;
}