import React from "react";
import {UploadedFilesToolbar} from "../../files/UploadedFilesToolbar";
import {DamsForm} from "../../form/DamsForm";
import {FormMetadata} from "../FormMetadata";
import {UploadedFilesGrid} from "../../files/UploadedFilesGrid";
import {useDocumentState} from "../../documents/documentContext";
import {FormikSubmitButton} from "../../form/FormikSubmitButton";
import {useNewFilesTranslation} from "../../files/NewFilesTranslationContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";

export const MetadataGridSmallScreen = ({
                                            toolbarRef,
                                            clearAllCallback,
                                            checkAllCallback,
                                            checked,
                                            checkFileCallback,
                                            saveDocumentsCallback,
                                            validationSchema,
                                            numberOfCheckedCallback,
                                        }) => {
    const t = useNewFilesTranslation();
    const {formData, files, saved} = useDocumentState();

    const getNumMissingTitle = () => {
        let numMissingTitle = files.length;
        if (saved && saved.length > 0) {
            const hasTitle = files.filter((f) => {
                const m =
                    undefined !==
                    saved.find((s) => s.content.mediae[0].reference.sourceId === f.dmsId);
                if (m) {
                    return f;
                }
                return null;
            }).length;
            numMissingTitle = files.length - hasTitle;
        }
        return numMissingTitle;
    };

    return (
        <DamsForm
            onSubmit={saveDocumentsCallback}
            initialValues={formData}
            validationSchema={validationSchema}
        >
            <Paper elevation={3} sx={{paddingBottom: 2}}>
                <Box ref={toolbarRef} sx={{paddingLeft: 2}}>
                    <UploadedFilesToolbar
                        checkAllCallback={checkAllCallback}
                        clearAllCallback={clearAllCallback}
                        numberOfCheckedCallback={numberOfCheckedCallback}
                    />
                </Box>

                {/* Uploaded files list */}
                <UploadedFilesGrid
                    checked={checked}
                    checkFile={checkFileCallback}
                    smallscreen={true}
                />

                {0 === numberOfCheckedCallback() &&
                    getNumMissingTitle() === files.length && (
                        <Paper
                            elevation={3}
                            sx={{
                                marginLeft: 5,
                                marginRight: 5,
                                marginBottom: 2,
                                padding: 2,
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                zIndex: 900,
                                backgroundColor: "white",
                            }}
                        >
                            <Typography variant={"h6"}>
                                {t("addMetadata", "Legg til metadata")}
                            </Typography>
                            <Typography variant={"body1"}>
                                {t(
                                    "addMetadataHelp",
                                    "Velg bilder for å redigere metadata. Velg flere bilder samtidig for å legge til den " +
                                    "samme informasjonen på alle de valgte bildene. Bilder må ha minimum " +
                                    "tittelfeltet utfylt for å kunne lagres."
                                )}
                            </Typography>
                        </Paper>
                    )}
            </Paper>
            {0 < numberOfCheckedCallback() && (
                <Dialog
                    open={0 < numberOfCheckedCallback()}
                    fullScreen
                    PaperProps={{
                        sx: {
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            height: "50vh",
                        },
                    }}
                    scroll={"paper"}
                >
                    <DialogTitle
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Typography variant={"h6"}>
                            {t(
                                "editMetadataNumberOfCheckedObjects",
                                "Rediger metadata for {{numChecked}} valgte bilder.",
                                {numChecked: numberOfCheckedCallback()}
                            )}
                        </Typography>
                        <Box flexGrow={1}/>
                        <CloseIcon
                            onClick={clearAllCallback}
                            sx={{cursor: "pointer", color: "GrayText"}}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{padding: 1}}>
                            <FormMetadata disabled={0 === numberOfCheckedCallback()}/>
                        </Box>
                        <Divider/>

                        <Box marginTop={2}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 2,
                                }}
                            >
                                <InfoIcon sx={{marginRight: 1, color: "GrayText"}}/>
                                <Typography color={"GrayText"} variant={"caption"}>
                                    {t(
                                        "editMetadataMissingTitle",
                                        "{{numMissingTitle}} bilder mangler tittel",
                                        {numMissingTitle: getNumMissingTitle()}
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <DialogActions>
                            <Box>
                                <Button onClick={clearAllCallback} sx={{marginRight: 1}}>
                                    Avbryt
                                </Button>
                                <FormikSubmitButton
                                    variant={"contained"}
                                    color={"secondary"}
                                    disabled={
                                        Object.values(checked) &&
                                        Object.values(checked).filter((v) => v === true).length ===
                                        0
                                    }
                                >
                                    {t("btnSaveEditedMetadata", "Lagre metadata")}
                                </FormikSubmitButton>
                            </Box>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}
        </DamsForm>
    );
};
