import React from "react";
import { useField } from "formik";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

export const FormikSelect = ({
  formikKey,
  label,
  fullWidth,
  children,
  ...props
}) => {
  const [field, meta] = useField(formikKey);
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={`${field.name}-select-label`}>{label}</InputLabel>
      <Select
        id={field.name}
        labelId={`${field.name}-select-label`}
        name={field.name}
        error={meta.touched && Boolean(meta.error)}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        {...props}
      >
        {children}
      </Select>
      <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
    </FormControl>
  );
};
