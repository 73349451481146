import MenuItem from "@mui/material/MenuItem";
import {FormikSelect} from "../form/FormikSelect";

/**
 * Returns a human-readable value for the selected copyright type.
 * @param t
 * @param value
 * @returns {*}
 */
export const copyrightTypeValueToHr = (t, value) => {
    if (value === 'copyright') {
        return t('copyrightTypeValueCopyright', 'Opphavsrett');
    } else if (value === 'unknown') {
        return t('copyrightTypeValueNotChosen', 'Falt i det fri');
    } else if (value === '') {
        return t('copyrightTypeValueNone', '-- Ikke valgt --');
    }
};

export const FormikSelectCopyrightType = ({t}) => {
    const formikKey = 'copyrightType';
    return (
        <FormikSelect fullWidth={true} formikKey={formikKey} size={"small"}>
            <MenuItem value={''}>{t('copyrightTypeValueNone', '-- Ikke valgt --')}</MenuItem>
            <MenuItem value={'copyright'}>{t('copyrightTypeValueCopyright', 'Opphavsrett')}</MenuItem>
            <MenuItem value={'unknown'}>{t('copyrightTypeValueNotChosen', 'Falt i det fri')}</MenuItem>
        </FormikSelect>
    );
};