import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocale } from "../../app/dateFormat";
import { useSearchTranslation } from "../SearchContext";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";


const PREFIX = "FilterDate";

const classes = {
    root: `${PREFIX}-root`,
    rangeInputs: `${PREFIX}-rangeInputs`,
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.root}`]: {
    },

    [`& .${classes.rangeInputs}`]: {
        display: "flex",
        "& > *": {
            marginRight: theme.spacing(1),
        },
    },
}));

export const FilterDate = ({
    min,
    max,
    onFilter,
}) => {
    const [value, setValue] = useState({});

    const [errorText, setErrorText] = useState({ minDate: "", maxDate: "" });

    const t = useSearchTranslation();

    useEffect(() => {
        const minDate = new Date(min);
        const maxDate = isNaN(max) ? new Date() : new Date(max);
        setValue({ min: minDate.getTime(), max: maxDate.getTime() });
    }, [min, max, setValue]);

    const handleChangeCommitted = (newValue, valueType) => {
        let values = value;
        values[valueType] = newValue.getTime();

        onFilter({
            from: new Date(values.min).toISOString(),
            to: new Date(values.max).toISOString(),
        });
        setValue(values);
    };

    const verifyInput = (v, valueType) => {
        let txt = "";
        let err = { ...errorText };
        if (!v instanceof Date || isNaN(v)) {
            txt = t("incorrectDateFormat", "Feil datoformat");
            err[valueType] = txt;
            setErrorText(err);
        } else {
            err[valueType] = "";
            setErrorText(err);
            const vt = valueType === "minDate" ? "min" : "max";
            handleChangeCommitted(v, vt);
        }
    };

    return (
        <Root className={classes.root}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
                <div className={classes.rangeInputs}>
                    <DesktopDatePicker
                        disableFuture={true}
                        label={t("from", "Fra")}
                        inputFormat={"dd.MM.yyyy"}
                        value={value.min}
                        acceptRegex={/^([0-3][0-9].[0-1][0-2].\\d{4})$/} // NOSONAR
                        // mask={''}
                        disableMaskedInput={true}
                        onChange={(nV) => {
                            verifyInput(nV, "minDate");
                        }}
                        onAccept={(nV) => {
                            handleChangeCommitted(nV, "min");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={errorText.minDate !== ""}
                                helperText={errorText.minDate}
                                variant={errorText.minDate !== "" ? "filled" : "outlined"}
                                size="small"
                            />
                        )}
                    />

                    <Box flexGrow={1} />
                    <DesktopDatePicker
                        disableFuture={true}
                        label={t("to", "Til")}
                        inputFormat={"dd.MM.yyyy"}
                        value={value.max}
                        acceptRegex={/^([0-3][0-9].[0-1][0-2].\\d{4})$/} // NOSONAR
                        mask={''}
                        disableMaskedInput={true}
                        onChange={(nV) => {
                            verifyInput(nV, "maxDate");
                        }}
                        onAccept={(nV) => {
                            handleChangeCommitted(nV, "max");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={errorText.maxDate !== ""}
                                helperText={errorText.maxDate}
                                variant={errorText.maxDate !== "" ? "filled" : "outlined"}
                                size="small"
                            />
                        )}
                    />
                </div>
            </LocalizationProvider>
        </Root>
    );
};
